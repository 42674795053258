import { Fragment } from "react";
import { Timeline, TimelineProps, timelineItemClasses } from "@mui/lab";
import { useFieldArray, useFormContext } from "react-hook-form";
import { EmailCampaignConfigurationStep } from "./EmailCampaignConfigurationStep";
import { AddCampaignConfigurationStep } from "./AddCampaignConfigurationStep";
import { useAuth } from "../../../../auth";
import { MobileCampaignConfigurationStep } from "./MobileCampaignConfigurationStep";

interface CampaignConfigurationStepsProps extends TimelineProps {
  campaignId: string;
}

// https://mui.com/material-ui/react-timeline/
// TypeScript error is a core product thing, so we can ignore it for now
export function CampaignConfigurationSteps({ campaignId }: CampaignConfigurationStepsProps) {
  const auth = useAuth();
  const isAdmin = auth.user?.isAdmin;
  const { control } = useFormContext();
  const { fields, insert, remove } = useFieldArray({
    control,
    name: "campaignSteps",
    keyName: "_id", // without renaming this, react-hook-form will generate its own id under the [id] property name, thus overriding our ids
  });

  const handleAddNewStep = (stepIndex: number, medium: "text" | "email") => {
    // Campaign max length is 5 steps
    if (stepIndex >= 5) return;
    insert(stepIndex, { subject: "", templateText: "", stepDelayDays: 1, enabled: true, medium });
  };

  const handleRemoveStep = (stepIndex: number) => {
    remove(stepIndex);
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {fields.map(({_id, ...campaignStep}, index) => {
        return (
          <Fragment key={`step-${index}`}>
            {campaignStep.medium === "email" && (
              <EmailCampaignConfigurationStep
                campaignId={campaignId}
                campaignStep={campaignStep}
                stepIndex={index}
                handleRemoveStep={handleRemoveStep}
                key={`step-${index}`}
              />
            )}
            {campaignStep.medium === "text" && (
              <MobileCampaignConfigurationStep
                campaignId={campaignId}
                campaignStep={campaignStep}
                stepIndex={index}
                handleRemoveStep={handleRemoveStep}
                key={`step-${index}`}
              />
            )}
            {/** Campaign max length is 5, so don't render an ending add action if we've added the max number of steps */}
            {fields.length < 5 && isAdmin && (
              <AddCampaignConfigurationStep handleAddNewStep={handleAddNewStep} stepIndex={index + 1} key={`addstep-${index}`} />
            )}
          </Fragment>
        );
      })}
    </Timeline>
  );
}
