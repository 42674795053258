import * as d3 from "d3"
import * as topojson from "topojson-client";
import SpikeMap from './spike-map';
import { claimsVizStore } from './components/claims-visualizations';
import { ClaimsSumFilters } from "./components/spike-map-component";
//This us topojson file comes from https://observablehq.com/@d3/spike-map 
let us = require('./us.json');

export const claimsMap = (svg, data) => {
  if (!data) return;
  const iterableClaimsMetrics = []

  //Necessary to clear the svg element so that old data doesn't persist
  svg.selectAll("*").remove();
  
  for (const location of data || []) {
    iterableClaimsMetrics.push([
      claimsVizStore.getState().sumFilter === ClaimsSumFilters.Claims ? 
          location.countPaid :
          location.totalPaid,
      claimsVizStore.getState().sumFilter === ClaimsSumFilters.Claims ? 
          location?.countRisk :
          location?.totalRisk,
      location.address,
      location.city,
      location.state,
      location.llcId,
      location.relationshipId,
      location.llcName,
      location.relationshipName,
      location.lat,
      location.lng,
    ]);
  }

  return SpikeMap(svg, iterableClaimsMetrics, {
    paidValue: ([iterableClaimsMetrics]) => +iterableClaimsMetrics,
    riskValue([, riskValue, address, city, state, llcId, relationshipId, llcName, relationshipName, lat, lng]) {
      if (riskValue === null && address === '3900 E. University Dr.') {
        console.log('null risk value', {address, city, state, llcId, relationshipId, llcName, relationshipName, lat, lng});
      }
      return riskValue;
    },
    relationshipId([, riskValue, address, city, state, llcId, relationshipId, llcName, relationshipName, lat, lng]) {
      return relationshipId;
    },
    llcId([, riskValue, address, city, state, llcId, relationshipId, llcName, relationshipName, lat, lng]) {
      return llcId;
    },
    locationUrl([, riskValue, address, city, state, llcId, relationshipId, llcName, relationshipName, lat, lng]) {
      return `/admin/${relationshipId}`;
    },
    position([, riskValue, address, city, state, llcId, relationshipId, llcName, relationshipName, lat, lng]) {
      const projection = d3.geoAlbersUsa().scale(1300).translate([487.5, 305])
      return projection([lng, lat])
    },
    title([count, riskValue, address, city, state, llcId, relationshipId, llcName, relationshipName, lat, lng]) {
      const paidValueLabel =
          claimsVizStore.getState().sumFilter === ClaimsSumFilters.Claims ?
          `${count} claim${count===1 ? '' : 's'} paid` :
          `$${count?.toFixed(2)} paid`;
      const riskValueLabel =
          claimsVizStore.getState().sumFilter === ClaimsSumFilters.Claims ?
          `${riskValue} claim${riskValue===1 ? '' : 's'} in progress` :
          `$${riskValue?.toFixed(2)} in progress`;
      return `${paidValueLabel} <br/> ${riskValueLabel} <br/> ${address}, ${city} ${state} <br/> ${llcName} <br/> ${relationshipName}`;
    },
    features: nation,
    borders: statemesh,
    width: 975,
    height: 610
  }) 
}

const path = d3.geoPath();
const centroid = feature => path.centroid(feature);

const nation = topojson.feature(us, us.objects.nation)
const statemap = new Map(topojson.feature(us, us.objects.states).features.map(d => [d.id, d]))
const countymap = new Map(topojson.feature(us, us.objects.counties).features.map(d => [d.id, d]))
const statemesh = topojson.mesh(us, us.objects.states, (a, b) => a !== b)
