import { useState } from "react";

// UI - libs
import { Button, Typography } from "@mui/material";

// UI - internal
import Loader from "../../../shared/Loader";
import Error from "../../../shared/Error";
import LocationAddressesTable from "./LocationAddressesTable";
import AddLocationAddressDialog from "./AddLocationAddressDialog";

// Data - libs
import { QueryResult, useQuery } from "@apollo/client";

// Data - internal
import { LocationAddress } from "../../../utilities/generated/gql-types";
import { getLocationAddresses } from "../../../queries";

interface LocationSettingsLocationAddressesProps {
  location: any;
}

// Table + CRUD for managing all address addresses for a location
export default function LocationAddresses({
  location,
}: LocationSettingsLocationAddressesProps) {

  const [isAddingAddress, setIsAddingAddress] = useState<boolean>(false);

  const locationAddressesQuery: QueryResult<{
    getLocationAddresses: LocationAddress[];
  }> = useQuery(getLocationAddresses, {
    variables: { locationId: location.id },
  });

  if (locationAddressesQuery.loading) return <Loader />;
  if (locationAddressesQuery.error) return <Error />;

  return (
    <>
      <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
        <Typography variant="h6">Location Addresses</Typography>
        <Button
          variant="contained"
          color="primary"
          className="tw-mt-8 tw-ml-auto"
          onClick={() => setIsAddingAddress(true)}
        >
          Add Location Address
        </Button>
      </div>
      <LocationAddressesTable
        location={location}
        locationAddresses={[
          ...(locationAddressesQuery.data?.getLocationAddresses || [])
        ]}
      />
      <AddLocationAddressDialog
        location={location}
        open={isAddingAddress}
        onClose={() => setIsAddingAddress(false)}
      />
    </>
  );
}
