import { Alert, Box, Divider, Grid, Skeleton, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";
import { CampaignCounter } from "../../../PortfolioView/CampaignCounter";
import { CampaignsOverviewFunnelChart } from "./CampaignsOverviewFunnelChart";
import { CampaignCard } from "../../CampaignCard";
import { Campaign, CampaignMetrics } from "@safelease/service-utilities";
import { useReputation } from "../../../../useReputation";
import { CampaignDrawer } from "./CampaignDrawer";

interface CampaignsOverviewProps {
  campaigns: Array<object>;
}

export function CampaignsOverview({}: CampaignsOverviewProps) {
  const { statuses, facilityMetrics } = useReputation();

  if (statuses.facilityMetrics === "loading") return <Skeleton variant="rectangular" height={400} sx={{ width: "100%" }} />;
  if (statuses.facilityMetrics === "error") return <Alert severity="error">Something went wrong</Alert>;

  const campaigns = facilityMetrics.campaigns.filter((campaign) => campaign.campaignType !== "manual") ?? [];

  if (campaigns.length === 0) {
    return <Alert severity="info">Please contact your SafeLease representative to enable move-in campaigns.</Alert>;
  }
  const aggregateFunnelMetrics = campaigns.reduce((acc, campaign) => {
    Object.keys(campaign.funnelMetrics).forEach((key) => {
      if (acc[key]) {
        acc[key] += campaign.funnelMetrics[key];
      } else {
        acc[key] = campaign.funnelMetrics[key];
      }
    });
    return acc;
  }, {}) as CampaignMetrics;

  const previousAggregateFunnelMetrics = campaigns.reduce((acc, campaign) => {
    Object.keys(campaign.previousFunnelMetrics).forEach((key) => {
      if (acc[key]) {
        acc[key] += campaign.previousFunnelMetrics[key];
      } else {
        acc[key] = campaign.previousFunnelMetrics[key];
      }
    });
    return acc;
  }, {}) as CampaignMetrics;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SafeLeaseCard sx={{ p: 2 }}>
            <CampaignsOverviewFunnelChart funnelMetrics={aggregateFunnelMetrics} previousFunnelMetrics={previousAggregateFunnelMetrics} />
          </SafeLeaseCard>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ mt: 2, mb: 1 }}>Auto Campaigns</Typography>
          <Grid container spacing={2}>
            {campaigns.map((campaign: Campaign & { funnelMetrics: CampaignMetrics }) => (
              <Grid item xs={6} md={6} lg={4} xl={3} key={campaign.id}>
                <CampaignCard campaign={campaign} />
              </Grid>
            ))}
          </Grid>
          {campaigns.length === 0 && (
            <Typography variant="body1" sx={{ mt: 2 }}>
              We're having trouble loading your campaigns.
            </Typography>
          )}
        </Grid>
      </Grid>

      <CampaignDrawer />
    </>
  );
}
