import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import { useHistory } from 'react-router-dom';
import { Select, MenuItem, Button } from '@mui/material';
import AccountSummaryChart from './widgets/account-summary-chart';
import SafeLeaseUnitsChart from './widgets/safe-lease-units-chart';
import ClaimsTable from './widgets/claims-table';
import UpcomingJobsDrawer from "./upcoming-jobs-drawer";
import { Metric, Claim, Location, User } from "../utilities/generated/gql-types";
import { useQuery, QueryResult } from '@apollo/client';
import { getLocations, getAggregateMetrics, getClaims, getRelationshipsSelector, getRelationship } from '../queries';
import { useAuth } from '../auth.js';
import { MetricsHelpers } from '../utilities/metrics-helpers';
import { muiSelect, muiMenuItem, muiButtonAsSafeleaseSelect } from '../styles/mui-overrides';
import TitleHeader from '../shared/title-header';
import RouterHelper from '../utilities/router-helper';
import _ from 'lodash';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { mixpanelEventHandler } from '../utilities/reactMixpanelHandler';
import Loader from '../shared/Loader';
import Error from '../shared/Error'
import AccessControlled from "../components/AccessControlled";
import { PrivatePolicyComplianceBanner } from "./widgets/PrivatePolicyComplianceBanner";
import { ReputationBanner } from "./widgets/ReputationBanner";
Chart.register(...registerables);

function Dashboard(props: {routerHelper: RouterHelper}) {
  const { routerHelper } = props
  const auth: {user: User} = useAuth();
  const history = useHistory();
  const isAdmin = auth.user.isAdmin;

  const [locationId, setLocationId] = 
    React.useState<string | number | null>(routerHelper.getLocationId() || null);
  const [jobsDrawerOpen, setJobsDrawerOpen] = useState(false);

  useEffect(() => {
    routerHelper.setLocationId(locationId?.toString())
  }, [locationId])

  const relationshipId = routerHelper?.getRelationshipId() || auth.user?.relationshipId;

  useEffect(() => {
    document.body.classList.add('dashboard');
    mixpanelEventHandler('View Dashboard')
    return () => { document.body.classList.remove('dashboard')}
  }, []);
  
  const {loading, error, data: unfilteredAggregateMetrics} = 
      useQuery<{getAggregateMetrics: Metric[]}>(
          getAggregateMetrics, 
          {variables: {relationshipId, locationId}}
      );
  
  const relationships: QueryResult = useQuery(getRelationshipsSelector);

  const locations: QueryResult<{getLocations: Location[]}> = 
      useQuery(getLocations, {variables: {relationshipId}});

  const relationship: QueryResult = useQuery(getRelationship, {variables: {id: relationshipId}});

  const claims: QueryResult<{getClaims: Claim[]}> = 
      useQuery(getClaims, {variables: {relationshipId}});
  if (loading || locations.loading || claims.loading || relationships.loading || relationship.loading) return <Loader />;
  if (error || locations.error || claims.error || relationships.error || relationship.error) return <Error/>;

  var aggregateMetrics: {getAggregateMetrics: Metric[]} = 
      JSON.parse(JSON.stringify(unfilteredAggregateMetrics));
  var claimsData = JSON.parse(JSON.stringify(claims.data))
  claimsData.getClaims = claimsData.getClaims.filter(claim => !!claim.locationConfirmed);
  if(locationId) {
    aggregateMetrics.getAggregateMetrics = 
        aggregateMetrics.getAggregateMetrics.filter(row => row.locationId == locationId || row.key == 'customerBillable');

    claimsData.getClaims = claimsData.getClaims.filter(claim => claim.locationId == locationId)
  }


  const occupiedUnits = MetricsHelpers.getLastValue('occupied', aggregateMetrics?.getAggregateMetrics);
  
  const locationsWithIngestedAt = locations.data.getLocations.filter(location => location.lastIngestedAt);
  const lastIngestedAt = () => {
    if (locationsWithIngestedAt.length > 0) {
      if (!locationId) {
        return new Date(_.min(locationsWithIngestedAt.map(location => 
            Date.parse(location.lastIngestedAt))))
      } else {
        if (locationsWithIngestedAt.map(l => l.id).includes(locationId.toString())) {
          return new Date(Date.parse(
              locationsWithIngestedAt.filter(l => l.id === locationId)[0].lastIngestedAt))
        }
      }
    }
    return null
  }

  const getUpcomingDates = () => {
    const today = (() => {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return now;
    })();
    const map = {
      setupDate: 'Setup',
      rolloverDate: 'Rollover',
      autoEnrollExistingEffectiveDate: 'Auto-Protect All',
    };
    const keys = ['setupDate', 'rolloverDate', 'autoEnrollExistingEffectiveDate'];
    let jobsData = [];
    for (const location of locations.data.getLocations) {
      for (const key of keys) {
        if (location[key] && new Date(location[key]).getTime() > today.getTime()) {
          jobsData.push({
            label: map[key],
            address: location.address,
            date: new Date(location[key]),
            rawDate: location[key],
            locationId: location.id
          });
        }
      }
    }
    if (locationId) jobsData = jobsData.filter(job => job.locationId === locationId);
    // sort by dates descending
    jobsData.sort((a, b) => {
      if (a.date.getTime() > b.date.getTime()) return 1;
      if (a.date.getTime() < b.date.getTime()) return -1;
      return 0;
    });
    return jobsData;
  }

  const handleSetLocationID = (e) => {
    mixpanelEventHandler('Dashboard - Location Selector')
    setLocationId(e.target.value)
  }

  const filteredLocations = () => {
    if (locationId) return locations.data.getLocations.filter(location => location.id == locationId);
    else return locations.data.getLocations;
  }

  const isUnsupportedFMS = () => {
    if (!locationId) return false;
    const location = locations.data.getLocations.find(location => location.id == locationId);
    if (!location) return false;
    // above line handles brief race condition for admins only
    // proper solution is single source of truth for a locationId, likely a routerHelper refactor
    if (location.unsupported) return true;
    else return false;
  }

  const navigateRelationship = (e) => {
    routerHelper.navigateToRoute('dashboard', e.target.value, history);
    setLocationId(null);
  }

  return (
    <div>
      <TitleHeader title="Dashboard" />
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={locationId || ''}
          displayEmpty
          onChange={(e) => handleSetLocationID(e)}
          sx={muiSelect}
        >
          <MenuItem value={''} sx={muiMenuItem}>
            All Locations
          </MenuItem>
          {locations.data.getLocations
            ?.filter(l => l.liveDate)
            .map(location => (
              <MenuItem sx={muiMenuItem} key={location.id} value={location.id}>
                {isAdmin ? location.name || location.fullAddress : location.fullAddress}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      { relationships && relationships.data.getRelationshipsSelector.length > 1 && 
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
              value={relationshipId}
              displayEmpty
              onChange={navigateRelationship}
              sx={muiSelect}>
              
            {relationships.data?.getRelationshipsSelector?.map(relationship => {
              return (
                <MenuItem key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      }

      {getUpcomingDates().length > 0 && (
        <Button
        sx={{ ...muiButtonAsSafeleaseSelect, margin: '8px' }}
        onClick={() => setJobsDrawerOpen(true)}
        >
          <span className="">Upcoming Dates</span>
          <span className="tw-ml-3 tw-text-white tw-bg-[#E9645F] tw-rounded-full tw-p-1 tw-w-6">{getUpcomingDates().length}</span>
        </Button>
      )}

      {jobsDrawerOpen && (
        <UpcomingJobsDrawer isOpen={jobsDrawerOpen} close={() => setJobsDrawerOpen(false)} jobsData={getUpcomingDates()}/>
      )}
      
        {locationsWithIngestedAt.length > 0 &&
        <p className="updated-text"> Data updated as of {
          lastIngestedAt()?.toLocaleString()
        }
        </p>
        }

        <ReputationBanner />

      <div
        className='dashboard-grid'
        style={
          !aggregateMetrics.getAggregateMetrics.some(metric => metric.key === 'customerBillable')
            ? { gridTemplateColumns: '[col] 1fr' }
            : {}
        }
      >
        {aggregateMetrics.getAggregateMetrics.some(metric => metric.key === 'customerBillable') && (
          <AccountSummaryChart
            relationship={relationship.data.getRelationship}
            locationId={locationId} 
            loading={loading} 
            error={error} 
            aggregateMetrics={aggregateMetrics} 
            locations={filteredLocations()}
            unsupportedFms={isUnsupportedFMS()}
          />
        )}
        <SafeLeaseUnitsChart locationId={locationId} 
          relationship={relationship.data.getRelationship}
          loading={loading} 
          error={error} 
          aggregateMetrics={aggregateMetrics} 
          occupiedUnits={occupiedUnits} 
          locations={filteredLocations()}
          unsupportedFms={isUnsupportedFMS()}
        />
        <AccessControlled
          type="Location"
          /* To simpfilfy reuse of <AccessControlled> wrapper, we need to pass an ID for the Location ability type even when we are looking at all locations
            Backend will still return proper permissions/claims */
          id={routerHelper.getLocationId() || locations?.data?.getLocations[0]?.id}
          permission="viewClaims"
        >
        <ClaimsTable
          claimsQueryResultData={claimsData?.getClaims} 
          routerHelper={routerHelper}
        />
        </AccessControlled>
      </div>
    </div>
  );
}

export { Dashboard };
