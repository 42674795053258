import { SafeLeaseDataGrid } from "@safelease/components";
import { facilityFeedbackListGridColDefs } from "./facilityFeedbackListGridColDefs";
import { Box } from "@mui/material";
import useWindowSize from "../../../../../utilities/useWindowSize";
import { PrivateReview } from "@safelease/service-utilities";
import { useState } from "react";
import { NoRowsDisplay } from "../FacilityOverview/ReviewsGrid";
import { useReputation } from "../../../../useReputation";

interface FacilityFeedbackList {
  feedback: Array<PrivateReview>;
}

export function FacilityFeedbackList({ feedback }: FacilityFeedbackList) {
  const size = useWindowSize();

  return (
    <Box sx={{ height: size.height - 500 }}>
      <SafeLeaseDataGrid
        columns={facilityFeedbackListGridColDefs}
        getRowHeight={() => "auto"}
        slots={{
          noRowsOverlay: NoRowsDisplay,
        }}
        rows={feedback}
        sx={{
          ".MuiDataGrid-row": {
            "&:hover": {
              cursor: "default",
              backgroundColor: "transparent",
            },
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        disableRowSelectionOnClick={true}
        
      />
    </Box>
  );
}
