import { CampaignOutlined, FeedbackOutlined, KeyboardArrowLeft, PersonOutline, TrendingUp, Tune } from "@mui/icons-material";
import { Alert, Box, CircularProgress, MenuItem, Select, Skeleton, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseTab, SafeLeaseTabContent, SafeLeaseTabs } from "@safelease/components";
import { useState, useEffect } from "react";
import { FacilityOverview } from "./FacilityViewTabs/FacilityOverview";
import { CampaignsOverview } from "./FacilityViewTabs/CampaignsOverview";
import { ContactsOverview } from "./FacilityViewTabs/ContactsOverview";
import { FacilitySettings } from "./FacilityViewTabs/FacilitySettings";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { FeedbackOverview } from "./FacilityViewTabs/FeedbackOverview";
import { useReputation } from "../../useReputation";
import { mixpanelEventHandler } from "../../../utilities/reactMixpanelHandler";
import TitleHeader from "../../../shared/title-header";
import RouterHelper from "../../../utilities/router-helper";

interface FacilityViewProps {
  routerHelper: RouterHelper;
}

export function FacilityView({ routerHelper }: FacilityViewProps) {
  // get facilityId from the URL query parameters
  const location = useLocation();
  const queryParamFacilityId = new URLSearchParams(location.search).get("facilityId");

  const { statuses, selectedFacilityId, facility, setSelectedFacilityId, portfolio } = useReputation();
  const [currentTab, setTab] = useState<string>("Overview");
  const { push: navigate } = useHistory();

  useEffect(() => {
    if (facility) {
      mixpanelEventHandler("Reputation - Page View - Facility", { facilityId: facility.id });
    }
  }, [facility]);

  // if there is a facilityId in the URL query parameters, set it as the selectedFacilityId
  if (queryParamFacilityId && !selectedFacilityId) {
    setSelectedFacilityId(queryParamFacilityId);
  }

  if (!selectedFacilityId) return <Redirect to="/reputation" />;
  if (statuses.facility === "loading") return <Skeleton variant="rectangular" height={400} sx={{ width: "100%" }} />;
  if (statuses.facility === "error") return <Alert severity="error">Something went</Alert>;

  return (
    <Box>
      <TitleHeader title="Facility Overview" />
      <Box sx={{ maxWidth: (theme: Theme) => theme.breakpoints.values.xl }}>
        <Stack direction="row" spacing={4} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <SafeLeaseButton
              variant="text"
              color="navy"
              onClick={() => navigate("/reputation")}
              startIcon={<KeyboardArrowLeft />}
              sx={{ fontWeight: 400, fontSize: "16px", fontFamily: "Roboto" }}
            >
              Portfolio
            </SafeLeaseButton>
            <Typography>/</Typography>
            {statuses.portfolio === "loading" && <CircularProgress size={20} />}
            {statuses.portfolio === "success" && (
              <Select
                value={selectedFacilityId}
                onChange={(e) => setSelectedFacilityId(e.target.value)}
                variant="standard"
                sx={{
                  borderBottom: "none",
                  "&:before": { borderBottom: "none" },
                  "&:hover": { borderBottom: "none", "&:before": { borderBottom: "none" } },
                }}
              >
                {portfolio.facilities.map((facility) => (
                  <MenuItem value={facility.id} key={facility.id}>
                    {`${facility.companyName} - ${facility.address}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Stack>
          {!facility.googlePlaceId && (
            <Alert
              severity="error"
              sx={{ minWidth: 500, flex: 1, display: "flex", alignItems: "center", fontSize: "16px", lineHeight: "normal" }}
              action={
                <SafeLeaseButton
                  color="red"
                  onClick={() => {
                    // Open integration settings by default
                    location.search = `?defaultTab=1`;
                    setTab("Settings");
                  }}
                >
                  Fix
                </SafeLeaseButton>
              }
            >
              Facility is missing a Google Place ID. Auto-campaigns are disabled until a Google Place ID is added.
            </Alert>
          )}
        </Stack>
        <SafeLeaseTabs
          onChange={(_, value) => {
            setTab(value);
            mixpanelEventHandler(`Reputation - Tab - ${value}`, { facilityId: facility.id });
          }}
          value={currentTab}
          sx={{ mt: 4 }}
        >
          <SafeLeaseTab label="Overview" icon={<TrendingUp />} value={"Overview"} />
          <SafeLeaseTab label="Campaigns" icon={<CampaignOutlined />} value={"Campaigns"} />
          <SafeLeaseTab label="Private Reviews" icon={<FeedbackOutlined />} value={"Private Reviews"} />
          <SafeLeaseTab label="Contacts" icon={<PersonOutline />} value={"Contacts"} />
          <SafeLeaseTab label="Settings" icon={<Tune />} value={"Settings"} />
        </SafeLeaseTabs>
        <Box sx={{ py: 2 }}>
          <SafeLeaseTabContent value={"Overview"} currentTab={currentTab}>
            <FacilityOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={"Campaigns"} currentTab={currentTab}>
            <CampaignsOverview campaigns={[]} />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={"Private Reviews"} currentTab={currentTab}>
            <FeedbackOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={"Contacts"} currentTab={currentTab}>
            <ContactsOverview />
          </SafeLeaseTabContent>
          <SafeLeaseTabContent value={"Settings"} currentTab={currentTab}>
            <FacilitySettings />
          </SafeLeaseTabContent>
        </Box>
      </Box>
    </Box>
  );
}
