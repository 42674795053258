import { Box, ButtonBase, Divider, Rating, Stack, Theme, Typography, useTheme } from "@mui/material";
import { SafeLeaseCard, ChangeText } from "@safelease/components";
import { CampaignCounter } from "./CampaignCounter";
import { PendingReviewsCounter } from "./PendingReviewsCounter";
import { useHistory } from "react-router-dom";
import { useReputation } from "../../useReputation";

interface FacilityListItemProps {
  id: string;
  name: string;
  reviewCount: number;
  currentRating: number;
  weeklyRatingChange: number;
  activeCampaignCount: number;
  reviewsPendingReply: number;
}

export function FacilityListItem({
  id,
  name,
  reviewCount,
  currentRating,
  weeklyRatingChange = 0,
  activeCampaignCount,
  reviewsPendingReply,
}: FacilityListItemProps) {
  const theme = useTheme();

  // Prep for upgrading react router
  const { setSelectedFacilityId } = useReputation();
  const { push: navigate } = useHistory();

  return (
    <ButtonBase
      sx={{ width: "100%", p: 0 }}
      onClick={() => {
        setSelectedFacilityId(id);
        navigate("/reputation/facility");
      }}
    >
      <SafeLeaseCard
        sx={{
          p: 2,
          width: "100%",
          cursor: "pointer",
          "&:hover": {
            border: "2px solid",
            borderColor: theme.palette.primary.light,
            boxShadow: "0 0 20px rgba(0,0,0,0.10)",
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{ width: 150, textAlign: "left" }}>
              <Typography sx={{ fontWeight: 700 }}>{name}</Typography>
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }} variant="subtitle2">
                {reviewCount} reviews
              </Typography>
            </Box>
            <Divider orientation="vertical" sx={{ borderColor: "#EBEFF7" }} flexItem />
            <Stack direction="row" alignItems="center" spacing={2} sx={{ width: 160 }}>
              <Typography sx={{ fontWeight: 700 }}>{Number(currentRating).toFixed(1)}</Typography>
              <Rating value={Number(currentRating)} readOnly sx={{ mr: 5 }} />
            </Stack>
            <ChangeText
              value={Number(currentRating) ?? 0}
              previousValue={(Number(currentRating) ?? 0) - (Number(weeklyRatingChange) ?? 0)}
              periodSize={7}
              periodType="days"
            />
            {activeCampaignCount > 0 && <Divider orientation="vertical" sx={{ borderColor: "#EBEFF7" }} flexItem />}
            {activeCampaignCount > 0 && <CampaignCounter count={activeCampaignCount} />}
          </Stack>
          <PendingReviewsCounter count={reviewsPendingReply} />
        </Stack>
      </SafeLeaseCard>
    </ButtonBase>
  );
}
