import { Check, Error, MailOutline } from "@mui/icons-material";
import { TimelineContent, TimelineContentProps } from "@mui/lab";
import { Alert, Box, CircularProgress, Divider, Stack, Typography, Theme, alpha } from "@mui/material";
import { SafeLeaseButton, SafeLeaseChip, SafeLeaseFormTextField, SafeLeaseSwitch, SafeLeaseTextField } from "@safelease/components";
import { Controller, useFormContext } from "react-hook-form";
import { useAuth } from "../../../../auth";
import { CampaignStepBodyEditor, deserialize, serializeBody } from "./CampaignStepBodyEditor";
import { useEffect, useMemo, useState } from "react";
import { Descendant } from "slate";
import { CampaignStep } from "@safelease/service-utilities";
import { blue, green, red } from "@mui/material/colors";
import { useReputation } from "../../../useReputation";

interface CampaignEmailConfigurationStepContentProps extends TimelineContentProps {
  campaignId?: string;
  campaignStep?: Partial<CampaignStep>;
  stepIndex: number;
  handleRemoveStep?: (stepIndex: number) => void;
}

/** House the content for an individual step in the campaign */
export function CampaignEmailConfigurationStepContent({
  campaignId,
  campaignStep,
  stepIndex,
  handleRemoveStep,
}: CampaignEmailConfigurationStepContentProps) {
  const { statuses } = useReputation();
  const auth = useAuth();
  const isAdmin = auth.user?.isAdmin;
  const { watch, control } = useFormContext();

  return (
    <TimelineContent>
      <Box
        sx={{
          height: "100%",
          bgcolor: alpha("#EBEFF7", 0.4),
          minHeight: 200,
          p: 2,
          borderRadius: 2,
          width: (theme: Theme) => theme.breakpoints.values.sm,
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" sx={{ mb: 2, flex: 1 }} spacing={2}>
              <MailOutline sx={{ color: (theme: Theme) => theme.palette.blue.main, width: "28px", height: "auto" }} />
              <Typography variant="body1" fontWeight="600">
                Message {stepIndex + 1}
              </Typography>
              {!watch(`campaignSteps.${stepIndex}.enabled`) && <SafeLeaseChip color="red" label="Disabled" sx={{ borderRadius: "50em" }} />}
            </Stack>
            {isAdmin && (
              <Controller
                name={`campaignSteps.${stepIndex}.enabled`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SafeLeaseSwitch onChange={onChange} checked={value} value={value} disabled={statuses.campaignConfigurationForm} />
                )}
              />
            )}
            {!isAdmin && watch(`campaignSteps.${stepIndex}.enabled`) && (
              <SafeLeaseChip color="green" label="Enabled" sx={{ borderRadius: "50em" }} />
            )}
            {!isAdmin && !watch(`campaignSteps.${stepIndex}.enabled`) && (
              <SafeLeaseChip color="red" label="Disabled" sx={{ borderRadius: "50em" }} />
            )}
          </Stack>
          {isAdmin && <AdminContent campaignId={campaignId} stepIndex={stepIndex} />}
          {!isAdmin && <PartnerContent stepIndex={stepIndex} />}
          <TestEmailSender stepIndex={stepIndex} campaignId={campaignId} campaignStep={campaignStep} />
        </Stack>
      </Box>
    </TimelineContent>
  );
}

/** Content to display to admins for editing campaign configuration */
function AdminContent({ stepIndex }: CampaignEmailConfigurationStepContentProps) {
  const { statuses } = useReputation();
  const { formState, watch, setValue } = useFormContext();
  const stepTemplateText = useMemo(() => deserialize(watch(`campaignSteps.${stepIndex}.templateText`)), []);

  const handleEditTemplateText = (value: Descendant[]) => {
    // setStepTemplateText(value);
    const reserializedValue = serializeBody({
      type: "body",
      children: value,
    });
    setValue(`campaignSteps.${stepIndex}.templateText`, `<p>${reserializedValue}</p>`);
  };

  const handleStepDelayDaysChange = (newValue: number) => {
    setValue(`campaignSteps.${stepIndex}.stepDelayDays`, newValue);
  };

  return (
    <>
      {stepIndex > 0 && (
        <SafeLeaseFormTextField
          type="number"
          onChange={(event) => handleStepDelayDaysChange(Number(event.target.value))}
          name={`campaignSteps.${stepIndex}.stepDelayDays`}
          label="Step delay (days)"
          sx={{ bgcolor: "white", width: 100 }}
          helperText={formState.errors?.campaignSteps?.[stepIndex]?.stepDelayDays?.message}
          error={Boolean(formState.errors?.campaignSteps?.[stepIndex]?.stepDelayDays)}
          disabled={statuses.campaignConfigurationForm}
          tooltip="The number of days to wait before sending this email after the previous email has been sent."
        />
      )}
      <SafeLeaseFormTextField
        label="Subject"
        name={`campaignSteps.${stepIndex}.subject`}
        sx={{ bgcolor: "white" }}
        helperText={formState.errors?.campaignSteps?.[stepIndex]?.subject?.message}
        error={Boolean(formState.errors?.campaignSteps?.[stepIndex]?.subject)}
        disabled={statuses.campaignConfigurationForm}
      />
      <CampaignStepBodyEditor value={stepTemplateText} setValue={handleEditTemplateText} />
    </>
  );
}

/** Content to display to partners for viewing campaign configuration */
function PartnerContent({ stepIndex }: CampaignEmailConfigurationStepContentProps) {
  const { watch } = useFormContext();
  const stepTemplateText = useMemo(() => deserialize(watch(`campaignSteps.${stepIndex}.templateText`)), []);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography component="label" sx={{ width: 80, color: "grey.A100" }}>
          Subject
        </Typography>
        <Typography fontWeight="500">
          {watch(`campaignSteps.${stepIndex}.subject`).length > 0 ? watch(`campaignSteps.${stepIndex}.subject`) : <i>Empty</i>}
        </Typography>
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography component="label" sx={{ width: 80, color: "grey.A100" }}>
          Body
        </Typography>
        <CampaignStepBodyEditor value={stepTemplateText} readOnly={true} />
      </Stack>

      <Divider />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography component="label" sx={{ width: 80, color: "grey.A100" }}>
          Delay
        </Typography>
        <Typography fontWeight="500">
          {stepIndex === 0 ? "Immediately" : `${watch(`campaignSteps.${stepIndex}.stepDelayDays`)} day(s)`}
        </Typography>
      </Stack>
      <Divider />
    </>
  );
}

function TestEmailSender({ campaignId, stepIndex, campaignStep }: CampaignEmailConfigurationStepContentProps) {
  const { statuses, SafeLeaseAPI } = useReputation();
  const { watch } = useFormContext();

  /** Which email should the test send go to? */
  const [testEmail, setTestEmail] = useState<string>("");
  const [testEmailStatus, setTestEmailStatus] = useState<"idle" | "loading" | "success" | "error">("idle");

  useEffect(() => {
    setTestEmailStatus("idle");
  }, [testEmail]);

  /* Send a test email with whatever the current subjec/body is */
  const sendTestEmail = async () => {
    const testTemplateSubject = watch(`campaignSteps.${stepIndex}.subject`);
    const testTemplateText = watch(`campaignSteps.${stepIndex}.templateText`);

    const params = {
      campaignId,
      toEmail: testEmail,
      subject: testTemplateSubject,
      body: testTemplateText,
      useCustomBody: true,
      campaignStepId: campaignStep.id,
    };

    try {
      setTestEmailStatus("loading");
      await SafeLeaseAPI.reputation.sendTestEmail(params);
      setTestEmailStatus("success");
    } catch (err) {
      setTestEmailStatus("error");
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
        <Box sx={{ width: 380 }}>
          <SafeLeaseTextField
            placeholder="Enter email"
            sx={{ bgcolor: "white" }}
            name="email"
            value={testEmail}
            onChange={(e) => setTestEmail(e.target.value)}
            disabled={testEmailStatus === "loading" || statuses.campaignConfigurationForm}
          />
        </Box>
        <SafeLeaseButton
          variant="outlined"
          color="navy"
          size="small"
          sx={{ minWidth: 100, height: "100%" }}
          onClick={sendTestEmail}
          disabled={statuses.campaignConfigurationForm}
        >
          Send test
        </SafeLeaseButton>
      </Stack>
      {testEmailStatus === "loading" && (
        <Alert severity="info" icon={<CircularProgress size={16} />} sx={{ bgcolor: blue[50], color: blue[500] }}>
          Sending test email...
        </Alert>
      )}
      {testEmailStatus === "success" && (
        <Alert severity="success" sx={{ bgcolor: green[50], color: green[500] }} icon={<Check fontSize="inherit" />}>
          Test email sent to {testEmail}.
        </Alert>
      )}
      {testEmailStatus === "error" && (
        <Alert severity="error" sx={{ bgcolor: red[50], color: red[500] }} icon={<Error fontSize="inherit" />}>
          Failed to send test email
        </Alert>
      )}
    </>
  );
}
