import { IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { Formatters } from "../../utilities/formatters";
import { invoiceStatuses } from "../../utilities/invoice-statuses";
import LaunchIcon from "@mui/icons-material/Launch";
import DownloadIcon from "@mui/icons-material/Download";
import BillingDataService from "../../services/billing.service";
import FileSaver from "file-saver";
import CheckIcon from "@mui/icons-material/Check";
import { Close } from "@mui/icons-material";

export const invoicesTableColumnDefs: GridColDef[] = [
  {
    field: "billingEntityName",
    headerName: "Billing Entity Name",
    flex: 2,
  },
  {
    field: "relationshipName",
    headerName: "Relationship",
    flex: 1,
  },
  {
    field: "invoiceLink",
    headerName: "Invoice",
    headerAlign: "center",
    align: "center",
    renderCell: ({ value }: GridRenderCellParams) => {
      return (
        <Tooltip title="Open invoice in a new tab">
          <IconButton href={value} target="_blank">
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      );
    },
  },
  {
    field: "confirmed",
    headerName: "Invoice Confirmed",
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) => {
      if (params.value) {
        return (
          <CheckIcon sx={{ color: (theme) => theme.palette.success.main }} />
        );
      } else {
        return <Close sx={{ color: (theme) => theme.palette.error.main }} />;
      }
    },
  },
  {
    field: "attachmentId",
    headerName: "Statement",
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) => {
      if (params.value) {
        return (
          <Tooltip title="Download">
            <IconButton
              onClick={async () => {
                const response = await BillingDataService.invoicePdfUrl(
                  parseInt(params.row.id),
                );
                FileSaver.saveAs(response.data.url);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    field: "emailSent",
    headerName: "Email Sent",
    renderCell: (params: GridRenderCellParams) => {
      if (params.value === "Yes") {
        return (
          <CheckIcon sx={{ color: (theme) => theme.palette.success.main }} />
        );
      } else {
        return <Close sx={{ color: (theme) => theme.palette.error.main }} />;
      }
    },
  },
  {
    field: "status",
    headerName: "Payment Status",
    renderCell: (params: GridRenderCellParams) =>
      invoiceStatuses[params.row.status].label,
  },
  {
    field: "balance",
    headerAlign: "right",
    align: "right",
    headerName: "Balance",
    renderCell: (params: GridRenderCellParams) =>
      Formatters.penniesToDollars(params.row.balance),
  },
  {
    field: "createdAt",
    headerName: "Created",
    renderCell: (params: GridRenderCellParams) => Formatters.jsDateToLocalTime(params.row.createdAt),
    flex: 1,
  },
];
