import { Box, Divider, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { PendingReviewsCounter } from "../../../PortfolioView/PendingReviewsCounter";
import { useState } from "react";
import { FacilityLogoAvatar } from "../../../../components/FacilityLogoAvatar";
// import { Add } from "@mui/icons-smaterial";
// import { CampaignCounter } from "../../../PortfolioView/CampaignCounter";
// import { NewQuickCampaignDialog } from "../CampaignsOverview/NewQuickCampaignDialog";

interface FacilityOverviewHeaderProps {
  logo?: string;
  facilityName?: string;
  facilityAddress?: string;
  activeCampaignCount?: number;
  reviewsPendingReplyCount?: number;
  handleReviewReplyDrawerOpen: () => void;
}

export function FacilityOverviewHeader({
  logo,
  facilityName,
  facilityAddress,
  activeCampaignCount,
  reviewsPendingReplyCount,
  handleReviewReplyDrawerOpen,
}: FacilityOverviewHeaderProps) {
  // const [quickCampaignDialogOpen, setQuickCampaignDialogOpen] =
  // useState<boolean>(false);

  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
            <FacilityLogoAvatar src={logo} />
            <Box>
              <Typography variant="h6" fontWeight="700">
                {facilityName}
              </Typography>
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>{facilityAddress}</Typography>
            </Box>
          </Stack>
          <Divider flexItem orientation="vertical" />
          {/* TODO: Disabled for MVP */}
          {/* <CampaignCounter count={activeCampaignCount} /> */}
          {/* <Divider flexItem orientation="vertical" /> */}
          <SafeLeaseButton onClick={handleReviewReplyDrawerOpen}>
            <PendingReviewsCounter count={reviewsPendingReplyCount} />
          </SafeLeaseButton>
        </Stack>
        {/* TODO: Disabled for MVP */}
        {/* <SafeLeaseButton
          sx={{ paddingTop: 1, paddingBottom: 1 }}
          variant="contained"
          color="blue"
          startIcon={<Add />}
          onClick={() => setQuickCampaignDialogOpen(true)}
        >
          Quick Campaign
        </SafeLeaseButton> */}
      </Stack>
      {/* <NewQuickCampaignDialog
          open={quickCampaignDialogOpen}
          handleClose={() => setQuickCampaignDialogOpen(false)}
        /> */}
    </SafeLeaseCard>
  );
}
