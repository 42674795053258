import React from "react";
import { Typography } from "@mui/material";
import ThemedButton from "../shared/ThemedButton";
import { useOrganizationSettings } from "../../useOrganizationSettingsStore";
import { DialogView } from "../DialogViews";

interface LlcProps {}

export const LlcSettings: React.FC<LlcProps> = () => {
  const { dialogView: view, setDialogView } = useOrganizationSettings();

  const handleGoBackClick = () => {
    console.log("GO BACK CLICKED");
  };

  return (
    <>
      {view === DialogView.LLC_SETTINGS && (
        <div>
          <Typography
            sx={{
              fontColor: "#031E30",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Open Sans",
            }}
            gutterBottom
          >
            Area 1
          </Typography>
          <Typography
            sx={{
              fontColor: "#929292",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "16px",
              fontFamily: "Open Sans",
              opacity: "0.7",
              mt: 1.5,
            }}
            gutterBottom
          >
            Blah blah blah. Blah blah blah. Blah blah blah. Blah blah blah. Blah blah blah. Blah blah blah. Blah blah blah. Blah blah blah.
          </Typography>
          <ThemedButton
            onClick={() => console.log("CLICKED")}
            styles={{
              marginTop: "15px",
            }}
          >
            Button on the bottom
          </ThemedButton>
        </div>
      )}
    </>
  );
};
