import { AxiosResponse } from 'axios';
import tenantPortalHttp from './http-common-tenant-portal';
import { LoggedInUser, } from '../tenantAuth';

class TenantUserDataService {
  signup(user: CreateTenantUserFormValues) {
    return tenantPortalHttp.post("/tenant-users/signup", user);
  }

  signin(user: SigninTenantFormValues): Promise<AxiosResponse<LoggedInUser>> {
    return tenantPortalHttp.post("/tenant-users/signin", user);
  }

  addUnit(unitName: string, place_id: string) {
    return tenantPortalHttp.post("/tenant-users/add_unit", {
      place_id,
      unitName
    });
  }
}

export default new TenantUserDataService();
