import { useState } from "react";
import { useUserManagementSettings } from "../useUserManagementSettings";

// UI - libs
import { Alert, Button, Divider, Grid, Stack, Typography } from "@mui/material";

// UI - internal
import { SafeleaseFormTextField } from "../../../../common";
import RoleSelect from "./RoleSelect";
import UserPermissionsChecklist from "../UserPermissionsChecklist";
import DrawerFooter, {
  DrawerType,
  EditMode,
} from "../../../../shared/drawer-footer";
import CheckboxSelection from "../../CheckboxSelection";

// Data
import { useApolloClient } from "@apollo/client";
import { Location, User } from "../../../../utilities/generated/gql-types";
import UserDataService from "../../../../services/user.service";
import { getUsers } from "../../../../queries";
import { mixpanelEventHandler } from "../../../../utilities/reactMixpanelHandler";

// Form
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useSnackbar } from "notistack";
import { WithRequiredProperty } from "../../../../utilities/required-property-modifier";
import client from "../../../../utilities/apolloClient";
import { useAuth } from "../../../../auth";

enum Role {
  Manager = "manager",
  Employee = "employee",
}

type UserSettingsFormValues = {
  userName: string;
  userEmail: string;
  userPhone: string;
  role: Role | string;
  pendingLocations: Location[];
  permissions: {
    revenue: boolean;
    billing: boolean;
  };
};

const validationSchema = z.object({
  userName: z.string().nonempty("Name is required."),
  userEmail: z.string().nonempty("Email is required.").email(),
  userPhone: z.union([
    z.string().length(0, "Phone number must be valid or empty."),
    z
      .string()
      .regex(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        "Phone number must be valid or empty."
      ),
  ]),
  role: z.string().nonempty("Role is required."),
  pendingLocations: z
    .array(z.unknown())
    .min(1, "At least one location must be selected."),
  permissions: z.object({
    revenue: z.boolean(),
    billing: z.boolean(),
  }),
});

interface UserSettingsDrawerProps {
  currentlyEditedUser: User | null;
  locations: Location[];
  relationshipId: number;
}

export default function AddUserForm({
  currentlyEditedUser,
  locations,
  relationshipId,
}: UserSettingsDrawerProps) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { editDrawerOpenMode, closeDrawer, loading, setLoading } =
    useUserManagementSettings();

  const isNewUser = !currentlyEditedUser;

  const [userAlreadyExistsError, setUserAlreadyExistsError] =
    useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    watch,
    setValue,
    reset: resetForm,
  } = useForm<UserSettingsFormValues>({
    defaultValues: {
      userName: "",
      userEmail: "",
      userPhone: "",
      role: Role.Employee,
      pendingLocations: [],
      permissions: {
        // if an employee is adding another employee, copy their exact permission levels and disable editing them below
        revenue:
          currentlyEditedUser?.role === Role.Employee
            ? currentlyEditedUser?.permissions?.revenue
            : false,
        billing:
          currentlyEditedUser?.role === Role.Employee
            ? currentlyEditedUser?.permissions?.billing
            : false,
      },
    },
    resolver: zodResolver(validationSchema),
  });

  const handleSave: SubmitHandler<UserSettingsFormValues> = async (data) => {
    setLoading(true);
    setUserAlreadyExistsError(false);

    const pendingUser: Partial<User> = {
      name: data.userName,
      email: data.userEmail,
      phone: data.userPhone,
      role: data.role,
      locationIds: data.pendingLocations.map((location) => Number(location.id)),
      relationshipId,
      permissions:
        data.role === Role.Employee
          ? data.permissions
          : { revenue: true, billing: true }, // only employees may not have both permissions
    };

    try {
      const response = await UserDataService.add(
        pendingUser as WithRequiredProperty<User, "relationshipId">
      );

      if (Object.values(response?.data?.errors || {}).length > 0) {
        enqueueSnackbar("Error creating new user.", { variant: "error" });
      }
      if (response.data?.errors?.email) {
        setUserAlreadyExistsError(true);
        setLoading(false);
        resetForm();
        return;
      }

      await client.refetchQueries({ include: [getUsers] });
      enqueueSnackbar(
        <Typography>
          Successfuly created new user <b>{pendingUser.name}</b>.
        </Typography>,
        { variant: "success" }
      );

      resetForm();
      setLoading(false);
      closeDrawer();
      mixpanelEventHandler("User Settings - Added User");
    } catch (err) {
      enqueueSnackbar("Error creating new user.", { variant: "error" });
      setLoading(false);
    }
  };

  // "Workaround" (intended behavior?) for MUI select + react-hook-form controller
  const handleSelectChange = (incomingLocation: Location) => {
    const currentState = getValues().pendingLocations;
    if (
      currentState.find(
        (location: Location) => location.id === incomingLocation.id
      )
    ) {
      return currentState.filter(
        (location: Location) => location.id !== incomingLocation.id
      );
    } else {
      return [...currentState, incomingLocation];
    }
  };

  const handleToggleAll = () => {
    if (locations.length === getValues().pendingLocations.length) {
      setValue("pendingLocations", []);
    } else {
      setValue("pendingLocations", locations);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {editDrawerOpenMode === EditMode.Add ? "Add New User" : "Edit User"}
          </Typography>
        </Grid>
        {userAlreadyExistsError && (
          <Grid item xs={12}>
            <Alert severity="error">User already exists with this email.</Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            name="userName"
            control={control}
            rules={{ required: true }}
            disabled={loading}
            render={({ field }) => (
              <SafeleaseFormTextField
                helperText={formState.errors.userName?.message}
                error={!!formState.errors.userName?.message}
                sx={{ width: "100%" }}
                label="Name"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="userEmail"
            control={control}
            rules={{ required: true }}
            disabled={loading}
            render={({ field }) => (
              <SafeleaseFormTextField
                helperText={formState.errors.userEmail?.message}
                error={!!formState.errors.userEmail?.message}
                sx={{ width: "100%" }}
                label="Email"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="userPhone"
            control={control}
            rules={{ required: false }}
            disabled={loading}
            render={({ field }) => (
              <SafeleaseFormTextField
                helperText={formState.errors.userPhone?.message}
                error={!!formState.errors.userPhone?.message}
                sx={{ width: "100%" }}
                label="Phone"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            disabled={loading}
            render={({ field }) => (
              <RoleSelect
                currentlyEditedUser={getValues()}
                disabled={false}
                {...field}
                onChange={(value) => {
                  // Default manager to all locations, non-manager to no locations.
                  if(value == 'manager') {
                    setValue("pendingLocations", locations);
                  } else {
                    setValue("pendingLocations", []);
                  }
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Assigned location(s)</Typography>
            <Button
              variant="text"
              sx={{ textTransform: "none" }}
              onClick={handleToggleAll}
              disabled={loading}
            >
              {watch("pendingLocations").length === locations.length
                ? "Deselect"
                : "Select"}{" "}
              all
            </Button>
          </Stack>
          <Controller
            name="pendingLocations"
            control={control}
            rules={{ required: true }}
            defaultValue={[]}
            render={({ field: { value, onChange }, ...rest }) => (
              <CheckboxSelection<Location>
                sx={{ width: "100%" }}
                disabled={loading}
                options={locations || []}
                displayEmpty={true}
                renderValue={(selected: Location[]) => {
                  if (getValues()?.role === "admin")
                    return `${locations.length} Locations Selected`; // admins are associated with all locations
                  if (selected.length > 1)
                    return `${selected.length} Locations Selected`;
                  if (selected.length === 0) return "Select Locations";
                  if (selected.length === 1) return selected[0].address;
                }}
                // Disable and check all for admins
                disableOptions={getValues()?.role === "admin"}
                checkOptions={getValues()?.role === "admin"}
                value={value}
                helperText={formState.errors.pendingLocations?.message}
                error={!!formState.errors.pendingLocations?.message}
                onChange={(_, newValue) => {
                  // Generate a new set of values that are +/- the selected location
                  const newSet = handleSelectChange(
                    newValue.props.value as Location
                  );

                  // Trigger the form's onChange manually with the new set of values
                  onChange({
                    target: {
                      value: newSet,
                    },
                  });
                }}
                renderText={(option) => option.address}
                {...rest}
              />
            )}
          />
        </Grid>

        {/* Only show permissions when adding a new employee. If an existing employee is adding another employee, they will not see this. */}
        {watch("role") === Role.Employee &&
          auth.user?.role !== Role.Employee && (
            <>
              <Grid item xs={12}>
                <Divider sx={{ mb: 2 }} />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="permissions"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UserPermissionsChecklist
                      disabled={loading}
                      role={watch("role")}
                      permissions={field.value}
                      setPermissions={field.onChange}
                    />
                  )}
                />
              </Grid>
            </>
          )}
      </Grid>
      <DrawerFooter
        editMode={isNewUser ? EditMode.Add : EditMode.Edit}
        drawerType={DrawerType.UserSettings}
      />
    </form>
  );
}
