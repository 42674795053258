import { Paper, PaperProps, Typography, styled } from "@mui/material";
import React from "react";
import FiberIcon from "@mui/icons-material/FiberManualRecord";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

interface InfoPaperProps extends PaperProps {
  type: InfoPaperType;
  hasTaxId: boolean;
  billingConnected: boolean;
  paperStyles: object;
}

export enum InfoPaperType {
  BILLING,
  TAXID,
}

// This component is used to display info items like 'Billing connected' status or 'Tax ID: Missing'.
const InfoPaper: React.FC<InfoPaperProps> = ({ type, billingConnected, hasTaxId, paperStyles, ...rest }) => {
  const typographyText = () => {
    if (type === InfoPaperType.BILLING) {
      return billingConnected ? "Billing connected" : "Billing not connected";
    }
    if (type === InfoPaperType.TAXID) {
      return hasTaxId ? "Bill legal entity" : "Tax ID: Missing";
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        border: `1px solid ${billingConnected ? "#F5F7FB" : "#E9645F"}`,
        borderRadius: "4px",
        backgroundColor: !billingConnected ? "rgba(233, 100, 95, 0.05)" : "#F5F7FB",
        padding: "4px 8px",
        height: "28px",
        display: "flex",
        alignItems: "center",
        margin: "4px",
        ...paperStyles,
      }}
      {...rest}
    >
      {type === InfoPaperType.BILLING && (
        <FiberIcon
          sx={{
            color: billingConnected ? "#25C280" : "#E9645F",
            fontSize: "10px",
            marginRight: "4px",
          }}
        />
      )}
      <AccountBalanceIcon
        sx={{
          fontSize: "13px",
          fontColor: "#031E30",
          marginRight: "4px",
        }}
      />
      <Typography
        sx={{
          fontSize: "12px",
          fontColor: "#031E30",
          fontWeight: 500,
          marginRight: "4px",
          whiteSpace: "nowrap",
        }}
      >
        {typographyText()}
      </Typography>
      <InfoIcon
        sx={{
          fontSize: "14px",
          color: "#929292",
        }}
      />
    </Paper>
  );
};

export default InfoPaper;
