import http from './http-common';
import tenantPortalHttp from './http-common-tenant-portal';
import { Note, TenantsLease } from '../utilities/generated/gql-types'

class ClaimsDataService {

  confirm(payload: {
      claimId: string,
      locationId: number, 
      confirmed: boolean,
      matchedLocation: string, 
      matchAccuracy: number}) {
    const { claimId, locationId, confirmed, matchedLocation, matchAccuracy } = payload
    return http.post('claims/confirm', {
      claimId, 
      locationId,
      confirmed,
      matchedLocation,
      matchAccuracy
    })
  }

  createNote(newNote: Note) {
    console.log(newNote)
    return http.post('claims/create_note', newNote)
  }
      
  deleteNote(noteToDelete: Note) {
    return http.post('claims/delete_note', noteToDelete)
  }

  editNote(editedNote: Note) {
    return http.post('claims/edit_note', editedNote)
  }

  createOrRetrieveClaim(params: TenantsLease) {
    return tenantPortalHttp.post('claims/create_or_retrieve', params);
  }

  claimAttachmentUpload(params: ClaimAttachmentUploadRequestObject){
    return tenantPortalHttp.post('claims/claim_attachment_upload', params);
  }

  claimTypeUpload(params: {claimType: string, claimId: string, creationStep?: number}){
    return tenantPortalHttp.post('claims/claim_type_upload', params);
  }
}


export default new ClaimsDataService();
