import TableWrapper from "../../shared/table-wrapper";
import SortingTable, { TableTitles } from '../../shared/sorting-table';
import { QueryResult } from '@apollo/client';
import { llcsByClaimsColumnDefs } from '../../utilities/column-defs';
import { LlcByClaims } from '../../utilities/generated/gql-types';
import { claimsVizStore } from './claims-visualizations';

function LlcsByClaimsSubTable(props: {
  queryId: number,
  queryResult: QueryResult,
}) {
  const { queryId: relationshipId, queryResult: llcsByClaims } = props;
  return (
    <TableWrapper
        title={TableTitles.LlcsByClaims}
        columns={llcsByClaimsColumnDefs}
        isSubTable={true}>
      <SortingTable
          title={TableTitles.LlcsByClaims}
          columns={llcsByClaimsColumnDefs}
          queryResultData={llcsByClaims.data?.getLlcsByClaims}
          queryResult={llcsByClaims}
          onRowClick={row => e => {
            claimsVizStore.setState({llcId: row.id})
            claimsVizStore.setState({llcName: row.name})
          }}
          isSubTable={true} />
    </TableWrapper>
  );
}

function llcsByClaimsSubTableInfo(
  llcsByClaims: QueryResult<{ getLlcsByClaims: LlcByClaims[] }>,
  setLlcId: (llcId: number) => void
) {
  const structureLlcsByClaimsQuery = (relationshipId: number) => {
    return {
      relationshipId
    }
  }
  return {
    component: LlcsByClaimsSubTable,
    queryResult: llcsByClaims,
    structureQuery: structureLlcsByClaimsQuery,
  }
}

export default llcsByClaimsSubTableInfo
