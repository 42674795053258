import { usePrivatePolicySettingsStore } from "../usePrivatePolicySettingsStore/usePrivatePolicySettingsStore";
import {
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  inverseNavyButton,
  navyButton,
} from "../../../../styles/mui-overrides";
import DatePicker from "../../../../components/lib/SafeLeaseDatePicker";
import { useState } from "react";
import dayjs from "dayjs";
import { PrivatePolicyComplianceConfig } from "../../../../utilities/generated/gql-types";

type PrivatePolicyConfigurationModalProps = {
  handleApply: () => void;
  handleClose: () => void;
  privatePolicyConfigs: PrivatePolicyComplianceConfig[]; // the list of private policy configs for the selected relationship.
};

function PrivatePolicyComplianceModalMenu({
  handleApply,
  handleClose,
  privatePolicyConfigs,
}: PrivatePolicyConfigurationModalProps) {
  const [fmsEnforcementDateError, setFmsEnforcementDateError] = useState(false);
  const [intEnforcementDateError, setIntEnforcementDateError] = useState(false);

  const configMenuOpen = usePrivatePolicySettingsStore(
    (state) => state.configMenuOpen
  );
  const internalEnforcementEnabled = usePrivatePolicySettingsStore(
    (state) => state.internalEnforcementEnabled
  );
  const setInternalEnforcementEnabled = usePrivatePolicySettingsStore(
    (state) => state.setInternalEnforcementEnabled
  );
  const fmsEnforcementEnabled = usePrivatePolicySettingsStore(
    (state) => state.fmsEnforcementEnabled
  );
  const setFmsEnforcementEnabled = usePrivatePolicySettingsStore(
    (state) => state.setFmsEnforcementEnabled
  );
  const selectedFmsEnforcementDate = usePrivatePolicySettingsStore(
    (state) => state.selectedFmsEnforcementDate
  );
  const setSelectedFmsEnforcementDate = usePrivatePolicySettingsStore(
    (state) => state.setSelectedFmsEnforcementDate
  );
  const selectedIntEnforcementDate = usePrivatePolicySettingsStore(
    (state) => state.selectedIntEnforcementDate
  );
  const setSelectedIntEnforcementDate = usePrivatePolicySettingsStore(
    (state) => state.setSelectedIntEnforcementDate
  );

  const handleDateChange = (
    date: Date,
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    setDate: (date: dayjs.Dayjs) => void,
    dateKeyToCompareAgainstExistingConfigs: string
  ) => {
    const dayJsDate = dayjs(date);
    if (dayJsDate.isValid()) {
      if (dayJsDate.isBefore(dayjs(), "day")) {
        if (
          privatePolicyConfigs.some((config) =>
            dayjs(config[dateKeyToCompareAgainstExistingConfigs]).isSame(
              dayJsDate,
              "day"
            )
          )
        ) {
          setError(false);
          setDate(dayJsDate);
        } else {
          // Date is in the past and no existing configs have used this date
          setError(true);
        }
      } else {
        setError(false);
        setDate(dayJsDate);
      }
    } else {
      // Parsing error or invalid date
      setError(true);
    }
  };

  return (
    <Modal
      open={configMenuOpen}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Stack
        spacing={3}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "5px",
          padding: "32px",
        }}
      >
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack alignItems="center" spacing={1} direction="row">
                <Typography variant="h6">FMS Enforcement</Typography>
                <Tooltip
                  placement="right"
                  title="Enable/disable private policy compliance for private policies that were submitted on the FMS directly instead of going through SafeLease."
                >
                  <InfoOutlinedIcon color="disabled" />
                </Tooltip>
              </Stack>
              <Switch
                checked={fmsEnforcementEnabled}
                onChange={() =>
                  setFmsEnforcementEnabled(!fmsEnforcementEnabled)
                }
              />
            </Box>
            {fmsEnforcementEnabled && (
              <Stack direction="row" spacing={1} alignItems="center">
                <DatePicker
                  label="Date"
                  disabled={!fmsEnforcementEnabled}
                  value={selectedFmsEnforcementDate}
                  onChange={(newDate: Date) =>
                    handleDateChange(
                      newDate,
                      setFmsEnforcementDateError,
                      setSelectedFmsEnforcementDate,
                      "fmsEnforcementDate"
                    )
                  }
                  slotProps={{
                    textField: {
                      error: fmsEnforcementDateError,
                      helperText: fmsEnforcementDateError
                        ? "Date must be valid and in the future"
                        : "",
                    },
                  }}
                  disablePast
                />
                <Tooltip
                  placement="right"
                  title="This is the date that SafeLease will begin enforcing private policy compliance that is set for the private policies that are found in the FMS."
                >
                  <InfoOutlinedIcon color="disabled" />
                </Tooltip>
              </Stack>
            )}
          </Stack>
          <Divider variant="middle" />
          <Stack spacing={1}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack alignItems="center" spacing={1} direction="row">
                <Typography variant="h6">Internal Enforcement</Typography>
                <Tooltip
                  placement="right"
                  title="Enable/disable private policy compliance for private policies that were submitted through SafeLease. This will also require all private policies to be submitted directly through Safelease. Any private policies from the fms will be removed."
                >
                  <InfoOutlinedIcon color="disabled" />
                </Tooltip>
              </Stack>
              <Switch
                checked={internalEnforcementEnabled}
                onChange={() =>
                  setInternalEnforcementEnabled(!internalEnforcementEnabled)
                }
              />
            </Box>
            {internalEnforcementEnabled && (
              <Stack direction="row" spacing={1} alignItems="center">
                <DatePicker
                  label="Date"
                  disabled={!internalEnforcementEnabled}
                  value={selectedIntEnforcementDate}
                  onChange={(newDate: Date) =>
                    handleDateChange(
                      newDate,
                      setIntEnforcementDateError,
                      setSelectedIntEnforcementDate,
                      "intEnforcementDate"
                    )
                  }
                  slotProps={{
                    textField: {
                      error: intEnforcementDateError,
                      helperText: intEnforcementDateError
                        ? "Date must be valid and in the future"
                        : "",
                    },
                  }}
                  disablePast
                />
                <Tooltip
                  placement="right"
                  title="This is the date that SafeLease will begin enforcing private policy compliance that is set for the private policies that are submitted directly through SafeLease. This will also be the day that existing FMS private policies will be removed."
                >
                  <InfoOutlinedIcon color="disabled" />
                </Tooltip>
              </Stack>
            )}
          </Stack>
          <Divider variant="middle" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            disableElevation
            sx={{ ...inverseNavyButton, width: "165px" }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            sx={{ ...navyButton, width: "165px" }}
            variant="contained"
            onClick={handleApply}
            disabled={fmsEnforcementDateError || intEnforcementDateError}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}

export default PrivatePolicyComplianceModalMenu;
