import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useAdminBillingStore } from "../useAdminBillingStore";
import { Close as CloseIcon } from "@mui/icons-material";
import { QueryResult, useQuery } from "@apollo/client";
import { getInvoiceLineItems } from "../../../queries";
import {
  Invoice,
  InvoiceLineItem,
} from "../../../utilities/generated/gql-types";
import LoadingButton from "@mui/lab/LoadingButton";
import _, { Dictionary } from "lodash";
import InvoiceBalanceCard from "./InvoiceBalanceCard";
import InvoiceLineItemsCard from "./InvoiceLineItemsCard";
import { ghostButtonNavy, muiRedButton } from "../../../styles/mui-overrides";
import BillingDataService from "../../../services/billing.service";
import React from "react";
import { useSnackbar } from "notistack";

type InvoiceDetailsDrawerProps = {
  invoicesQuery: QueryResult<{ getInvoices: Invoice[] }>;
};

const InvoiceDetailsDrawer = ({ invoicesQuery }: InvoiceDetailsDrawerProps) => {
  const { selectedInvoiceForDrawer, setSelectedInvoiceForDrawer } =
    useAdminBillingStore();
  const { enqueueSnackbar } = useSnackbar();
  const [toggleConfirmStatusPending, setToggleConfirmStatusPending] =
    React.useState(false);
  const drawerOpen = selectedInvoiceForDrawer !== null;
  const invoiceLineItemsQuery: QueryResult<{
    getInvoiceLineItems: InvoiceLineItem[];
  }> = useQuery(getInvoiceLineItems, {
    skip: !selectedInvoiceForDrawer,
    variables: { invoiceId: selectedInvoiceForDrawer?.id },
  });
  const locationIdsToInvoiceLineItems: Dictionary<InvoiceLineItem[]> =
    _.groupBy(invoiceLineItemsQuery?.data?.getInvoiceLineItems, "locationId");

  const handleClose = () => {
    setSelectedInvoiceForDrawer(null);
  };

  const toggleConfirmInvoice = async () => {
    if (
      selectedInvoiceForDrawer.confirmed &&
      !window.confirm(
        "By unconfirming the invoice, the PDF will be deleted. Are you sure you want to do this?"
      )
    ) {
      return;
    }
    try {
      setToggleConfirmStatusPending(true);
      await BillingDataService.confirmInvoice(
        parseInt(selectedInvoiceForDrawer.id),
        !selectedInvoiceForDrawer.confirmed
      );
      invoicesQuery.refetch();
      setToggleConfirmStatusPending(false);
      handleClose();
    } catch (e) {
      enqueueSnackbar("Failed to change the invoice confirmation status.", {
        variant: "error",
      });
      setToggleConfirmStatusPending(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      onClose={handleClose}
      open={drawerOpen}
      PaperProps={{
        sx: { width: "60%", minWidth: "400px", backgroundColor: "#f2f3f5" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "25px",
          paddingTop: "15px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography color="rgba(21, 39, 68, 1)" variant="h5">
          {selectedInvoiceForDrawer?.billingEntityName}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack
        spacing={3}
        direction="row"
        sx={{
          paddingLeft: "25px",
          paddingRight: "25px",
          paddingBottom: "10px",
          display: "flex",
        }}
      >
        <InvoiceBalanceCard
          autoConfirmed={selectedInvoiceForDrawer?.confirmed}
          balanceAmount={selectedInvoiceForDrawer?.balance}
        />
        <Box>
          <LoadingButton
            loading={toggleConfirmStatusPending}
            onClick={toggleConfirmInvoice}
            sx={
              selectedInvoiceForDrawer?.confirmed
                ? ghostButtonNavy
                : muiRedButton({})
            }
          >
            {selectedInvoiceForDrawer?.confirmed ? "Unconfirm" : "Confirm"}
          </LoadingButton>
        </Box>
      </Stack>
      <Divider />
      <Grid container overflow="auto">
        {selectedInvoiceForDrawer?.autoConfirmFailureReason && (
          <Grid item xs={12} paddingInline="25px" paddingTop="10px">
          <Alert severity="error">
            {selectedInvoiceForDrawer.autoConfirmFailureReason}
          </Alert>
        </Grid>
        )}
        {invoiceLineItemsQuery.loading ? (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          Object.entries(locationIdsToInvoiceLineItems).map(
            ([locationId, lineItems]) => (
              <Grid item xs={12} padding="25px" key={locationId}>
                <InvoiceLineItemsCard lineItems={lineItems} />
              </Grid>
            )
          )
        )}
      </Grid>
    </Drawer>
  );
};

export default InvoiceDetailsDrawer;
