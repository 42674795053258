import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { claimsColumnDefs } from "../../utilities/column-defs";
import { mixpanelEventHandler } from "../../utilities/reactMixpanelHandler";
import { Drawer } from "@mui/material";
import { TableRow } from "../../utilities/table-state";
import SortingTable from "../../shared/sorting-table";
import { ZeroState } from "../../common";
import { Claim } from "../../utilities/generated/gql-types";
import { doClaimStatusTriage } from "../../column-renderers/claim-status-pill";
import ClaimDetailsDrawer from "../../claims/claim-details-drawer";
import RouterHelper from "../../utilities/router-helper";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { claimNotesEditingStateAtom } from "../../claims/claim-details-drawer";
import { NotificationsContext } from "../../utilities/notifications-context";
import TableWrapper from "../../shared/table-wrapper";
import { useAuth } from "../../auth";

function ClaimsTable(props: {
  claimsQueryResultData: Claim[];
  routerHelper: RouterHelper;
}) {
  const { claimsQueryResultData, routerHelper } = props;
  const routerParams: { claimId?: string; relationshipId?: string } =
    useParams();
  const history = useHistory();
  const auth = useAuth();
  const notifsHook = useContext(NotificationsContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [claimNotesEditingState, setClaimNotesEditingState] = useAtom(
    claimNotesEditingStateAtom
  );
  const resetClaimNotesEditingState = useResetAtom(claimNotesEditingStateAtom);

  useEffect(() => {
    if (routerParams?.claimId) {
      const selectedClaimInQueryResult = claimsQueryResultData.find((claim) => {
        return claim.externalId == routerParams?.claimId;
      });
      if (selectedClaimInQueryResult) {
        setSelectedClaim(selectedClaimInQueryResult);
        setDrawerOpen(true);
      }
    }
  }, [
    JSON.parse(JSON.stringify(claimsQueryResultData)),
    JSON.parse(JSON.stringify(routerParams)),
  ]);

  const toggleDrawer =
    (open: boolean, claim: Claim) => (event: KeyboardEvent | MouseEvent) => {
      if (open) {
        mixpanelEventHandler("Dashboard - Clicked Claim");
        routerHelper.navigateToRoute(
          "claim",
          routerHelper.getRelationshipId(),
          history,
          claim.externalId
        );
      } else {
        routerHelper.navigateToRoute(
          "dashboard",
          routerHelper.getRelationshipId(),
          history
        );
      }
      resetClaimNotesEditingState();
      setSelectedClaim(claim);
      setDrawerOpen(open);
    };

  const onClaimRowClick = (row: any) => {
    return toggleDrawer(true, row);
  };

  const modifyClaimsRows = (): TableRow[] => {
    return claimsQueryResultData.map((claim) => {
      return { ...claim, shortStatus: doClaimStatusTriage(claim.status) };
    });
  };

  const sortClaimsByNotifications = (row1: Claim, row2: Claim): number => {
    const row1HasNotif = notifsHook.NotificationsHelper.claimHasNotification(
      row1,
      notifsHook?.allNotifications
    );
    const row2HasNotif = notifsHook.NotificationsHelper.claimHasNotification(
      row2,
      notifsHook?.allNotifications
    );

    if (row1HasNotif && row2HasNotif) {
      return 0;
    } else if (!row1HasNotif && !row2HasNotif) {
      return 0;
    } else if (row1HasNotif) {
      return -1;
    }
    return 1;
  };

  return (
    <div className="widget widget__long widget__long widget__claims-table">
      <div className="widget-title widget-title__claims">Claims</div>
      {claimsQueryResultData.length === 0 && (
        <div className="widget--zero-state">
          <ZeroState
            title="No claims"
            body=""
            svg="/images/no-reports-zero-state.svg"
          />
        </div>
      )}
      {claimsQueryResultData.length > 0 && (
        <>
          <Drawer
            anchor={"right"}
            open={drawerOpen}
            onClose={
              claimNotesEditingState.pendingText
                ? (e) =>
                    setClaimNotesEditingState((s) => ({
                      ...s,
                      userIsTryingToExit: true,
                    }))
                : toggleDrawer(false, null)
            }
          >
            <ClaimDetailsDrawer
              claim={selectedClaim}
              closeDrawer={() => toggleDrawer(false, null)}
            />
          </Drawer>
          <TableWrapper title="Claims Table" columns={claimsColumnDefs}>
            <SortingTable
              title="Claims Table"
              columns={claimsColumnDefs}
              queryResultData={claimsQueryResultData}
              modifyRows={modifyClaimsRows}
              defaultSorter={sortClaimsByNotifications}
              onRowClick={
                auth.user.isAdmin ||
                auth.user.relationshipId == routerHelper.getRelationshipId()
                  ? onClaimRowClick
                  : undefined
              }
            />
          </TableWrapper>
        </>
      )}
    </div>
  );
}

export default ClaimsTable;
