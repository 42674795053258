import { InfoOutlined } from "@mui/icons-material";
import { Box, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { SafeLeaseAvatarWithText, SafeLeaseChip } from "@safelease/components";
import dayjs from "dayjs";
import { issueTypeLabels } from "./issueTypeLabels";
import { FeedbackResponseActionArea } from "../../../../components/FeedbackResponseActionArea";

export const facilityFeedbackListGridColDefs: GridColDef<any>[] = [
  {
    field: "contact",
    headerName: "Tenant",
    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography>Tenant</Typography>
        <Tooltip title="Name of the person who left the feedback">
          <InfoOutlined
            sx={{
              height: 18,
              color: (theme: Theme) => theme.palette.grey.A100,
            }}
          />
        </Tooltip>
      </Stack>
    ),
    width: 200,
    renderCell: ({ value }) => <SafeLeaseAvatarWithText text={value?.name} subText={value?.unit} />,
  },
  {
    field: "feedback",
    headerName: "Tenant Feedback",
    flex: 2,
  },
  {
    field: "issueTypes",
    headerName: "Topics",
    width: 350,
    renderCell: ({ value }) => {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
          {value.map((issueType: string) => (
            <SafeLeaseChip key={issueType} label={issueTypeLabels[issueType]} sx={{ mr: 1, mb: 1 }} color="neutral" />
          ))}
        </Box>
      );
    },
    flex: 1.5,
  },
  {
    field: "createdAt",
    headerName: "Date Submitted",
    flex: 0.75,
    valueFormatter: ({ value }) => dayjs(value).format("MMM DD, YYYY"),
  },
  {
    field: "campaign",
    headerName: "Campaign",
    flex: 0.75,
    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography variant="body2">Campaign</Typography>
        <Tooltip title="If we were able to associate a review with a campaign, we will identify it here.">
          <InfoOutlined sx={{ height: 18, color: (theme: Theme) => theme.palette.grey.A100 }} />
        </Tooltip>
      </Stack>
    ),
    valueGetter: ({ row }) => row.message?.campaign?.name,
  },
  {
    field: "id",
    headerName: "Response Actions",
    renderCell: ({ row }) => {
      return (
        <Stack direction="row" alignItems="flex-right">
          <FeedbackResponseActionArea feedback={row} />
        </Stack>
      );
    },
    flex: 2,
  },
];
