import { Grid, Stack, TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTenantAuth } from "../../../tenantAuth";
import TenantUserDataService from "../../../services/tenant-user.service";
import { Image } from "../../../image";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";

type TenantSignInProps = {};

const validationSchema = z.object({
  email: z.string().nonempty("Email is required.").email(),
  password: z.string().nonempty("Password is required."), //TODO: Add more password validation
});

function TenantSignIn({}: TenantSignInProps) {
  const history = useHistory();
  const tenantAuth = useTenantAuth();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, formState } = useForm<SigninTenantFormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const onLoginButtonClicked: SubmitHandler<{
    email: string;
    password: string;
  }> = async (data) => {
    setLoading(true);
    try {
      const signInResponse = await TenantUserDataService.signin(data);
      console.log(signInResponse);
      if (signInResponse.status === 200) {
        await tenantAuth.login(signInResponse.data);
        history.replace("/");
        return;
      }
    } catch (error) {
      if (error.response.status === 401) {
        enqueueSnackbar(error.response.data, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong. Please try again later.", {
          variant: "error",
        });
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onLoginButtonClicked)}>
      <Grid container>
        <Grid item container xs={12} justifyContent="center" paddingTop="30px">
          <Image
            className="tw-mb-[30px]"
            src="/images/safelease_logo.svg"
            alt="SafeLease logo"
          />
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Typography variant="h4">Tenant Portal</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          marginBottom="30px"
        >
          <Typography variant="h5">File and manage your claims</Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Stack spacing={2}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.email?.message}
                  error={!!formState.errors.email?.message}
                  sx={{ width: "328px" }}
                  label="Email"
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.password?.message}
                  error={!!formState.errors.password?.message}
                  sx={{ width: "328px" }}
                  label="Password"
                  type="password"
                  {...field}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          marginBottom="20px"
        >
          <a href="" style={{ textDecoration: "none" }}>
            Forgot password?
          </a>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          marginBottom="10px"
        >
          <Typography>
            Don't have an account?{" "}
            <a href="/signup" style={{ textDecoration: "none" }}>
              Sign up
            </a>
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <LoadingButton
            variant="contained"
            disableElevation
            sx={{ width: "220px", alignSelf: "center", textTransform: "none" }}
            type="submit"
            loading={loading}
          >
            Login
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export { TenantSignIn };
