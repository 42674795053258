import axios from "axios";

const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-type": "application/json",
    "X-SafeLease-Build": process.env.REACT_APP_BUILD || '',
  },
});

instance.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem('tenantUser'));

  const headers = config.headers.common;
  if (user && user.accessToken) {
    headers['Authorization'] = "Bearer " + user.accessToken;
  }

  config.headers.common = headers;

  return config;
}, function (error) {
  return Promise.reject(error);
});


export default instance;
