import { grey } from "@mui/material/colors";
import { SortDirection } from "../utilities/table-state";

export const muiAlert = {
  height: '40px',
  width: '350px',
  border: '1px solid #84D43D',
  backgroundColor: '#84D43D',
  borderRadius: '20px',
  fontWeight: 'bolder',
  color: '#FFFFFF',
  fill: 'white',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}

export const muiAvatar = (payload: { [index: string]: any }) => {
  const styles = {
    backgroundColor: 'rgba(21,39,68,0.1)',
    border: '4px solid white',
    paddingTop: '5px',
    '&:hover': {
      border: '4px solid #FFFFFF',
      backgroundColor: 'rgba(21,39,68,0.2)',
    },
  };

  if (payload.active) {
    delete styles['&hover'];
    styles.backgroundColor = '#FFFFFF';
    styles.border = '4px solid #2879FB';
  }

  return styles;
};

export const muiDefaultAvatarIcon = (payload: { [index: string]: any }) => {
  const styles = {
    color: '#6E82A9',
    width: '40px',
    height: '40px',
  };

  if (payload.active) styles.color = '#2879FB';

  return styles;
};

export const muiMenu = {
  width: '161px',
  paddingTop: '6px',
  boxShadow: '0px 7px 10px 0 rgba(0,0,0,0.09)'
}

export const muiMenuItem = {
  height: '30px', 
  fontSize: '12px', 
  color: '#4E4E4E',
  fontFamily: 'Open Sans, Arial, sans-serif',
  fontWeight: 600,
  paddingLeft: '20px',
  '&:hover': {
    backgroundColor: '#2879FB',
    color: 'white'
  }
}

export const muiPhotosBg = {
  bgcolor: 'rgba(0,0,0,0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const muiPhotosXButtons = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  marginTop: '30px',
  marginRight: '40px',
  color: 'rgba(255,255,255,0.3)',
  '&:hover': {
    color: 'rgba(255,255,255,0.6)',
  },
}

export const muiTextField = {
  marginBottom: '45px',
  width: '328px',
  height: '48px',
}

export const muiTextFieldForPP = {
  ...muiTextField,
  marginBottom: '0px',
}

export const muiTextFieldForTenantUserSignup = {
  width: "100%",
  marginBottom: "15px"
}

export const muiSelect = {
  backgroundColor: 'white',
  borderColor: '#EBEFF7',
  color: '#152744',
  fontSize: '12px',
  lineHeight: '17px',
  textAlign: 'center',
  fontWeight: 'bold',
  padding: '7px',
  marginRight: '20px',
  marginLeft: '10px', 
  borderRadius: '18px',
  height: '34px',
  fontStyle: 'normal',
}

export const muiButtonAsSafeleaseSelect = {
  ...muiSelect,
  border: '1px solid #C4C4C4',
  'text-transform': 'capitalize',
  padding: '1rem'
}

//TODO this will be the overwrite for claim-details--ok-button
export const muiRedButton = (payload: { maxWidth?: boolean, bg?: string } = { }) => {
  return {
    maxWidth: payload.maxWidth ? '104px' : null,
    width: '104px',
    height: '36px',
    backgroundColor: payload.bg || '#E9645F',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '4px',
    marginTop: '15px',
    marginBottom: '15px',
    '&:hover': {
      backgroundColor: payload.bg || '#EB504A',
    },
    '&:active': {
      backgroundColor: payload.bg || '#D83F39',
    },
    '&:disabled': {
      backgroundColor: grey[300],
      color: grey[500]
    },
    textTransform: 'none'
  }
}

export const muiRedOutlineButton = () => {
  return {
    width: '104px',
    height: '36px',
    backgroundColor: 'white',
    color: '#E9645F',
    fontWeight: 'bold',
    border: '1px solid #E9645F',
    borderRadius: '4px',
    marginTop: '15px',
    marginBottom: '15px',
    '&:hover': {
      backgroundColor: '#E9645F',
      color: 'white'
    },
    textTransform: 'none'
  }
}

export const muiBlankButton = {
  width: '104px',
  height: '36px',
  backgroundColor: '#FFFFFF',
  color: '#152744',
  fontWeight: 'bold',
  border: '1px solid #152744',
  borderRadius: '4px',
  marginTop: '15px',
  marginBottom: '15px',
  '&:hover': {
    backgroundColor: '#cbcdd0',
  },
  textTransform: 'none'
}

export const muiAltModalButton = {
  width: '104px',
  height: '36px',
  backgroundColor: '#152744',
  color: 'white',
  fontWeight: 'bold',
  borderRadius: '4px',
  marginTop: '15px',
  marginBottom: '15px',
  '&:hover': {
    backgroundColor: '#031E30',
  },
  '&:active': {
    backgroundColor: '#2779FB',
  },
  '&:disabled': {
    backgroundColor: '#CED7E7',
    color: 'white'
  },
  textTransform: 'none'
}

export const muiUserFeedbackModal = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
}

export const muiFeedbackTextField = {
  width: '100%',
  backgroundColor: '#e3e9f7',
}

export const ghostButtonNavy = { 
  backgroundColor: 'transparent',
  border: '1px solid #152744',
  color: '#152744',
  borderRadius: '4px',
  marginLeft: '10px',
  textTransform: 'none',
  boxShadow: 'none',
}

export const muiSettingsCard = (payload?: { grey?: boolean, round?: boolean } = {}) => {
  return {
    color: '#152744',
    backgroundColor: payload?.grey ? '#FAFAFC' : 'white',
    borderRadius: payload?.round ? '17px' : '4px',
    border: '1px solid #EBEFF7',
    textTransform: 'none',
  }
}

export const muiSettingsButton = (payload?: { grey?: boolean, round?: boolean } = {}) => {
  return {
    color: '#152744',
    backgroundColor: payload?.grey ? '#FAFAFC' : 'white',
    borderRadius: payload?.round ? '17px' : '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: '1px solid #EBEFF7',
    width: payload?.round ? '175px' : '276px',
    height: payload?.round ? '34px' : '53px',
    textTransform: 'none',
    paddingLeft: payload?.round ? '15px' : '24.5px',
    paddingRight: payload?.round ? '5px' : '24.5px',
    fontWeight: 'bold',
    marginBottom: payload?.round ? '0px' : '19px',
    '&:hover': {
      borderColor: '#152744',
      backgroundColor: payload?.grey ? '#FAFAFC' : 'white'
    },
    '&:active': {
      backgroundColor: '#152744',
      color: 'white' 
    }
  }
}

export const muiSettingsButtonColor = (payload: {selected: boolean}) => {
  return {
    backgroundColor: payload.selected ? '#152744' : '#FAFAFC',
    color: payload.selected ? '#FFFFFF' : '#152744',
    '&:hover': payload.selected && {
      backgroundColor: '#152744'
    }
  }
}

export const muiSettingsButtonIcon = (payload: {isHovered: boolean, round?: boolean}) => {
  return {
    color: payload.isHovered ? '#152744' : '#CED7E7',
    backgroundColor: payload.isHovered ? 'white' : 'none',
    borderRadius: '20px',
  }
}

export const muiSigninButton = {
  width: '100%',
  textTransform: 'none'
}


export const muiModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  backgroundColor: 'white',
  //border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  padding: '32px',
  '&:active': {
    border: 'none'
  }
};

export const muiSortButton = (payload: {isSorted: boolean}) => {
  return {
    marginLeft: '5px',
    height: '5px',
    width: '5px',
    color: payload.isSorted ? '#6E82A9' : '#CED7E7',
    '&:hover': {
      color: '#6E82A9',
      backgroundColor: 'transparent'
    }
  }
};


export const muiDatePickerConfirmButton = {
  marginLeft: '10px',
};

export const muiTableCell = (payload: {
  sortDirection: SortDirection,
  isRowHovered?: boolean,
  isRowExpanded?: boolean,
  isColumnHidden?: boolean
  cellPadding?: string
}) => {
  const isSorted = payload.sortDirection !== SortDirection.Neutral;
  let backgroundColor: string;
  if (payload.isRowExpanded && payload.isRowHovered) {
    backgroundColor = '#CBDFF6'
  } else if (payload.isRowExpanded) {
    backgroundColor = '#E3E9F7'
  } else if (payload.isRowHovered && isSorted) {
    backgroundColor = '#CBDFF6'
  } else if (isSorted) {
    backgroundColor = '#E3E9F7'
  } else if (payload.isRowHovered) {
    backgroundColor = '#FAFAFC'
  } else {
    backgroundColor = 'white'
  }
  const styles = {
    color: '#152744',
    backgroundColor,
    borderLeft: isSorted ? '1px solid #D8D8D8' : 'none',
    cursor: 'pointer',
    padding: payload.cellPadding || '9px'
  }

  if (payload.isColumnHidden) {
    styles.display = 'none'
  }
  return styles
}

export const muiArrow = (payload) => {
  const styles = {};
  if (payload.direction === 'up') styles['color'] = '#84D43D';
  if (payload.direction === 'down') styles['color'] = '#E9645F';
  return styles;
}

export const muiTooltip = {
  padding: '0',
  minWidth: '0',
  'margin': '0 0 0 2px',
  '&:hover': {
    backgroundColor: '#152744',
    opacity: '90%'
  }
}

export const muiStatisticTooltip = {
  padding: '0px',
  margin: '0 0 0 2px',
  justifyContent: 'flex-start',
  minWidth: '0px',
  '&:hover': {
    backgroundColor: '#152744',
    opacity: '90%',
  }
}

export const muiBooleanIcon = (value) => {
  return {
    color: value ? '#84D43D' : '#E9645F',
  }
}

export const muiDrawer = {
  width: 833, 
  marginLeft: '50px', 
  marginRight: '50px', 
  marginTop: '32px', 
  height: '100vh'
}

export const muiSettingsItem = (payload?: {long: boolean, halfLong: boolean}) => {
  let width = '275px'
  if (payload?.long) width = '328px'
  else if (payload?.halfLong) width = '150px'

  return {
    width,
    maxHeight: '48px',
    marginBottom: '20px',
    marginRight: payload?.halfLong ? '28px' : '0px',
  }
}

export const muiTextFieldInput = {
  fontSize: '14px'
}

export const muiLabelSmall = (payload?: {bold: boolean}) => {
  return {
    fontSize: '12px',
    fontWeight: payload?.bold ? 600 : 'normal',
    color: '#152744',
    fontFamily: "Open Sans",
    letterSpacing: payload?.bold ? '0.21px' : 'normal'
  }
}

export const muiFaintIconButton = {
  color: '#CED7E7',
  width: '20px',
  height: '20px'
}

export const muiRedIconButton = {
  color: '#E9645F',
  width: '20px',
  height: '20px'
}

export const muiGreenIconButton = (payload: {isActive: boolean}) => ({
  color: payload.isActive ? '#84D43D' : '#CED7E7',
  width: '20px',
  height: '20px'
})

export const muiTinyLabel = {
  fontSize: 'x-small',
  marginBottom: '8px'
}

export const tableActionButton = {
  margin: '0.25rem',
  padding: '0.25rem',
  minWidth: '32px',
}

export const redCTAButton = (
  payload: {
    width: string;
    height: string;
    margin: string;
  } = { margin: null, height: null, width: null }
) => {
  return {
    float: 'right',
    margin: payload.margin || '1rem',
    backgroundColor: '#e9645f',
    width: payload.width || '7rem',
    height: payload.height || '2.5rem',
    color: '#ffffff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#e9645f',
      opacity: '80%',
    },
  };
};

export const blackButton = {
  color: 'white',
  borderRadius: '5px',
  backgroundColor: 'black',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#5c5c5c'
  },
  '&:disabled': {
    backgroundColor: 'rgba(140,140,140,0.3)'
  }
};

export const navyButton = {
  color: 'white',
  borderRadius: '5px',
  backgroundColor: '#152744',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#5c5c5c'
  },
  '&:disabled': {
    backgroundColor: 'rgba(140,140,140,0.3)'
  }
};

export const primaryColorButton = {
  color: 'white',
  borderRadius: '5px',
  backgroundColor: 'rgba(40, 121, 251, 1)',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(40, 121, 251, 0.7)'
  },
  '&:disabled': {
    backgroundColor: 'rgba(140,140,140,0.3)'
  }
}

export const inverseNavyButton = {
  color: 'black', // Set the text color to dark
  borderRadius: '5px',
  backgroundColor: 'rgba(220, 220, 220, 0.3)', // Very faint gray
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(200, 200, 200, 0.4)' // Slightly darker gray on hover
  },
  '&:disabled': {
    backgroundColor: 'rgba(140,140,140,0.1)', // Very faint gray for disabled state
    color: 'rgba(0, 0, 0, 0.5)' // Faded black text for disabled state
  }
};