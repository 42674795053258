import { Close, ForwardToInboxOutlined } from "@mui/icons-material";
import { Dialog, DialogProps, IconButton, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { Campaign, CampaignStep } from "@safelease/service-utilities";
import { CampaignConfigurationSteps } from "./CampaignConfigurationSteps";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "../../../../auth";
import { useReputation } from "../../../useReputation";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";

const campaignSchema = z.object({
  campaignSteps: z.array(
    z
      .object({
        id: z.string().optional(),
        medium: z.literal("email"),
        subject: z.string().min(1, "Must include a subject").max(255, "Subject must be less than 255 characters"),
        templateText: z.string().min(1, "Must include a email body").max(5000, "Email body must be less than 5000 characters"),
        stepDelayDays: z.number().min(0, "Delay cannot be negative").or(z.string().min(0, "Delay cannot be negative")),
        enabled: z.boolean(),
      })
      .or(
        z.object({
          id: z.string().optional(),
          medium: z.literal("text"),
          templateText: z.string().min(1, "Must include a text body").max(900, "Text body must be less than 900 characters"),
          stepDelayDays: z.number().min(0, "Delay cannot be negative").or(z.string().min(0, "Delay cannot be negative")),
          enabled: z.boolean(),
        }),
      ),
  ),
});

interface CampaignConfigurationDialogProps extends DialogProps {
  campaign: Campaign;
  handleClose: () => void;
}

export function CampaignConfigurationDialog({ open, handleClose, campaign }: CampaignConfigurationDialogProps) {
  const { statuses, setCampaignConfigurationFormSubmitting, SafeLeaseAPI } = useReputation();
  const auth = useAuth();
  const isAdmin = auth.user?.isAdmin;
  const form = useForm({
    defaultValues: {
      campaignSteps: campaign.campaignSteps,
    },
    resolver: zodResolver(campaignSchema),
  });

  useEffect(() => {
    if (!open) form.reset();
  }, [open]);

  const handleSave = async (data: { campaignSteps: Array<CampaignStep> }) => {
    const campaignSteps = data.campaignSteps.map((step, index) => {
      return {
        ...step,
        stepNumber: index + 1, // step numbers start at 1 and increment from there
        startDate: new Date(),
      };
    });

    // Lock down the editor
    setCampaignConfigurationFormSubmitting(true);

    try {
      const response = await SafeLeaseAPI.reputation.updateCampaign(campaign.id, { campaignSteps });
      const updatedCampaignSteps = response.data.campaignSteps;
      form.reset({ campaignSteps: updatedCampaignSteps });
      enqueueSnackbar("Campaign configuration saved", { variant: "success" });
    } catch {
      enqueueSnackbar("Failed to save campaign configuration", { variant: "error" });
    } finally {
      // Unlock ther editor
      setCampaignConfigurationFormSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { p: 2 } }} maxWidth="md">
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" fontWeight="600">
            Configure {campaign.name} Campaign
          </Typography>
          <IconButton onClick={handleClose} disabled={statuses.campaignConfigurationForm}>
            <Close />
          </IconButton>
        </Stack>
        <SafeLeaseCard sx={{ boxShadow: "none", p: 2 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
            <ForwardToInboxOutlined sx={{ mr: 1, color: (theme: Theme) => theme.palette.blue.main, width: "28px", height: "auto" }} />
            <Typography variant="body1" fontWeight="600">
              {campaign.name}
            </Typography>
          </Stack>
          <Typography color="grey.A100" variant="body2">
            {campaign.description}
          </Typography>
        </SafeLeaseCard>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <CampaignConfigurationSteps campaignId={campaign.id} />
            {isAdmin && (
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <SafeLeaseButton variant="text" color="navy" onClick={handleClose} disabled={statuses.campaignConfigurationForm}>
                  Cancel
                </SafeLeaseButton>
                <SafeLeaseButton variant="contained" color="blue" type="submit" disabled={statuses.campaignConfigurationForm}>
                  Save
                </SafeLeaseButton>
              </Stack>
            )}
          </form>
        </FormProvider>
      </Stack>
    </Dialog>
  );
}
