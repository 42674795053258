import React, { useState, useEffect } from "react";
import { Typography, Button, styled, Snackbar, IconButton, Alert, FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { Box } from "@mui/system";
import { OrgRowBox, FlexRowBox } from "./OrganizationStyledComponents";
import { muiSelect } from "../../../styles/mui-overrides";
import { useOrganizationSettings } from "./useOrganizationSettingsStore";
import { useAuth } from "../../../auth";
import { useQuery } from "@tanstack/react-query";
import TuneIcon from "@mui/icons-material/Tune";
import FmsPaper from "./FmsPaper";
import OrganizationTree from "./OrganizationTree";
import Loader from "../../../shared/Loader";
import OrganizationDialog from "./OrganizationDialog";
import Error from "../../../shared/Error";
import OrgChartRow from "./OrgChartRow";
import FmsLoginModal from "../../../shared/fms-login-modal";
import LocationSettingsModal from "../../../shared/LocationSettingsModal";
import CloseIcon from "@mui/icons-material/Close";

const StyledFmsPaper = styled(FmsPaper)(({ theme }) => ({
  border: "1px solid #EBEFF7",
  borderRadius: "4px",
  backgroundColor: "#F5F7FB",
  padding: "4px 16px 4px 16px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  marginLeft: "5px",
  cursor: "pointer",
}));

const OrgRowBoxTitleText = styled(Typography)(({ theme }) => ({
  color: "#9F9F9F",
  fontSize: "16px",
}));

const ManageIntegrationsButton = styled(Button)(({ theme }) => ({
  color: "black",
  backgroundColor: "white",
  border: "1px solid black",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "white",
  },
}));

const FlexSpaceBetweenBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: "10px",
  marginBottom: "20px",
  minWidth: "120px",
}));

const Organization = () => {
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const relationshipId = auth.user.relationshipId;
  const [fmsLoginModalOpen, setFmsLoginModalOpen] = useState(false);
  const [locationSettingsModalOpen, setLocationSettingsModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: "error" | "warning" | "info" | "success";
  } | null>(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [relationshipSelectorId, setRelationshipSelectorId] = useState(relationshipId);
  const {
    loading,
    accounts,
    error,
    llcs,
    dialogOpen,
    fetchOrgSettings,
    fetchRelationshipsSelector,
    fetchOrgSettingsByRelationshipId,
    setDialogOpen,
  } = useOrganizationSettings();

  useEffect(() => {
    if (isAdmin) {
      // Load the organization settings for the selected relationship for admins
      fetchOrgSettingsByRelationshipId(relationshipSelectorId);
    }
  }, []);

  const fetchOrgSettingsResult = useQuery({
    queryKey: ["fetch-org-settings"],
    queryFn: () => fetchOrgSettings(),
    enabled: !isAdmin, // execute this query only if the user is not an admin
  });

  // Fetches all the relationships to populate the relationship dropdown
  const fetchRelationshipsSelectorResult = useQuery({
    queryKey: ["fetch-relationships-selector"],
    queryFn: () => fetchRelationshipsSelector(),
    enabled: isAdmin, // execute this query only if the user is an admin
  });

  if (fetchOrgSettingsResult.isFetching || fetchRelationshipsSelectorResult.isFetching) {
    return <Loader />;
  }

  if (fetchOrgSettingsResult.error || fetchRelationshipsSelectorResult.error || error) {
    return <Error />;
  }

  const handleCloseDialog = () => {
    // may as well close all
    setDialogOpen(false);
    setFmsLoginModalOpen(false);
  };

  const handleFmsPaperClick = (event: React.BaseSyntheticEvent) => {
    const { id: accountId } = event.currentTarget;
    setSelectedAccountId(+accountId);
    setFmsLoginModalOpen(true);
  };

  const getAllLocationsFromOrgSettings = () => {
    if (llcs.length) {
      const locations = llcs.map((llc) => llc.locations);
      return locations.flat();
    }

    return [];
  };

  const handleRelationshipSelectorChange = async (event: SelectChangeEvent) => {
    const { value: relationshipId } = event.target;
    await fetchOrgSettingsByRelationshipId(+relationshipId);
    setRelationshipSelectorId(relationshipId);
  };

  const selectedLocation = getAllLocationsFromOrgSettings().find((location) => location.id == selectedLocationId);

  const buildAccountList = () => {
    const accountList = [];
    for (const account of accounts) {
      if (!accountList.find((fms) => fms.integrationName === account.integrationName)) {
        accountList.push({
          integrationName: account.integrationName,
          id: account.id,
        });
      }
    }

    return accountList;
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loading && <Loader />}
      <OrganizationDialog open={dialogOpen} handleClose={handleCloseDialog} />
      {auth.user && isAdmin && fetchRelationshipsSelectorResult.data && (
        <StyledFormControl>
          <Select
            value={relationshipSelectorId}
            displayEmpty
            onChange={handleRelationshipSelectorChange}
            sx={{ ...muiSelect, marginLeft: "0px", padding: "0px" }}
          >
            {fetchRelationshipsSelectorResult.data?.map((relationship: { id: number; name: string }) => {
              return (
                <MenuItem key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </MenuItem>
              );
            })}
          </Select>
        </StyledFormControl>
      )}
      {!!alertMessage && (
        <Snackbar open anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert
            className="tw-mt-4 tw-mb-4"
            severity={alertMessage?.severity}
            action={
              <IconButton
                color="inherit"
                onClick={() => {
                  setAlertMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      )}
      {/* The following modal is porting over the FmsLoginModal from the old location settings. For reference view LocationSettings.tsx 
      TODO: Take out FmsLoginModal functionality & put it into OrganizationDialog to prevent the use of multiple modals. */}
      {fmsLoginModalOpen && (
        <FmsLoginModal
          relationshipId={relationshipSelectorId}
          account={accounts.find((account) => account.id === selectedAccountId)}
          onClose={(accountId) => {
            setFmsLoginModalOpen(false);
            setSelectedAccountId(accountId);
          }}
          setAlertMessage={setAlertMessage}
        />
      )}
      {/* The following modal is porting over the LocationSettingsModal from the old location settings. For reference view LocationSettings.tsx */}
      {/* TODO: Take out LocationsSettingsModal functionality & put it into OrganizationDialog to prevent the use of multiple modals. */}
      {locationSettingsModalOpen && selectedLocation && (
        <LocationSettingsModal
          location={selectedLocation}
          onClose={() => {
            setLocationSettingsModalOpen(false);
            setSelectedLocationId(null);
          }}
          isAdmin={isAdmin}
        />
      )}
      {!loading && (
        <>
          <OrgRowBoxTitleText>Integrations</OrgRowBoxTitleText>
          <OrgRowBox>
            <FlexSpaceBetweenBox>
              <FlexRowBox>
                {accounts.length > 0 &&
                  buildAccountList().map((account) => {
                    return (
                      // Render FmsPaper for each account (SiteLink, StorEDGE, etc.)
                      <div key={account.id}>
                        <StyledFmsPaper fms={account.integrationName} showLaptopIcon={true} onClick={handleFmsPaperClick} id={account.id} />
                      </div>
                    );
                  })}
                {/* Render N/A fms if no accounts are available */}
                {!accounts.length && <StyledFmsPaper fms={"N/A"} showLaptopIcon={true} />}
              </FlexRowBox>
              <ManageIntegrationsButton variant="contained" startIcon={<TuneIcon />} disableElevation={true} disableRipple={true}>
                {/* TODO: add onClick action to button */}
                Manage Integrations
              </ManageIntegrationsButton>
            </FlexSpaceBetweenBox>
          </OrgRowBox>
          <FlexSpaceBetweenBox>
            <OrgRowBoxTitleText mt={1}>Organization Chart</OrgRowBoxTitleText>
          </FlexSpaceBetweenBox>
          <OrgChartRow />
          <Box mt={1}>
            <OrganizationTree
              setLocationSettingsModalOpen={setLocationSettingsModalOpen}
              setSelectedLocationId={setSelectedLocationId}
              fetchOrgSettingsResult={fetchOrgSettingsResult}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Organization;
