import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import DatePicker from './components/lib/SafeLeaseDatePicker';
import Tooltip from '@mui/material/Tooltip';
import { useQuery, QueryResult } from '@apollo/client';
import { getLocations, getLocation, getReportDetails, getReportDetailsTotals } from './queries';
import { Formatters } from './utilities/formatters';
import FileSaver from 'file-saver';
import TitleHeader from './shared/title-header';
import { ZeroState } from './common';
import SortingTable from './shared/sorting-table';
import { muiRedButton, muiTooltip, ghostButtonNavy } from './styles/mui-overrides';
import { reportDetailsColumnDefs } from './utilities/column-defs';
import RouterHelper from './utilities/router-helper';
import { mixpanelEventHandler } from './utilities/reactMixpanelHandler';
import RelationshipDataService from "./services/relationship.service";
import { FormControl, Select, MenuItem } from '@mui/material';
import { muiSelect, muiMenuItem } from './styles/mui-overrides';
import TableWrapper from './shared/table-wrapper';
import { Image } from './image';
import { Location } from './utilities/generated/gql-types';
import Loader from './shared/Loader';
import Error from './shared/Error';
import React from 'react';
const { convertArrayToCSV } = require('convert-array-to-csv');
require('datejs');
import { useSnackbar } from "notistack";
import { DateValidationError } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';

function ReportDetails(props: { routerHelper: RouterHelper, match: any }) {
  const { routerHelper } = props;
  const { locationId, relationshipId } = useParams<{
    locationId: string;
    relationshipId: string;
  }>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const firstOfTheMonth = (() => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0)
    return date;
  })();

  const endOfTheMonth = new Date(firstOfTheMonth.getFullYear(), firstOfTheMonth.getMonth() + 1, 0);

  const [startDate, setStartDate] = useState<Date>(firstOfTheMonth);
  const [endDate, setEndDate] = useState<Date>(endOfTheMonth);
  const [pendingStartDate, setPendingStartDate] = useState<Date>(firstOfTheMonth);
  const [pendingEndDate, setPendingEndDate] = useState<Date>(endOfTheMonth);

  const [startError, setStartError] = React.useState<DateValidationError | null>(null);
  const [endError, setEndError] = React.useState<DateValidationError | null>(null);
  
  const startErrorMessage = React.useMemo(() => {
    switch (startError) {
      case 'maxDate': {
        return 'Start date must be before end date.';
      }
      case 'invalidDate': {
        return 'Your start date is not valid.';
      }
      default: {
        return '';
      }
    }
  }, [startError]);
  
  const endErrorMessage = React.useMemo(() => {
    switch (endError) {
      case 'minDate': {
        return 'End date must be after start date.';
      }
      case 'invalidDate': {
        return 'Your end date is not valid.';
      }
      default: {
        return '';
      }
    }
  }, [endError]);

  const locations: QueryResult<{getLocations: Location[]}> = 
      useQuery(getLocations, {variables: {relationshipId}});

  const location: QueryResult = useQuery(getLocation, { variables: { locationId }, skip: locationId == 'all' });
  
  const reportDetails: QueryResult = useQuery(getReportDetails, {
    variables: {
      relationshipId,
      locationId,
      startDate: startDate.toString('yyyy-MM-dd'),
      endDate: endDate.toString('yyyy-MM-dd'),
    },
    fetchPolicy: "no-cache",
  });
  
  const reportDetailsTotals: QueryResult = useQuery(getReportDetailsTotals, {
    variables: {
      relationshipId,
      locationId,
      startDate: startDate.toString('yyyy-MM-dd'),
      endDate: endDate.toString('yyyy-MM-dd'),
    },
    fetchPolicy: "no-cache",
  });

  
  useEffect(() => {
    document.body.classList.add('report-details');
    mixpanelEventHandler('View Report Details')
    return () => { document.body.classList.remove('report-details')}
  }, []);

  useEffect(() => {
    if (routerHelper.getRelationshipId() != relationshipId) {
      history.push('/reports')
    }
  }, [routerHelper.getRelationshipId()])
  
  const setDates = function () {

    if (startErrorMessage || endErrorMessage) {
      console.error("Cannot load report due to date errors.");
      enqueueSnackbar("Could not recognize dates", { variant: "error" });
      return;
    }

    mixpanelEventHandler('Report Details - Date Filter');
    setStartDate(pendingStartDate);
    setEndDate(pendingEndDate);
  }

  async function exportCsv(e) {
    e.preventDefault();
    mixpanelEventHandler('Report Details - Export CSV');
  
    const formattedReportDetails: {}[] = [];
    reportDetails.data.getReportDetails.map(row => {
      const newRow: {[index: string]: any} = {};
      Object.keys(row).map(key => {
        if (key === '__typename' || key === 'id') return;
        const newKey = Formatters.camelCaseToUpperCase(key);
        newRow[newKey] = row[key];
      })
      var output = {
        'Address': row.address,
        'Unit Number': row.name,
        'Tenant Name': row.tenantName,
        'Enrollment Date': row.startDate,
        'Cancel Date': row.cancelDate,
        'Coverage': Formatters.penniesToDollars(row.coverage,  {digits: 0}),
        'Fee': Formatters.penniesToDollars(row.premium),
        'Collected': Formatters.penniesToDollars(row.collectedSum),
        'Paid Through Date': row.paidThroughDate,
      };

      formattedReportDetails.push(output);
    })
    const csvString = convertArrayToCSV(formattedReportDetails);
    var date = Formatters.jsDateToGraphQlDate(new Date());
    var csvBlob = new Blob([csvString],
      { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(csvBlob, `Report Details (${date}).csv`);
    
    return false;
  }

  if (reportDetails.loading || reportDetailsTotals.loading || location.loading || locations.loading) return <Loader />;
  if (reportDetails.error || reportDetailsTotals.error || location.error || locations.error) return <Error />

  function TableToolTip(props: { title: string }) {
    const { title } = props;
    return (
      <Tooltip placement="bottom" title={title}>
        <Button sx={muiTooltip}>
          <span className="badge">
            <Image src="/images/tooltip-icon.svg" alt="warning" />
          </span>
        </Button>
      </Tooltip>
    )
  }

  function changeLocation(id) {
    history.push(`/report_details/${relationshipId}/${id}`);
  };
  
  return (
    <div>
      <div className="report-title-link">
      <Link to={() => routerHelper?.reportsRoute() || 'reports'} style={{ textDecoration: 'none' }}>
        <div className="return-link">Reports</div>
      </Link>
      <p className="report-title-text">/ Protected Tenants Report </p>
      </div>

      <TitleHeader title={(location.data && location.data.getLocation && location.data.getLocation.fullAddress) || "All Locations"} />

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
            value={locationId || ''}
            displayEmpty
            onChange={(e) => changeLocation(e.target.value)}
            sx={muiSelect}>
          {locations.data.getLocations.length > 1 &&
            <MenuItem value={'all'} sx={muiMenuItem}>
              All Locations
            </MenuItem>
          }
          {locations.data.getLocations.map(location => 
            <MenuItem sx={muiMenuItem} key={location.id} value={location.id}>
              {location.fullAddress}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      
      <div className="report-details--options">
        <div className="report-details--options--left">
          
          <DatePicker
            label="Start Date"
            onChange={(newStartDate: Date) => setPendingStartDate(newStartDate)}
            value={pendingStartDate}
            maxDate={pendingEndDate ? dayjs(pendingEndDate) : null}
            onError={(newStartDateError) => setStartError(newStartDateError)}
            views={['year', 'month', 'day']}
            slotProps={{
              textField: {
                size: "small",
                sx: { width: 150 },
                error: !!startErrorMessage, // Boolean indicating if there's an error
                helperText: startErrorMessage
              }
            }}
          />

          <div className="report-details--options--left--to">
            <div>To</div>
          </div>
          
          <DatePicker
            label="End Date"
            value={pendingEndDate}
            onChange={(newEndDate: Date) => setPendingEndDate(newEndDate)}
            minDate={pendingStartDate ? dayjs(pendingStartDate) : null}
            onError={(newEndDateError) => setEndError(newEndDateError)}
            views={['year', 'month', 'day']}
            slotProps={{
              textField: {
                size: "small",
                sx: { width: 150 },
                error: !!endErrorMessage, // Boolean indicating if there's an error
                helperText: endErrorMessage
              }
            }}
          />

          <Button sx={ {...ghostButtonNavy, height: 40}  } onClick={setDates}>Load Report</Button>
        </div>
        {reportDetails.data.getReportDetails.length > 0 &&
          <Button className="claim-details--right" 
              sx={muiRedButton({maxWidth: true})} 
              onClick={exportCsv}>
            Export CSV
          </Button>
        }
      </div>

      <div className="totals">
        <div className="title">Totals</div>
        <div className="body">
          <div className="total">
            <div className="number">{reportDetailsTotals.data.getReportDetailsTotals.count.toLocaleString()}</div>
            <div className="label">Protection Plan Units
              <TableToolTip title={'The total number of units with an active policy for at least one day during the selected time period.'} />
            </div>
          </div>
          
          <div className="total">
            <div className="number">{Formatters.penniesToDollars(reportDetailsTotals.data.getReportDetailsTotals.coverage, {digits: 0})}</div>
            <div className="label">Coverage Amount
              <TableToolTip title={'The total coverage amount for units with an active policy for at least one day during the selected time period.'} />
            </div>
          </div>

          <div className="total">
            <div className="number">{Formatters.penniesToDollars(reportDetailsTotals.data.getReportDetailsTotals.premium)}</div>
            <div className="label">Total Premium Amount
              <TableToolTip title={'The total premium amount for units that had an active policy for one or more days during the selected time period.'} />
            </div>
          </div>

          <div className="total">
            <div className="number">{Formatters.penniesToDollars(reportDetailsTotals.data.getReportDetailsTotals.collected)}</div>
            <div className="label">Amount Collected
              <TableToolTip title={'The total dollar amount collected for units with an active policy for one or more days during the selected time period.'} />
            </div>
          </div>

        </div>
      </div>
      {location.data && !location.data.getLocation.unsupported && reportDetails.data.getReportDetails && reportDetails.data.getReportDetails.length == 0 &&
        <ZeroState title="No insured units found." 
            body="Try adjusting your filter settings for better results." 
            svg="/images/no-reports-zero-state.svg"/>
      }
      {location.data && !!location.data.getLocation.unsupported && 
        <ZeroState title="No data found" 
            body="This location currently uses a non-supported FMS." 
            svg="/images/no-data-zero-state.svg"/>
      }

      {reportDetails?.data?.getReportDetails?.length > 0 &&
        <>
          <TableWrapper columns={reportDetailsColumnDefs} title="Report Details">
            <SortingTable columns={reportDetailsColumnDefs}
              title="Report Details"
              queryResultData={reportDetails?.data?.getReportDetails}
              />
          </TableWrapper>
        </>
      }

    </div>
  )
}

export { ReportDetails }
