import dayjs from "dayjs";
import { Skeleton, alpha, useTheme } from "@mui/material";
import { PeriodDataPoints } from "../RatingBreakdownGraphs";
import * as HighCharts from "highcharts";
import HighChartsUtils from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import _ from "lodash";
import { isFloat } from "validator";
import { sharedToolTip, xAxisConfiguration } from "./graphUtils";

interface CumulativeReviewsGraphProps {
  cumulativeSums: Array<number>;
  periods: Array<PeriodDataPoints>;
  loading?: boolean;
}

export function CumulativeReviewsGraph({ cumulativeSums, periods, loading = false }: CumulativeReviewsGraphProps) {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const theme = useTheme();

  // In dev mode, warn if the length of the categories and data arrays are not the same
  if (process.env.NODE_ENV !== "production" && periods?.length) {
    console.warn("LineChart: The length of the categories and data arrays must be the same");
  }

  if (loading) return <Skeleton variant="rectangular" height={252} />;

  const xMin = Math.min(...cumulativeSums);

  const chartConfig: Highcharts.Options = {
    // Remove the chart title
    title: {
      text: "",
    },

    // Remove the legend since it's only one dataset
    legend: {
      enabled: false,
    },

    // Disable "highcharts.com" in the corner
    credits: {
      enabled: false,
    },
    xAxis: {...xAxisConfiguration(periods), min: 0},
    yAxis: {
      min: 0,
      max: Math.max(...cumulativeSums),
      endOnTick: false,
      startOnTick: false,
      labels: {
        style: {
          color: theme.palette.grey.A100,
        },
      },

      // Remove the y axis label from the graph
      title: {
        text: null,
      },
    },
    tooltip: sharedToolTip(periods),
    plotOptions: {
      area: {
        // Set line color to transparent
        lineWidth: 2,
        // Set marker styling (optional)
        marker: {
          fillColor: "031E30",
          lineWidth: 2,
        },
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, alpha(theme.palette.yellow.main, 0.4)], // Start with theme yellow
            [1, alpha(theme.palette.yellow.main, 0)], // End with transparent
          ],
        },
      },
    },
    series: [
      {
        name: "Reviews",
        data: cumulativeSums,
        type: "area",
        color: theme.palette.yellow.main,
        marker: {
          fillColor: "031E30",
          lineWidth: 2,
        },
      },
    ],
  };

  return (
    <HighchartsReact
      id={_.uniqueId("area-chart-")}
      containerProps={{
        id: _.uniqueId("area-chart-"),
        style: {
          height: "100%",
        },
      }}
      highcharts={HighCharts}
      ref={chartComponentRef}
      options={chartConfig}
    />
  );
}
