import * as d3 from "d3"
import { StatusTriage } from '../utilities/field-enums'
import { claimsVizStore } from './components/claims-visualizations';

// Copyright 2022 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/spike-map
function SpikeMap(svg, data, {
  position = d => d, // given d in data, returns the [longitude, latitude]
  paidValue = () => undefined, // given d in data, returns the quantitative value
  riskValue = () => undefined, // given d in data, returns the quantitative value
  title, // given a datum d, returns the hover text
  locationUrl, // given a datum d, returns the url to navigate to
  relationshipId, // given a datum d, returns the relationshipId
  llcId,
  scale = d3.scaleLinear, // type of length scale
  domain, // [0, max] values; input of length scale; must start at zero
  maxLength = 200, // maximum length of spikes
  width = 640, // outer width, in pixels
  height, // outer height, in pixels
  projection, // a D3 projection; null for pre-projected geometry
  features, // a GeoJSON feature collection for the background
  borders, // a GeoJSON object for stroking borders
  spike = (length, width = 7) => `M${-width / 2},0L0,${-length}L${width / 2},0`,
  outline = projection && projection.rotate ? {type: "Sphere"} : null, // a GeoJSON object for the background
  backgroundFill = "#e0e0e0", // fill color for background
  backgroundStroke = "white", // stroke color for borders
  backgroundStrokeWidth, // stroke width for borders
  backgroundStrokeOpacity, // stroke width for borders
  backgroundStrokeLinecap = "round", // stroke line cap for borders
  backgroundStrokeLinejoin = "round", // stroke line join for borders
  fill = "#2779FB", // fill color for spikes
  fillOpacity = 0.3, // fill opacity for spikes
  stroke = "#2779FB", // stroke color for spikes
  strokeWidth, // stroke width for spikes
  strokeOpacity, // stroke opacity for spikes
  legendX = width - 20,
  legendY = height - 20,
} = {}) {
  // Compute values.
  const I = d3.map(data, (_, i) => i);
  const V = d3.map(data, paidValue).map(d => d == null ? 0 : +d);
  const K = d3.map(data, riskValue).map(d => d == null ? 0 : +d);
  const P = d3.map(data, position);
  const T = title == null ? null : d3.map(data, title);
  const U = d3.map(data, locationUrl);
  const R = d3.map(data, relationshipId);
  const L = d3.map(data, llcId);
  console.log({paidValue, riskValue});
  console.log({V, K, I, P, T, U, R, L});

  const allStatusesSelected = claimsVizStore.getState().statuses.length >= 2

  // Compute default domains.
  if (domain === undefined) domain = [0, d3.max(V)];

  // Construct scales.
  let length = scale(
    [
      0, 
      allStatusesSelected ? 
          claimsVizStore.getState().claimsVizMaxValue * 2 :
          claimsVizStore.getState().claimsVizMaxValue
    ],
    [
      0,
      maxLength 
    ]);

  // Compute the default height. If an outline object is specified, scale the projection to fit
  // the width, and then compute the corresponding height.
  if (height === undefined) {
    if (outline === undefined) {
      height = 400;
    } else {
      const [[x0, y0], [x1, y1]] = d3.geoPath(projection?.fitWidth(width, outline)).bounds(outline);
      const dy = Math.ceil(y1 - y0), l = Math.min(Math.ceil(x1 - x0), dy);
      projection?.scale(projection.scale() * (l - 1) / l).precision(0.2);
      height = dy;
    }
  }

  // Construct a path generator.
  const path = d3.geoPath(projection);

  var div = d3.select("body").append("div")
     .attr("class", "d3-tooltip")
     .style("opacity", 0);

  /*
  const svg = d3.create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "width: 100%; height: auto; height: intrinsic;");
  */

  if (outline != null) svg.append("path")
      .attr("fill", "white")
      .attr("stroke", "currentColor")
      .attr("d", path(outline));

  svg.append("path")
      .datum(features)
      .attr("fill", backgroundFill)
      .attr("d", path);

  if (borders != null) svg.append("path")
      .attr("pointer-events", "none")
      .attr("fill", "none")
      .attr("stroke", backgroundStroke)
      .attr("stroke-linecap", backgroundStrokeLinecap)
      .attr("stroke-linejoin", backgroundStrokeLinejoin)
      .attr("stroke-width", backgroundStrokeWidth)
      .attr("stroke-opacity", backgroundStrokeOpacity)
      .attr("d", path(borders));

  const legend = svg.append("g")
      .attr("fill", "#777")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
    .selectAll("g")
      .data(length.ticks(4).slice(1).reverse())
    .join("g")
      .attr("transform", (d, i) => `translate(${legendX - i * 18},${legendY})`);

  legend.append("path")
      .attr("fill", "#2779FB")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "#2779FB")
      .attr("d", d => spike(length(d)));
      //.attr("d", d => spike(claimsVizStore.getState().claimsVizMaxValue));

  legend.append("text")
      .attr("dy", "1.3em")
      .text(length.tickFormat(4, "s"));

  const spikeSort = (i, j) => {
    if (claimsVizStore.getState().llcId == L[i]) {
      return 1;
    } else if (claimsVizStore.getState().relationshipId == R[i] &&
        claimsVizStore.getState().llcId != L[j]) {
      return 1;
    }
    return -1;
  }

  if (claimsVizStore.getState().statuses.includes(StatusTriage.Paid)) {
    svg.append("g")
        .attr("fill-opacity", fillOpacity)
        .attr("cursor", "pointer")
      .selectAll("path")
      .data(d3.range(data.length)
          .filter(i => P[i][0] != 'lat' && P[i][1] != 'lng')
          .sort((i, j) => d3.ascending(P[i][1], P[j][1]) || d3.ascending(P[i][0], P[j][0]))
          .sort(spikeSort))
      .join("path")
        .attr("transform", !projection 
            ? i => `translate(${P[i]})`
            : i => `translate(${projection(P[i])})`)
        .attr("d", i => spike(length(V[i])))
        .attr("fill", i => L[i] == claimsVizStore.getState().llcId ?
            'orange' :
            (R[i] == claimsVizStore.getState().relationshipId ? 'limegreen' : fill))
        .attr("stroke", i => L[i] == claimsVizStore.getState().llcId ?
            'orange' :
            (R[i] == claimsVizStore.getState().relationshipId ? 'limegreen' : stroke))
        .attr("stroke-width", strokeWidth)
        .attr("stroke-opacity", strokeOpacity)
        //.call(T ? path => path.append("title").text(i => T[i]) : () => {})

        .on('mouseover', function (e, d, i) {
          //let pos = d3.mouse(this);
          div.transition()
             .duration(50)
             .style("opacity", 1);
          div.html(T[d])
               .style("left", (e.pageX + 10) + "px")
               .style("top", (e.pageY - 15) + "px")
          d3.select(this).style("fill-opacity", "0.8");
        })
       .on('mouseout', function (e, d, i) {
          d3.select(this).transition()
               .duration('50')
               .attr('opacity', '1');
          //Makes the new div disappear:
          div.transition()
               .duration('50')
               .style("opacity", 0);
          d3.select(this).style("fill-opacity", fillOpacity);
       })
        .on('click', function (e, d, i) {
          window.open(U[d], '_blank');
        });
  }


  console.log('allStatusesSelected', allStatusesSelected)
  if (claimsVizStore.getState().statuses.includes(StatusTriage.InProgress)) {
    svg.append("g")
        .attr("fill-opacity", fillOpacity)
        .attr("cursor", "pointer")
      .selectAll("path")
      .data(d3.range(data.length)
          //TODO not sure this is necessary
          .filter(i => P[i][0] != 'lat' && P[i][1] != 'lng')
          .sort((i, j) => d3.ascending(P[i][1], P[j][1]) || d3.ascending(P[i][0], P[j][0]))
          .sort(spikeSort))
      .join("path")
        .attr("transform", !projection 
            //? i => `translate(${P[i]})`
            ? i => `translate(${P[i][0]},${P[i][1] - (allStatusesSelected ? length(V[i]) : 0)})`
            : i => `translate(${projection([P[i][0], P[i][1] - length(V[i])])})`)
        .attr("d", i => spike(length(K[i]), allStatusesSelected ? 0.5 : 7))
        .attr("stroke", i => allStatusesSelected ? 
            'red' : 
            (L[i] == claimsVizStore.getState().llcId ?
                'orange' :
                (R[i] == claimsVizStore.getState().relationshipId ? 'limegreen' : 'red')))
        .attr("fill", i => L[i] == claimsVizStore.getState().llcId ?
            'orange' :
            (R[i] == claimsVizStore.getState().relationshipId ? 'limegreen' : 'red'))

      /*
        .attr("fill", i => L[i] == claimsVizStore.getState().llcId ?
            'limegreen' :
            (R[i] == claimsVizStore.getState().relationshipId ? 'red' : 'orange'))
        .attr("stroke", i => L[i] == claimsVizStore.getState().llcId ?
            'limegreen' :
            (R[i] == claimsVizStore.getState().relationshipId ? 'red' : 'orange'))
      */
        .attr("stroke-width", allStatusesSelected ? 2 : 1)
        .attr("stroke-opacity", strokeOpacity)
        .on('mouseover', function (e, d, i) {
          //let pos = d3.mouse(this);
          div.transition()
             .duration(50)
             .style("opacity", 1);
          div.html(T[d])
               .style("left", (e.pageX + 10) + "px")
               .style("top", (e.pageY - 15) + "px")
          d3.select(this).style("fill-opacity", "0.8").style("stroke-width", allStatusesSelected ? "4" : 1);
        })
       .on('mouseout', function (e, d, i) {
          d3.select(this).transition()
               .duration('50')
               .attr('opacity', '1');
          //Makes the new div disappear:
          div.transition()
               .duration('50')
               .style("opacity", 0);
          d3.select(this).style("fill-opacity", fillOpacity).style("stroke-width", allStatusesSelected ? 2 : 1);
       })
        .on('click', function (e, d, i) {
          window.open(U[d], '_blank');
        });
  }
  /*
  svg.call(d3.zoom()
      //.extent([[0, 0], [1000, 1000]])
      .scaleExtent([1, 8])
      .on("zoom", zoomed));

  function zoomed({transform}) {
    svg.attr("transform", transform);
  }*/

  return svg.node();
}

export default SpikeMap;
