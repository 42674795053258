import { create } from "zustand";
import { ClaimType } from "./SelectClaimType";

type ClaimCreationStore = {
  claimId: string,
  activeStep: number | null,
  creationStep: number | null,
  protectionPlanAddendumFile: File,
  billingLedgerFile: File,
  claimType: ClaimType | null,
  setActiveStep: (activeStep: number) => void,
  setCreationStep: (creationStep: number) => void,
  setClaimId: (claimId: string) => void,
  setProtectionPlanAddendumFile: (protectionPlanAddendumFile: File) => void,
  setBillingLedgerFile: (billingLedgerFile: File) => void,
  setClaimType: (claimType: ClaimType) => void,
  cleanUp: () => void,
};

export const useClaimCreationStore = create<ClaimCreationStore>()((set, get) => ({
  claimId: null,
  activeStep: null,
  creationStep: null,
  protectionPlanAddendumFile: null,
  billingLedgerFile: null,
  claimType: null,
  setActiveStep: (activeStep: number) => set({ activeStep }),
  setCreationStep: (creationStep: number) => {
    const activeStep = getActiveStep(creationStep);
    set({ creationStep });
    set({ activeStep });
  },
  setClaimId: (claimId: string) => set({ claimId }),
  setProtectionPlanAddendumFile: (protectionPlanAddendumFile: File) => set({ protectionPlanAddendumFile }),
  setBillingLedgerFile: (billingLedgerFile: File) => set({ billingLedgerFile }),
  setClaimType: (claimType: ClaimType) => set({ claimType }),
  cleanUp: () => set({ claimId: null, activeStep: null, creationStep: null, protectionPlanAddendumFile: null, billingLedgerFile: null, claimType: null }),
}));

const getActiveStep = (creationStep: number) => {
  if (creationStep === 0) return 0;
  if ([1, 2, 3, 4].includes(creationStep)) return 1;
};
