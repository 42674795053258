import { InfoOutlined, KeyboardArrowRight, Warning } from "@mui/icons-material";
import { Box, Rating, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { SafeLeaseButton, SafeLeaseChip, SafeLeaseLetterAvatar } from "@safelease/components";
import { Review } from "@safelease/service-utilities";
import dayjs from "dayjs";
import _ from "lodash";

export const reviewsGridColumns: GridColDef<Review>[] = [
  {
    field: "googleReviewUser",
    headerName: "Reviewer",
    sortable: false,
    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography>Reviewer</Typography>
        <Tooltip title="Name of the person who left the review">
          <InfoOutlined
            sx={{
              height: 18,
              color: (theme: Theme) => theme.palette.grey.A100,
            }}
          />
        </Tooltip>
      </Stack>
    ),
    flex: 1,
    renderCell: ({ value }) => {
      // If for some reason the google user couldn't be associated with this review, throw an error
      if (!value)
        return (
          <SafeLeaseChip
            color="red"
            size="small"
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Warning />
                <Typography>Failed to load</Typography>
              </Stack>
            }
          />
        );

      return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ m: 0.5 }}>
          <SafeLeaseLetterAvatar src={value.thumbnailUrl} name={value.name} sx={{ height: 32, width: 32, fontSize: 14 }} />
          <Box>
            <Typography variant="body2" fontWeight="semibold">
              {value.name}
            </Typography>
            <Typography variant="caption" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
              {value.unit}
            </Typography>
          </Box>
        </Stack>
      );
    },
  },
  {
    field: "rating",
    headerName: "Rating",
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => <Rating value={value} readOnly />,
  },
  {
    field: "platform",
    headerName: "Review Site",
    sortable: false,
    flex: 1,
    valueGetter: () => "Google",
  },
  // {
  //   field: "sentiment",
  //   headerName: "Sentiment",
  //   flex: 1,
  //   renderHeader: () => (
  //     <Stack alignItems="center" spacing={1} direction="row">
  //       <Typography>Sentiment</Typography>
  //       <Tooltip title="Our analysis of the tone of this review">
  //         <InfoOutlined
  //           sx={{
  //             height: 18,
  //             color: (theme: Theme) => theme.palette.grey.A100,
  //           }}
  //         />
  //       </Tooltip>
  //     </Stack>
  //   ),
  //   renderCell: ({ value }) => {
  //     const sentimentColors = {
  //       positive: "green",
  //       neutral: "blueGrey",
  //       negative: "red",
  //       mixed: "yellow",
  //     };

  //     return (
  //       <SafeLeaseChip
  //         color={sentimentColors[value]}
  //         label={_.capitalize(value)}
  //       />
  //     );
  //   },
  // },
  {
    field: "actualDate",
    headerName: "Review Date",
    sortable: false,

    flex: 1,
    valueGetter: ({ row }) => (row.actualDate ? dayjs(row.actualDate).format("MMM D, YYYY") : row.date),
  },
  {
    field: "campaign",
    headerName: "Campaign",
    sortable: false,
    flex: 1,
    valueGetter: ({ value }) => (value ? value.name : "Organic"),
    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography>Campaign</Typography>
        <Tooltip
          title='If a review was able to be associated with a campaign, the channel will be the campaign type.
        "Organic" means that we could not associate this review with a campaign.'
        >
          <InfoOutlined
            sx={{
              height: 18,
              color: (theme: Theme) => theme.palette.grey.A100,
            }}
          />
        </Tooltip>
      </Stack>
    ),
  },
  {
    field: "responseSnippet",
    headerName: "",
    sortable: false,
    groupable: false,
    hideable: false,
    align: "right",
    flex: 1,
    valueGetter: ({ row }) => ({ requiresResponse: !Boolean(row.responseSnippet), link: row.link }),
    renderCell: ({ value }) => (
      <SafeLeaseButton
        variant={value.requiresResponse ? "contained" : "text"}
        color="navy"
        endIcon={<KeyboardArrowRight sx={{ height: 16 }} />}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          window.open(value.link, "_blank");
        }}
      >
        {value.requiresResponse ? "Reply to review" : "See review"}
      </SafeLeaseButton>
    ),
  },
];
