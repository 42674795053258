import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@mui/material';
import { stateAbbr } from '../../utilities/field-enums';
import { ProtectionLevels } from '../../common';
import SetupDataService from '../../services/setup.service';

interface LocationSettingsFacilityDetailsProps {
  location: any; // # todo track down type
  isAdmin: boolean;
  loading: boolean;
  setLoading: (val: boolean) => void;
  setErrorMessage: (message: string) => void;
  onClose: () => void;
}

export default function FacilityDetails({
  location,
  isAdmin,
  loading,
  setLoading,
  setErrorMessage,
  onClose
}: LocationSettingsFacilityDetailsProps) {

  const [errors, setErrors] = useState<{
    name?: { msg?: string };
    address?: { msg?: string };
    city?: { msg?: string };
    state?: { msg?: string };
    postalCode?: { msg?: string };
    revenueSharePercent?: { msg?: string };
  }>({});

  const [name, setName] = useState<string>(location.name ?? "");
  const [address, setAddress] = useState<string>(location.address ?? "");
  const [city, setCity] = useState<string>(location.city ?? "");
  const [state, setState] = useState<string>(location.state ?? "");
  const [postalCode, setPostalCode] = useState<string>(location.postalCode ?? "");
  const [revenueSharePercent, setRevenueSharePercent] = useState<string>(location.revenueSharePercent ?? "");
  const [insightsEnabled, setInsightsEnabled] = useState<boolean>(location.insightsEnabled);
  const [insuranceEnabled, setInsuranceEnabled] = useState<boolean>(location.insuranceEnabled);
  const [customProtectionLevels, setCustomProtectionLevels] = useState<boolean>(
    location.protectionLevelValues
  );
  const [protectionLevels, setProtectionLevels] = useState(location.protectionLevelValues);

  const saveLocationDetails = async function () {
    const locationClone = {
      ...location,
      name,
      address,
      city,
      state,
      postalCode,
      insightsEnabled,
      insuranceEnabled,
      protectionLevelValues: customProtectionLevels ? protectionLevels : null,
      revenueSharePercent: revenueSharePercent === "" ? null : revenueSharePercent,
    };

    setLoading(true);
    setErrors({});
    const response = await SetupDataService.addLocation(locationClone);
    setLoading(false);

    if (response.data.errors) {
      setErrors(response.data.errors);
      setErrorMessage('A problem occurred while saving the location.');
      return;
    }

    onClose();
  };

  return (
    <>
      <Paper
        sx={{
          maxHeight: 400,
          overflow: 'auto',
          backgroundColor: 'inherit',
          boxShadow: 'none',
          padding: 1,
        }}
      >
        <TextField
          name="name"
          label={'Name'}
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ marginBottom: 1, width: '100%' }}
          helperText={errors.name?.msg}
        />
        <TextField
          className="tw-w-full"
          name="address"
          label={'Address'}
          type="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          sx={{ marginBottom: 1, width: '100%' }}
          helperText={errors.address?.msg}
        />
        <Grid className="split-input-fields" container spacing={2}>
          <Grid item xs={4} className="input-grid">
            <TextField
              className="tw-w-full"
              name="city"
              label={'City'}
              type="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              sx={{ marginBottom: 1, width: '100%' }}
              helperText={errors.city?.msg}
            />
          </Grid>
          <Grid item xs={4} className="input-grid">
            <FormControl fullWidth>
              <InputLabel id="state-selector-label">State</InputLabel>
              <Select
                autoWidth
                id="state-selector"
                labelId="state-selector-label"
                label="State"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                {stateAbbr.map((state) => (
                  <MenuItem value={state} key={state}>{state}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.state?.msg}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4} className="input-grid">
            <TextField
              name="postalCode"
              label={'Postal Code'}
              type="postalCode"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              helperText={errors.postalCode?.msg}
            />
          </Grid>
        </Grid>

        {isAdmin && (
          <div className="tw-flex tw-flex-col">
            <FormControlLabel
              sx={{ marginRight: '-5px' }}
              control={
                <Checkbox
                  checked={insightsEnabled}
                  onChange={(e) => setInsightsEnabled(e.target.checked)}
                />
              }
              label="Insights Enabled"
            />
            <FormControlLabel
              sx={{ marginRight: '-5px' }}
              control={
                <Checkbox
                  checked={insuranceEnabled}
                  onChange={(e) => setInsuranceEnabled(e.target.checked)}
                />
              }
              label="Insurance Enabled"
            />

            <div className="tw-flex tw-flex-row">
              <ProtectionLevels
                protectionLevels={protectionLevels}
                setProtectionLevels={setProtectionLevels}
              />
            </div>

            <TextField
              name="revenueSharePercent"
              label="Location-Specific Revenue Share Percentage (SafeLease Share)"
              type="number"
              step="1"
              sx={{ marginTop: 1, width: '100%' }}
              value={revenueSharePercent}
              onChange={(e) => setRevenueSharePercent(e.target.value)}
              helperText={errors.revenueSharePercent?.msg}
            />
          </div>

        )}
      </Paper>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button className="modal-login-button" onClick={saveLocationDetails}>
          Save Details
        </Button>
      )}
    </>
  )
}
