import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, SelectChangeEvent, IconButton } from "@mui/material";
import { useOrganizationSettings } from "./useOrganizationSettingsStore";
import { buildMenuItems, findUserById } from "./common";
import { useAuth } from "../../../auth";
import { CustomSelectContent, CustomSelect } from "./CustomSelect";
import {
  FlexColumnBox,
  FlexRowBox,
  FlexStatusActionBox,
  OrgRowBox,
  StyledIconButton,
  StyledMoreVertIcon,
} from "./OrganizationStyledComponents";
import { UseQueryResult } from "@tanstack/react-query";
import { RelationshipLocation } from "../../../types/OrganizationSettingsTypes";
import PinDropIcon from "@mui/icons-material/PinDropOutlined";
import FmsPaper from "./FmsPaper";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
// TODO: replace with more accurate icon or the image from figma file
import TimelineIcon from "@mui/icons-material/Timeline";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUserOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import FiberIcon from "@mui/icons-material/FiberManualRecord";
import styled from "@emotion/styled";

const StyledPinDropIcon = styled(PinDropIcon)(({ theme }) => ({
  fontSize: "24px",
  backgroundColor: "transparent",
  borderRadius: "50%",
  marginRight: "16px",
}));

const LocationAddressText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Open Sans",
}));

const StyledFmsPaper = styled(FmsPaper)(({ theme }) => ({
  border: "1px solid #EBEFF7",
  borderRadius: "4px",
  backgroundColor: "#F5F7FB",
  padding: "4px 8px",
  height: "28px",
  display: "inline-flex", // Use inline-flex to make it resize based on content
  justifyContent: "center",
  alignItems: "center",
  width: "fit-content", // Use fit-content to make it resize based on content
  minWidth: "99px",
  marginTop: "4px",
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: "24px",
  border: "0.5px solid #EBEFF7",
  marginRight: "10px",
  marginLeft: "10px",
}));

const EnrolledProductsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginRight: "16px",
  color: "black",
  width: "122px",
}));

const EnrolledProductsText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontColor: "#929292",
  fontWeight: 500,
  wrap: "nowrap",
}));

const EnrolledProductsIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: "12px",
  color: "#929292",
  marginLeft: "3px",
}));

interface LocationRowProps {
  location: RelationshipLocation;
  fms: string;
  fetchOrgSettingsResult: UseQueryResult;
  setLocationSettingsModalOpen: (open: boolean) => void;
  setSelectedLocationId: (locationId: number) => void;
}

const LocationRow: React.FC<LocationRowProps> = ({
  location,
  fms,
  fetchOrgSettingsResult,
  setLocationSettingsModalOpen,
  setSelectedLocationId,
}) => {
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const { relationshipUsers: users, loading, fetchOrgSettingsByRelationshipId, updatePointOfContact } = useOrganizationSettings();
  const [pointOfContact, setPointOfContact] = useState<any>("");
  const pointOfContactMenuItems = buildMenuItems(users);
  const usersWithAccessMenuItems = buildMenuItems(users);

  // Load point of contact for location if point of contact exists or changes
  useEffect(() => {
    if (location.pointOfContact) {
      setPointOfContact(location.pointOfContact.id);
    } else {
      setPointOfContact("");
    }
  }, [location.pointOfContact]);

  const handleUsersWithAccessChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
  };

  const handlePointOfContactChange = async (event: SelectChangeEvent) => {
    if (!loading) {
      const { value: userId } = event.target;
      setPointOfContact(userId);
      if (userId) {
        await updatePointOfContact(+userId, location.id, "location");

        // if admin, fetch org settings by relationship id. This is necessary if an admin is changing point of contact for a location that is not part of their relationship
        if (isAdmin) {
          await fetchOrgSettingsByRelationshipId(location.relationshipId);
        } else {
          // fetch org settings for user's relationship (non-admin)
          await fetchOrgSettingsResult.refetch();
        }
      }
    }
  };

  // toggles the location settings modal ported over from the old location settings page. Reference locations.tsx
  const handleStyledIconClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { id: locationId } = event.currentTarget;
    setSelectedLocationId(+locationId);
    setLocationSettingsModalOpen(true);
  };

  return (
    <OrgRowBox>
      <StyledPinDropIcon />
      <FlexColumnBox sx={{ marginRight: "16px", minWidth: "200px" }}>
        <LocationAddressText>{location.address}</LocationAddressText>
        <StyledFmsPaper fms={fms} showInfoIcon={true} />
      </FlexColumnBox>
      <StyledDivider orientation="vertical" flexItem />
      {/* Point of contact select */}
      <CustomSelect
        value={pointOfContact}
        onChange={handlePointOfContactChange}
        menuItems={pointOfContactMenuItems}
        sx={{
          height: "40px",
          width: "194px",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: pointOfContact ? "inherit" : "#E9645F",
            backgroundColor: pointOfContact ? "inherit" : "rgba(233, 100, 95, 0.1)",
          },
        }}
        displayEmpty
        renderValue={(userId: number) => {
          if (userId) {
            return (
              <CustomSelectContent
                value={findUserById(users, userId).name}
                valueColor="#031E30"
                marginLeft="0px"
                imageRefs={["test"]}
                infoText={"Point of contact"}
              />
            );
            // No selection
          } else {
            return <CustomSelectContent value={"No selection"} valueColor="#E9645F" marginLeft="0px" infoText={"Point of contact"} />;
          }
        }}
      />
      <StyledDivider orientation="vertical" flexItem />
      <FlexRowBox>
        <CustomSelect
          value={users.length ? buildMenuItems(users)[0].value.toString() : ""}
          onChange={handleUsersWithAccessChange}
          menuItems={usersWithAccessMenuItems}
          sx={{
            height: "40px",
            width: "260px",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
              backgroundColor: "inherit",
            },
          }}
          renderValue={(value) => {
            return (
              <CustomSelectContent
                contentWrapperStyling={{
                  marginLeft: "-10px",
                }}
                value={`${users.length} selected`}
                valueColor="#031E30"
                marginLeft="0px"
                imageRefs={["test", "test", "test"]}
                infoText={"Users with access"}
              />
            );
          }}
        />
      </FlexRowBox>
      <StyledDivider orientation="vertical" flexItem />
      {/* Enrolled Products */}
      <EnrolledProductsBox>
        <Box>
          <AdminPanelSettingsIcon sx={{ fontSize: "16px" }} />
          <StarOutlineIcon sx={{ fontSize: "16px" }} />
          <TimelineIcon sx={{ fontSize: "16px" }} />
          <HomeRepairServiceIcon sx={{ fontSize: "16px" }} />
          <VerifiedUserIcon sx={{ fontSize: "16px" }} />
        </Box>
        <FlexRowBox>
          <EnrolledProductsText>Enrolled products</EnrolledProductsText>
          <EnrolledProductsIcon />
        </FlexRowBox>
      </EnrolledProductsBox>
      <FlexStatusActionBox>
        <FiberIcon
          sx={{
            color: "#E9645F",
            fontSize: "10px",
          }}
        />
        <StyledIconButton onClick={handleStyledIconClick} disableRipple id={location.id.toString()}>
          <StyledMoreVertIcon />
        </StyledIconButton>
      </FlexStatusActionBox>
    </OrgRowBox>
  );
};

export default LocationRow;
