import React, { useState } from "react";
import ClaimsDataService from "../../../services/claims.service";
import AttachmentDataService from "../../../services/attachments.service";
import { useSnackbar } from "notistack";
import { useClaimCreationStore } from "./useClaimCreationStore";
import SingleFileUploadFormComponent from "./SingleFileUploadFormComponent";
import { ClaimStatus } from "../../../utilities/ClaimsEnums";

const ProtectionPlanAddendumUpload = () => {
  const { enqueueSnackbar } = useSnackbar();
  const claimId = useClaimCreationStore((state) => state.claimId);
  const creationStep = useClaimCreationStore((state) => state.creationStep);
  const setCreationStep = useClaimCreationStore((state) => state.setCreationStep);
  const protectionPlanAddendumFile = useClaimCreationStore((state) => state.protectionPlanAddendumFile);
  const setProtectionPlanAddendumFile = useClaimCreationStore((state) => state.setProtectionPlanAddendumFile);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    setProtectionPlanAddendumFile(file);
  };

  const continueToNextStep = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading(true);
    const filename = await AttachmentDataService.directUpload(protectionPlanAddendumFile);

    const reqObject = {
      attachment_filename: filename,
      attachment_desired_filename: protectionPlanAddendumFile.name,
      attachment_mime_type: protectionPlanAddendumFile.type,
      creationStep: creationStep + 1,
      databaseColumnName: 'protectionPlanAddendumAttachmentId',
      claimStatus: ClaimStatus.Incomplete,
      claimId: claimId,
    };

    try {
      const response = await ClaimsDataService.claimAttachmentUpload(reqObject);
      setCreationStep(creationStep + 1);
    } catch (err) {
      enqueueSnackbar('Error uploading protection plan addendum, please try again.', { variant: "error" });
      setLoading(false);// only need to set back to false if there was an error because the component unmounts after a successful upload
    }
  };

  return (
    <SingleFileUploadFormComponent
      handleFileInputChange={handleFileInputChange}
      onContinue={continueToNextStep}
      file={protectionPlanAddendumFile}
      title="Protection Plan Addendum upload"
      subTitle="Please upload your Protection Plan Addendum..."
      helperTexts={[
        {
          label: "What is it?",
          content: "Document, etc"
        },
        {
          label: "How do i find it?",
          content: "Refer to your facility to get documentation."
        }
      ]}
    />
  );
};

export default ProtectionPlanAddendumUpload;
