import {useEffect, useState} from 'react';
import * as d3 from 'd3';
import { useD3 } from '../d3-hook';
import {claimsMap} from '../claims-map';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ClaimCauseOptions, ClaimStatusOptions, StatusTriage } from '../../utilities/field-enums';
import { SafeleaseCheckboxList } from '../../common'
import { ClaimsMapMetric, RelationshipByClaim, LlcByClaim } from '../../utilities/generated/gql-types'
import { QueryResult } from '@apollo/client';
import { useClaimsVizStore } from './claims-visualizations';


function SpikeMapComponent (props: {
  claimsMetrics: QueryResult<{getClaimsMapMetrics: ClaimsMapMetric[]}>,
  relationshipsByClaims: QueryResult<{getRelationshipsByClaims: RelationshipByClaim[]}>,
  llcsByClaims: QueryResult<{getLlcsByClaims: LlcByClaim[]}>,
  children: JSX.Element
}) {
  const { children, claimsMetrics, relationshipsByClaims, llcsByClaims } = props;

  const { 
    sumFilter,
    setSumFilter,
    setClaimsVizMaxValue,
    claimsVizMaxValue,
    relationshipId,
    relationshipName,
    llcName,
    llcId,
    statuses,
    setStatuses,
  } = useClaimsVizStore((state) => {
        return {
          sumFilter: state.sumFilter,
          setSumFilter: state.setSumFilter,
          setClaimsVizMaxValue: state.setClaimsVizMaxValue,
          claimsVizMaxValue: state.claimsVizMaxValue,
          relationshipId: state.relationshipId,
          relationshipName: state.relationshipName,
          llcId: state.llcId,
          llcName: state.llcName,
          statuses: state.statuses,
          setStatuses: state.setStatuses,
        }
      })

  const ref = useD3((svg) => {
    const metrics = claimsMetrics.data?.getClaimsMapMetrics || []
    claimsMap(svg, metrics.filter(d => d.lat && d.lng) || [])
  }, [
    JSON.stringify(claimsMetrics?.data || {}),
    sumFilter,
    claimsVizMaxValue,
    llcId,
    relationshipId,
    statuses,
  ]);
  
  const [pendingCauses, setPendingCauses] = 
      useState(ClaimCauseOptions.map(d => d.value))

  useEffect(() => {
    claimsMetrics.refetch({
      causes: pendingCauses,
    })  
    relationshipsByClaims.refetch({
      causes: pendingCauses,
    })    
    llcsByClaims.refetch({
      causes: pendingCauses,
    })    
    //TODO: Refetch llcsByClaims
  }, [pendingCauses])


  return (
    <div className='tw-h-[87vh] tw-overflow-hidden tw-max-w-[85vw]'>
      <div className='tw-flex tw-justify-between'>
        <RadioGroup
            onChange={(e) => {
              if (e.target.value === ClaimsSumFilters.Claims) {
                const max = Math.max(...claimsMetrics.data?.getClaimsMapMetrics?.map(d => d.countPaid));
                setClaimsVizMaxValue(max);
              } else {
                const max = Math.max(...claimsMetrics.data?.getClaimsMapMetrics?.map(d => d.totalPaid));
                console.log('setting max value', max);
                setClaimsVizMaxValue(max);
              }
              setSumFilter(e.target.value)}
            }
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={ClaimsSumFilters.Dollars}
            name="radio-buttons-group" >
          <FormControlLabel value={ClaimsSumFilters.Claims}
              control={<Radio />} label="Total Claims" />
          <FormControlLabel value={ClaimsSumFilters.Dollars}
              control={<Radio />} label="Total Dollars" />
        </RadioGroup>
        <div className='tw-flex tw-flex-col tw-ml-4'>
          <SafeleaseCheckboxList locations={ClaimCauseOptions}
              pendingIds={pendingCauses}
              setPendingIds={setPendingCauses}
              displayAsGrid={true}
              title="Claim Causes"/>
        </div>
        <div className='tw-flex tw-flex-col tw-ml-4'>
          <SafeleaseCheckboxList 
              locations={
                ClaimStatusOptions.filter((d, i) => 
                    [StatusTriage.Paid, StatusTriage.InProgress].includes(d.value))
              }
              pendingIds={statuses}
              setPendingIds={setStatuses}
              displayAsGrid={true}
              title="Claim Statuses" />
        </div>
        <div className='tw-flex tw-flex-col tw-ml-4 tw-w-[140px]'>
          {statuses.includes(StatusTriage.Paid) &&
            <span className='tw-text-xs tw-font-bold tw-mb-2 tw-bg-opacity-30 tw-bg-[#2779FB]
                tw-border-solid tw-border-2 tw-border-[#2779FB]'>
              Paid
            </span>
          }
          {statuses.includes(StatusTriage.InProgress) &&
            <span className='tw-text-xs tw-font-bold tw-mb-2 tw-bg-opacity-30 tw-bg-[red]
                tw-border-solid tw-border-2 tw-border-[red]'>
              In Progress
            </span>
          }
          {relationshipName &&
            <span className='tw-text-xs tw-font-bold tw-mb-2 tw-bg-opacity-30 tw-bg-[limegreen]
                tw-border-solid tw-border-2 tw-border-[limegreen]'>
              {relationshipName}
            </span>
          }
          {llcName &&
            <span className='tw-text-xs tw-font-bold tw-mb-2 tw-bg-opacity-30 tw-bg-[orange]
                tw-border-solid tw-border-2 tw-border-[orange]'>
              {llcName}
            </span>
          }
        </div>
      </div>
      <div className='tw-flex tw-h-full tw-w-full tw-overflow-y-hidden tw-justify-between'>
        <svg
          ref={ref}
          viewBox='0 0 1000 1000 '
          style={{
            //height: 620,
            //width: 965,
            marginRight: "0px",
            marginLeft: "0px",
          }}
        >
        </svg>
        {children}
      </div>
    </div>
  );
}

export enum ClaimsSumFilters {
  Claims = 'Claims',
  Dollars = 'Dollars',
}

export default SpikeMapComponent
