import { StateCreator } from "zustand";
import {
  GeneralUnit,
  Location,
  PrivatePolicy,
  TenantsLease,
} from "../../utilities/generated/gql-types";
import _ from "lodash";
import { sliceResetFns } from "./useReportsStore";

/* General unit with location, lease, and private policy joined */
interface ReportGeneralUnit extends GeneralUnit {
  location: Partial<Location>
  privatePolicy: Partial<PrivatePolicy>
  lease: Partial<TenantsLease>
}

/* Validation status of any given private policy
 * for more info on how this works, see:
 * https://docs.google.com/presentation/d/1tEqMsndpW8bPAk8lPdqUsoSTymQebKaMMS5Jk1NPcjA/edit#slide=id.p
 */
export enum PrivatePolicyValidationStatus {
  null = null,
  invalidNumber = "invalidNumber",
  invalidExpiration = "invalidExpiration",
  unknownDeclaration = "unknownDeclaration",
  humanReview = "humanReview",
  pendingRejected = "pendingRejected",
  pending = "pending",
  rejected = "rejected",
  accepted = "accepted",
  archived = "archived"
}

export interface PrivatePolicyReportSliceState {
  /* ------------------------------------------------------------ */
  /* Private Policy Data                                          */
  /* ------------------------------------------------------------ */

  /* All private policies, regardless of verification status */
  allPrivatePolicies: PrivatePolicy[];

  /* ------------------------------------------------------------ */
  /* General Unit Data                                            */
  /* ------------------------------------------------------------ */

  allUnits: Partial<ReportGeneralUnit>[];

  /* general units with private policies with an invalid private policy number */
  /* privatePolicyValidationStatus = 'invalidNumber' */
  invalidPolicyNumberUnitBucket: ReportGeneralUnit[];

  /* general units with private policies with an invalid private policy expiration date */
  /* privatePolicyValidationStatus = 'invalidExpiration' */
  invalidExpirationDateUnitBucket: ReportGeneralUnit[];

  /* general units with private policies with an invalid expiration date */
  /* privatePolicyValidationStatus = 'unknownDeclarationPage' || 'humanReview' */
  unknownDeclarationPageUnitBucket: ReportGeneralUnit[];

  /* general units with private policies that have been determined (with AI or by human review) to
  have an invalid declaration page */
  /* privatePolicyValidationStatus = 'pendingRejected' || 'rejected' */
  invalidDeclarationPageBucket: ReportGeneralUnit[];

  /* general units with private policies that are pending human review */
  humanReviewBucket: ReportGeneralUnit[];

  /* general units with private policies that have been determined (with AI or by human review) to
  have a valid declaration page, expiration date, and policy number */
  acceptedUnitBucket: ReportGeneralUnit[];

  /* All general units that are enrolled in a SafeLease policy */
  safeLeaseUnits: { [key: string]: Partial<ReportGeneralUnit>[] };

  /* All general units that are not enrolled in a SafeLease policy */
  nonSafeLeaseUnits: { [key: string]: Partial<ReportGeneralUnit>[] };
}

/* Representation of the unit count by location breakdown report used to display
 * the quantity of safelease and non-safelease units by location */
interface SafeLeaseUnitCountReport {
  rows: ReportGeneralUnit[];
  totals: {
    safeLeaseUnits: { [key: string]: Partial<GeneralUnit>[] };
    nonSafeLeaseUnits: { [key: string]: Partial<GeneralUnit>[] };
  };
}

export interface PrivatePolicyReportSlice
  extends PrivatePolicyReportSliceState {
  updatePrivatePolicies: (privatePolicies: PrivatePolicy[]) => void;
  updateUnits: (report: SafeLeaseUnitCountReport) => void;
}

const initialPrivatePolicyReportSlice: PrivatePolicyReportSliceState = {
  allPrivatePolicies: [],
  allUnits: [],
  safeLeaseUnits: {},
  nonSafeLeaseUnits: {},

  invalidPolicyNumberUnitBucket: [],
  invalidExpirationDateUnitBucket: [],
  unknownDeclarationPageUnitBucket: [],
  invalidDeclarationPageBucket: [],
  humanReviewBucket: [],
  acceptedUnitBucket: [],
};

export const createPrivatePolicyReportSlice: StateCreator<
  PrivatePolicyReportSlice
> = (set) => {
  sliceResetFns.add(() => set(initialPrivatePolicyReportSlice));

  return {
    ...initialPrivatePolicyReportSlice,

    updatePrivatePolicies: (privatePolicies: PrivatePolicy[]) => {
      set({
        allPrivatePolicies: privatePolicies,
      });
    },
    updateUnits: ({ rows }) => {
      set({
        allUnits: rows,

        /* general units with private policies with an invalid private policy number */
        invalidPolicyNumberUnitBucket: rows.filter(
          (unit) =>
            unit.privatePolicyValidationStatus ===
            PrivatePolicyValidationStatus.invalidNumber
        ),

        /* general units with private policies with an invalid private policy expiration date */
        invalidExpirationDateUnitBucket: rows.filter(
          (unit) =>
            unit.privatePolicyValidationStatus ===
            PrivatePolicyValidationStatus.invalidExpiration
        ),

        /* general units with private policies with an invalid expiration date */
        unknownDeclarationPageUnitBucket: rows.filter(
          (unit) =>
            unit.privatePolicyValidationStatus ===
            PrivatePolicyValidationStatus.unknownDeclaration
        ),
        
        /* general units with private policies that have been determined (with AI or by human review) to
        have an invalid declaration page */
        invalidDeclarationPageBucket: rows.filter(
          (unit) =>
            unit.privatePolicyValidationStatus ===
            PrivatePolicyValidationStatus.pendingRejected ||
            unit.privatePolicyValidationStatus ===
            PrivatePolicyValidationStatus.rejected
        ),

        /* general units with private policies that are pending human review */
        humanReviewBucket: rows.filter(
          (unit) =>
            unit.privatePolicyValidationStatus ===
            PrivatePolicyValidationStatus.humanReview
        ),

        /* general units with private policies that have been determined (with AI or by human review) to
        have a valid declaration page, expiration date, and policy number */
        acceptedUnitBucket: rows.filter(
          (unit) =>
            unit.privatePolicyValidationStatus ===
            PrivatePolicyValidationStatus.accepted
        ),
      });
    },
  };
};
