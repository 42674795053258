import { Box, Divider, Drawer, Grid, Skeleton, Stack, Theme, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import { FunnelChart, Metric, MetricGroup, SafeLeaseCard, SafeLeaseSwitch } from "@safelease/components";
import { InfoOutlined, Lens } from "@mui/icons-material";
import { useReputation } from "../../../../../useReputation";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import { reactQueryClient } from "../../../../../../utilities/reactQueryClent";

interface CampaingDrawerProps {}

export function CampaignDrawer({}: CampaingDrawerProps) {
  // Disable interaction with the switch while we de/reactivate a campaign
  const [disableInteraction, setDisableInteraction] = useState<boolean>(false);
  const theme = useTheme();

  const { selectedCampaign: campaign, setSelectedCampaign, SafeLeaseAPI } = useReputation();

  const campaignDetailsQuery = useQuery({
    queryKey: ["campaignDetails", campaign?.id],
    queryFn: () => SafeLeaseAPI.reputation.getCampaignDetails(campaign?.id),
    enabled: Boolean(campaign?.id),
  });

  const open = Boolean(campaign);
  const campaignFunnelMetrics = campaignDetailsQuery?.data?.data?.funnelMetrics;
  const messages = campaignDetailsQuery?.data?.data?.messages ?? [];

  const handleClose = () => {
    setSelectedCampaign(null);
  };

  const handleActiveToggle = async (event: React.SyntheticEvent) => {
    if (disableInteraction) return;

    event.stopPropagation();
    event.preventDefault();
    setDisableInteraction(true);
    try {
      await SafeLeaseAPI.reputation.updateCampaign(id, { enabled: !enabled });
      reactQueryClient.invalidateQueries({ queryKey: ["facilityDashboard", facilityId] }); // refetch facility overview
    } finally {
      setDisableInteraction(false);
    }
  };

  if (campaignDetailsQuery.isLoading) {
    return Boolean(campaign) ? <Skeleton sx={{ width: "100%", height: 700 }} /> : null;
  }

  const { id, enabled, facilityId } = campaign;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { width: 700, p: 2 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack id="upper-right-label" direction="row" spacing={1} alignItems="center">
              <Lens
                sx={{
                  color: (theme: Theme) => (campaign?.enabled ? theme.palette.blue.main : theme.palette.grey.A100),
                  height: 12,
                  width: 12,
                }}
              />
              <Typography sx={{ fontWeight: 700 }} variant="h6">
                {campaign?.name} Campaign
              </Typography>
              <Tooltip title={campaign?.description}>
                <InfoOutlined
                  sx={{
                    height: 16,
                    width: "auto",
                    color: (theme: Theme) => theme.palette.grey.A100,
                  }}
                />
              </Tooltip>
            </Stack>
            {campaign?.campaignType === "recurring" && (
              <SafeLeaseSwitch
                disabled={disableInteraction}
                checked={enabled}
                onLabel="Live"
                addWidth={14}
                onChange={handleActiveToggle}
                onClick={(e) => e.stopPropagation()}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
            {campaign?.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ mt: 2, mb: 3 }} fontWeight="bold">
            Campaign Activity
          </Typography>
          {messages.length === 0 && (
            <Typography variant="body1" color="grey.A100">
              No messages sent from this campaign yet.
            </Typography>
          )}
          {messages.length > 0 && (
            <Box sx={{ position: "relative" }}>
              <MetricGroup
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 999,
                }}
                dividers={false}
                equalWidth={true}
              >
                <Metric label="Total Reach Out" value={campaignFunnelMetrics.messageSendCount} />
                <Metric label="Click Through" value={campaignFunnelMetrics.messageOpenCount} />
                <Metric label="Elicited Reviews" value={campaignFunnelMetrics.reviewCount} />
                <Metric label="Positive Reviews" value={campaignFunnelMetrics.positiveReviewCount} />
              </MetricGroup>
              <Box sx={{ height: 250 }}>
                <FunnelChart
                  transparentLabels
                  spacing={5}
                  transformLabelY={157}
                  data={[
                    {
                      stepLabel: "Reached",
                      value: campaignFunnelMetrics.messageSendCount,
                      color: alpha(theme.palette.yellow.main, 1),
                    },
                    {
                      stepLabel: "Clicked Through",
                      value: campaignFunnelMetrics.messageOpenCount,
                      color: alpha(theme.palette.yellow.main, 0.8),
                    },
                    {
                      stepLabel: "Left Reviews",
                      value: campaignFunnelMetrics.reviewCount,
                      color: alpha(theme.palette.yellow.main, 0.6),
                    },
                    {
                      stepLabel: "Positive",
                      value: campaignFunnelMetrics.positiveReviewCount,
                      color: alpha(theme.palette.yellow.main, 0.4),
                    },
                  ]}
                />
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ mt: 2, mb: 1 }} fontWeight="bold">
            Sent Messages
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {messages.length === 0 && (
            <Typography variant="body1" color="grey.A100">
              No messages sent from this campaign yet.
            </Typography>
          )}
          <Stack direction="column" spacing={1}>
            {messages.map((message) => (
              <SafeLeaseCard sx={{ p: 1 }} key={message.id}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body1">
                    Sent {message.messageMedium} to{" "}
                    <Typography component="span" sx={{ fontWeight: 700 }}>
                      {message.sentTo}
                    </Typography>
                  </Typography>
                  <Typography variant="body2" color="grey.A100">
                    {dayjs(message.createdAt).format("MMM DD, h:mm A")}
                  </Typography>
                </Stack>
              </SafeLeaseCard>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  );
}
