import { TwilioJobPosition } from "../../types/TwilioTypes";
import { BaseAPI } from "./base-api";

export interface UpdateUserDto {
  id: number;
  name: string;
  jobTitle: string;
  jobPosition?: TwilioJobPosition;
  email?: string;
  phone: string;
  role: string;
}

class UserAPI extends BaseAPI {
  constructor() {
    super();
  }

  async updateUser(dto: UpdateUserDto) {
    const response = await this.axiosInstance.post(`/api/users/edit`, dto);

    return response.data;
  }

  async getMe() {
    const response = await this.axiosInstance.get(`/api/me`);

    return response.data;
  }

  async getUserById(userId: number) {
    const response = await this.axiosInstance.get(`/api/user/${userId}`);

    return response.data;
  }

  async getUsers(relationshipId) {
    const response = await this.axiosInstance.post(`/api/users`, {
      relationshipId,
    });

    return response.data;
  }
}

export const userApi = new UserAPI();
