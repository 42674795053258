// UI
import { amber } from "@mui/material/colors";
import { Check, Close, InfoOutlined, Lens } from "@mui/icons-material";
import { Box, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { SafeLeaseAvatarWithText } from "@safelease/components";

// Data
import { Message } from "@safelease/service-utilities";

// State / hooks
import { useReputation } from "../../../../useReputation";

// Utils
import _ from "lodash";
import dayjs from "dayjs";

export const contactGridColumns: GridColDef[] = [
  {
    field: "contact",
    headerName: "Contact",
    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography>Contact</Typography>
        <Tooltip title="Current or former tenant name">
          <InfoOutlined
            sx={{
              height: 18,
              color: (theme: Theme) => theme.palette.grey.A100,
            }}
          />
        </Tooltip>
      </Stack>
    ),
    flex: 1,
    valueGetter: ({ row }) => row.name,
    renderCell: ({ value }) => <SafeLeaseAvatarWithText text={value} />,
  },
  {
    field: "review",
    headerName: "Review",
    flex: 1,
    renderCell: ({ value }) => {
      return (
        <Box>
          {/* User has not left a review */}
          {!value && <Typography variant="body2">-</Typography>}

          {/* User has left a review, pending reply */}
          {value && !value.responseSnippet && (
            <Typography variant="body2">
              <Lens sx={{ height: 18, width: 18, color: amber[400], mr: 1 }} />
              Pending Response
            </Typography>
          )}

          {/* User has left a review, and the company has replied*/}
          {value && value.responseSnippet && (
            <Typography variant="body2" color="grey.A100">
              <Check sx={{ mr: 1, color: (theme: Theme) => theme.palette.green.main }} />
              Responded
            </Typography>
          )}
        </Box>
      );
    },
  },
  {
    field: "feedback",
    headerName: "Private Review",
    flex: 1,
    renderCell: ({ value }) => {
      return (
        <Box>
          {/* User has not left a review */}
          {!value && <Typography variant="body2">-</Typography>}

          {/* User has left a review, pending reply */}
          {value && !value.responseSnippet && (
            <Typography variant="body2">
              <Lens sx={{ height: 18, width: 18, color: amber[400], mr: 1 }} />
              Pending Response
            </Typography>
          )}

          {/* User has left a review, and the company has replied*/}
          {value && value.responseSnippet && (
            <Typography variant="body2" color="grey.A100">
              <Check sx={{ mr: 1, color: (theme: Theme) => theme.palette.green.main }} />
              Responded
            </Typography>
          )}
        </Box>
      );
    },
  },
  {
    field: "messages",
    headerName: "Contact History",
    flex: 1,
    renderCell: ({ value }: { value?: Array<Message> }) => {
      return <Typography variant="body2">{value.length} messages sent</Typography>;
    },
  },
  {
    field: "id",
    headerName: "Last Contacted At",
    flex: 1,
    valueGetter: ({ row }) => {
      // If the row has messages (length > 0), it finds the most recent message date by mapping all message dates to timestamps, finding the maximum, and formatting it. If there are no messages, it returns "-" as a placeholder
      return row.messages.length > 0
        ? dayjs(Math.max(...row.messages.map((message) => new Date(message.sentAt).getTime()))).format("MMM DD, hh:mm")
        : "-";
    },
  },
  {
    field: "unsubscribeGroupMemberships",
    headerName: "Campaign Subscriptions",
    flex: 1,
    renderCell: ({ value, row }) => {
      const { portfolio } = useReputation();

      // Unsubscribe groups the user is a part of
      const userUnsubscribeGroupMemberships = value.map((membership) => membership.unsubscribeGroupId);

      // Subscription status
      const manuallyOptedOut = row.manualOptOut;
      const fullySubscribed = userUnsubscribeGroupMemberships?.length === 0;
      const partiallySubscribed =
        userUnsubscribeGroupMemberships?.length > 0 && userUnsubscribeGroupMemberships?.length < portfolio.unsubscribeGroups?.length;
      const unsubscribed =
        userUnsubscribeGroupMemberships?.length === portfolio.unsubscribeGroups?.length && userUnsubscribeGroupMemberships?.length > 0;

      if (manuallyOptedOut) {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Close sx={{ color: "red.main" }} />
            <Typography component="span">Manually Opted Out</Typography>
          </Stack>
        );
      }

      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          {fullySubscribed && <Check sx={{ color: "green.main" }} />}
          {fullySubscribed && <Typography component="span">Fully Subscribed</Typography>}
          {partiallySubscribed && <Lens sx={{ color: amber[400] }} />}
          {partiallySubscribed && <Typography component="span">Partially Subscribed</Typography>}
          {unsubscribed && <Close sx={{ color: "red.main" }} />}
          {unsubscribed && <Typography component="span">Unsubscribed</Typography>}
        </Stack>
      );
    },
  },
];
