import { useState } from "react";
import _ from "lodash";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { EmployeeReviewSortStrategies, PerformanceSortButton } from "./PerformanceSortButton";
import { SafeLeaseTextField } from "@safelease/components";
import { SearchOutlined, SettingsOutlined } from "@mui/icons-material";
import { EmployeeReviewPerformanceBoardListItem } from "./EmployeeReviewPerformanceBoardListItem";
import { usePerformanceBoardStore } from "../usePerformanceBoard";
import dayjs from "dayjs";
import { ReputationUser } from "@safelease/service-utilities";
import { TeamQRCodeSetupDialog } from "../TeamPerformanceBoardSetup";

interface TeamPerformanceBoardActiveStateProps {
  users: Array<ReputationUser>;
}

/**
 * UI for the performance board for reputation in-person solicitation that's displayed after enabling one or more employees.
 */
export function TeamPerformanceBoardActiveState({ users }: TeamPerformanceBoardActiveStateProps) {
  const [employeeSearchText, setEmployeeSearchText] = useState<string>("");
  const { configDialogOpen, setConfigDialogOpen } = usePerformanceBoardStore();

  const [sortStrategy, setSortStrategy] = useState<EmployeeReviewSortStrategies>(
    EmployeeReviewSortStrategies.TOTAL_REVIEWS_EARNED_HIGH_TO_LOW,
  );

  const sortedEmployees = users
    .filter((user) => user.name.toLowerCase().includes(employeeSearchText.toLowerCase()))
    .sort((a, b) => employeeSortStrategy(sortStrategy, a, b));

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography sx={{ fontWeight: 600 }} variant="h6">
          Team Performance Board{" "}
        </Typography>
        <IconButton size="small" onClick={() => setConfigDialogOpen(true)}>
          <SettingsOutlined />
        </IconButton>
      </Stack>
      <Typography sx={{ fontWeight: 300, mb: 2, color: "gray" }} variant="subtitle2">
        Track reviews solicited by your employees using their unique QR codes and links
      </Typography>
      <Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <PerformanceSortButton sortStrategy={sortStrategy} setSortStrategy={setSortStrategy} />
          <SafeLeaseTextField
            slotProps={{
              input: {
                maxLength: 80,
              },
            }}
            value={employeeSearchText}
            onChange={(e) => setEmployeeSearchText(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            sx={{ height: 40, marginTop: "0px !important" }}
            startAdornment={<SearchOutlined sx={{ color: "grey.A100", mr: 1 }} />}
            placeholder="Search employees"
          />
        </Stack>
        {!sortedEmployees.length && (
          <Typography variant="body1" color="grey.A100" textAlign="center" sx={{ mt: 4 }}>
            No employees matching '{employeeSearchText}'
          </Typography>
        )}
        <Stack direction="column" spacing={2} sx={{ mt: 4, maxHeight: 391, overflowY: "scroll" }}>
          {sortedEmployees.map((user) => (
            <EmployeeReviewPerformanceBoardListItem user={user} key={_.uniqueId()} />
          ))}
        </Stack>
      </Box>
      <TeamQRCodeSetupDialog open={configDialogOpen} handleClose={() => setConfigDialogOpen(false)} />
    </>
  );
}

const employeeSortStrategy = (strategy: EmployeeReviewSortStrategies, userA: ReputationUser, userB: ReputationUser) => {

  const userA_reviews = userA.referralLinkEvents?.map((event) => event.googleReview) ?? [];
  const userA_reviewsInLast30 = userA_reviews.filter((review) => dayjs(review.actualDate).isAfter(dayjs().subtract(30, "days")));
  const userA_rating = userA_reviews.length ? userA_reviews.reduce((acc, review) => acc + review.rating, 0) / userA_reviews.length : 0;

  const userB_reviews = userB.referralLinkEvents?.map((event) => event.googleReview) ?? [];
  const userB_reviewsInLast30 = userB_reviews.filter((review) => dayjs(review.actualDate).isAfter(dayjs().subtract(30, "days")));
  const userB_rating = userB_reviews.length ? userB_reviews.reduce((acc, review) => acc + review.rating, 0) / userB_reviews.length : 0;

  switch (strategy) {
    case EmployeeReviewSortStrategies.TOTAL_REVIEWS_EARNED_HIGH_TO_LOW:
      
      return (userB_reviews.length ?? 0) - (userA_reviews.length ?? 0);
    case EmployeeReviewSortStrategies.TOTAL_REVIEWS_EARNED_LOW_TO_HIGH:
      return (userA_reviews.length ?? 0) - (userB_reviews.length ?? 0);
    case EmployeeReviewSortStrategies.CURRENT_REVIEW_RATING_HIGH_TO_LOW:
      return userB_rating - userA_rating;
    case EmployeeReviewSortStrategies.CURRENT_REVIEW_RATING_LOW_TO_HIGH:
      return userA_rating - userB_rating;
    case EmployeeReviewSortStrategies.REVIEWS_IN_THE_30_DAYS_HIGH_TO_LOW:
      return userB_reviewsInLast30.length - userA_reviewsInLast30.length;
    case EmployeeReviewSortStrategies.REVIEWS_IN_THE_30_DAYS_LOW_TO_HIGH:
      return userA_reviewsInLast30.length - userB_reviewsInLast30.length;
    case EmployeeReviewSortStrategies.EMPLOYEE_NAME_A_TO_Z:
      return userA.name.localeCompare(userB.name);
    case EmployeeReviewSortStrategies.EMPLOYEE_NAME_Z_TO_A:
      return userB.name.localeCompare(userA.name);
  }
};
