// UI
import { Box, Drawer, Grid, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { SafeLeaseAvatarWithText, SafeLeaseCard } from "@safelease/components";
import { Close, KeyboardArrowRight, MailOutlineOutlined, PhoneIphoneOutlined, InfoOutlined } from "@mui/icons-material";
import { FacilityFeedbackCard } from "../FeedbackOverview/FacilityFeedbackCard";
import { FacilityReviewCard } from "../FeedbackOverview/FacilityReviewCard";
import { SafeLeaseSwitch } from "@safelease/components";

// Hooks
import { reactQueryClient } from "../../../../../utilities/reactQueryClent";
import { useReputation } from "../../../../useReputation";

// Types
import { Contact } from "@safelease/service-utilities";
import { useState } from "react";

interface ContactInfoDrawerProps {
  contact: Contact;
  open: boolean;
  handleClose: () => void;
}

export function ContactInfoDrawer({ contact, open, handleClose }: ContactInfoDrawerProps) {
  return (
    <Drawer
      anchor="right"
      open={open && Boolean(contact)}
      onClose={handleClose}
      PaperProps={{
        sx: { width: 600, p: 2 },
      }}
    >
      {!contact && <Skeleton variant="rectangular" height={400} sx={{ width: "100%" }} />}
      {contact && <ContactDrawerContent contact={contact} handleClose={handleClose} />}
    </Drawer>
  );
}

/**
 * Drawer that displays after selecting a contact from a facility contact list
 * Controls the ability to manually opt a tenant in or out of receiving messages
 */
function ContactDrawerContent({ contact, handleClose }) {
  const { selectedFacilityId, SafeLeaseAPI } = useReputation();
  const [loading, setLoading] = useState(false);

  const onUpdateOptOut = async (optOut: boolean) => {
    try {
      setLoading(true);
      await SafeLeaseAPI.reputation.updateContact(contact.id, { manualOptOut: optOut });
      await reactQueryClient.refetchQueries({ queryKey: ['facilityContacts', selectedFacilityId] });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  if (!contact) return null;

  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <SafeLeaseAvatarWithText text={contact?.name} />
            <IconButton size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            {contact.phone && (
              <SafeLeaseCard sx={{ p: 1, my: 1, display: "flex", alignItems: "center" }}>
                <PhoneIphoneOutlined sx={{ mr: 1, color: "grey.A100" }} />
                <Typography variant="body2">{contact.phone}</Typography>
              </SafeLeaseCard>
            )}
            {contact.email && (
              <SafeLeaseCard sx={{ p: 1, my: 1, display: "flex", alignItems: "center" }}>
                <MailOutlineOutlined sx={{ mr: 1, color: "grey.A100" }} />
                <Typography variant="body2">{contact.email}</Typography>
                <IconButton size="small" sx={{ ml: 1 }} onClick={() => window.open(`mailto:${contact.email}`, "_blank")}>
                  <KeyboardArrowRight />
                </IconButton>
              </SafeLeaseCard>
            )}
            {!contact.email && !contact.phone && <Typography sx={{ color: "grey.A100" }}>No contact methods found for tenant.</Typography>}

            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
              <Box
                sx={{
                  height: "12px",
                  width: "12px",
                  borderRadius: "50%",
                  backgroundColor: contact.manualOptOut ? "error.main" : "success.main",
                }}
              />
              <Typography variant="body2" sx={{ color: "grey.A100" }}>
                opted {contact.manualOptOut ? "out" : "in"}
              </Typography>
              <Tooltip title={"Whether or not the tenant will receive communications from your campaigns"}>
                <InfoOutlined />
              </Tooltip>
              <SafeLeaseSwitch
                checked={!contact.manualOptOut}
                onClick={() => onUpdateOptOut(!contact.manualOptOut)}
                disabled={loading}
              />
            </Box>
          </Stack>
        </Grid>

        {contact.feedback && (
          <Grid item xs={12}>
            <Typography fontWeight="bold" variant="body1" sx={{ mb: 2 }}>
              Private Review
            </Typography>
            <FacilityFeedbackCard feedback={{ ...contact.feedback, contact }} />
          </Grid>
        )}
        {!contact.feedback && (
          <Grid item xs={12}>
            <Typography>Tenant has not provided any private reviews.</Typography>
          </Grid>
        )}
        {contact.review && (
          <Grid item xs={12}>
            <Typography fontWeight="bold" variant="body1" sx={{ mb: 2 }}>
              Public Review
            </Typography>
            <FacilityReviewCard review={contact.review} contact={contact} />
          </Grid>
        )}
        {!contact.review && (
          <Grid item xs={12}>
            <Typography>Tenant has not left a public review.</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
