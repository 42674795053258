import React, { useEffect, useState } from "react";
import { Divider, SelectChangeEvent, Typography, styled } from "@mui/material";
import { useOrganizationSettings } from "./useOrganizationSettingsStore";
import { useAuth } from "../../../auth";
import { buildMenuItems, findUserById } from "./common";
import { CustomSelectContent } from "./CustomSelect";
import {
  OrgRowBox,
  FlexColumnBox,
  FlexStatusActionBox,
  StyledMoreVertIcon,
  FlexRowBox,
  StyledIconButton,
} from "./OrganizationStyledComponents";
import { UseQueryResult } from "@tanstack/react-query";
import { CustomSelect } from "./CustomSelect";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import InfoPaper, { InfoPaperType } from "./InfoPaper";
import FiberIcon from "@mui/icons-material/FiberManualRecord";
import { Llc } from "../../../types/OrganizationSettingsTypes";
import { DialogView } from "./OrganizationDialog/DialogViews";

interface LlcRowProps {
  llc: Llc;
  fetchOrgSettingsResult: UseQueryResult;
}

const StyledBusinessCenterIcon = styled(BusinessCenterIcon)(({ theme }) => ({
  fontSize: "24px",
  backgroundColor: "transparent",
  borderRadius: "50%",
  marginRight: "16px",
}));

const LlcNameText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontColor: "#152744",
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: "100%",
  border: "0.5px solid #E9E9E9",
  marginLeft: "20px",
  marginRight: "20px",
}));

const LlcRow: React.FC<LlcRowProps> = ({ llc, fetchOrgSettingsResult }) => {
  const auth = useAuth();
  const {
    relationshipUsers: users,
    updatePointOfContact,
    fetchOrgSettingsByRelationshipId,
    setDialogView,
    setDialogOpen,
  } = useOrganizationSettings();
  const isAdmin = auth.user.isAdmin;
  const [pointOfContact, setPointOfContact] = useState<any>("");
  const usersWithAccessMenuItems = buildMenuItems(users);

  // Load point of contact for llc if point of contact exists or changes
  useEffect(() => {
    if (llc.pointOfContact) {
      setPointOfContact(llc.pointOfContact.id);
    } else {
      setPointOfContact("");
    }
  }, [llc.pointOfContact]);

  const handlePointOfContactChange = async (event: SelectChangeEvent) => {
    const { value: userId } = event.target;
    setPointOfContact(userId);
    if (userId) {
      await updatePointOfContact(+userId, llc.id, "llc");

      // if admin fetch org settings by relationship id. This is necessary if an admin is changing point of contact for an llc that is not part of their relationship
      if (isAdmin) {
        await fetchOrgSettingsByRelationshipId(llc.relationshipId);
      } else {
        // fetch org settings for user's relationship
        await fetchOrgSettingsResult.refetch();
      }
    }
  };

  // opens the dialog for the selected llc
  const handleLlcSettingsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // const { id: locationId } = event.currentTarget; // TODO: get actual LLC data
    setDialogView(DialogView.LLC_SETTINGS);
    setDialogOpen(true);
  };

  const handleUsersWithAccessChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
  };

  const billingConnected = !!llc.billingEntities.length;

  return (
    <OrgRowBox>
      {/* <StyledBusinessCenterIcon /> */}
      <FlexColumnBox>
        <LlcNameText>
          {llc.name} ({llc.locations.length})
        </LlcNameText>
        <FlexRowBox>
          <InfoPaper type={InfoPaperType.BILLING} hasTaxId={false} billingConnected={billingConnected} paperStyles={{}} />
          <InfoPaper
            type={InfoPaperType.TAXID}
            hasTaxId={false} // TODO: hook up to actual data
            billingConnected={true} // TODO: hook up to actual data
            paperStyles={{}}
          />
        </FlexRowBox>
      </FlexColumnBox>
      <StyledDivider flexItem orientation="vertical" />
      <CustomSelect
        value={pointOfContact}
        onChange={handlePointOfContactChange}
        menuItems={buildMenuItems(users)}
        sx={{
          height: "40px",
          width: !pointOfContact ? "162px" : "194px",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: pointOfContact ? "inherit" : "#E9645F",
            backgroundColor: pointOfContact ? "inherit" : "rgba(233, 100, 95, 0.1)",
          },
        }}
        displayEmpty
        renderValue={(value) => {
          if (value) {
            return (
              <CustomSelectContent
                value={findUserById(users, value).name}
                valueColor="#031E30"
                marginLeft="0px"
                imageRefs={["test"]}
                infoText={"Point of contact"}
              />
            );
            // No selection
          } else {
            return <CustomSelectContent value={"No selection"} valueColor="#E9645F" marginLeft="0px" infoText={"Point of contact"} />;
          }
        }}
      />
      <StyledDivider flexItem orientation="vertical" />
      <CustomSelect
        value={users.length ? buildMenuItems(users)[0].value.toString() : ""}
        onChange={handleUsersWithAccessChange}
        menuItems={usersWithAccessMenuItems}
        sx={{
          height: "40px",
          width: "260px",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "inherit",
            backgroundColor: "inherit",
          },
        }}
        renderValue={(value) => {
          return (
            <CustomSelectContent
              contentWrapperStyling={{
                marginLeft: "-10px",
              }}
              value={`${users.length} selected`}
              valueColor="#031E30"
              marginLeft="0px"
              imageRefs={["test", "test", "test"]}
              infoText={"Users with access"}
            />
          );
        }}
      />
      <FlexStatusActionBox>
        <FiberIcon
          sx={{
            color: "#E9645F",
            fontSize: "10px",
          }}
        />
        <StyledIconButton onClick={handleLlcSettingsClick} disableRipple>
          <StyledMoreVertIcon />
        </StyledIconButton>
      </FlexStatusActionBox>
    </OrgRowBox>
  );
};

export default LlcRow;
