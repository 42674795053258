import { Form, FormElement} from "./forms.js";
import PrivatePolicyDataService from "./services/private-policy.service";
import { UsaStates } from 'usa-states';
import './styles/private-policy.scss';
import React, {useState, useEffect} from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Image } from './image';
import { Divider, Stack, Typography, styled } from "@mui/material";
import { muiTextFieldForPP } from "./styles/mui-overrides.tsx";

function PrivatePolicy() {
  useEffect(() => {
    document.title = 'Private Policy';
  }, []);

  const [token, setToken] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [type, setType] = React.useState('unitName');
  
  const onFormAttempt = async (input, cb) => {
    input.append('token', token);
    if(type == 'unitName') {
      if(!input.get('unitName')) {
        input.set('unitName', '');
      }
      input.delete('moveInDate');
    } else if(type == 'moveInDate') {
      input.delete('unitName');
      if(!input.get('moveInDate')) {
        input.set('moveInDate', '');
      }
    }

    var response = await PrivatePolicyDataService.save(input);
    setErrors(response.data.errors);
    cb(response);
  };

  var states = Object.fromEntries(new UsaStates().states.map(state => [state.abbreviation, state.name]));

  const onChange = (value) => {
    setToken(value);
  };

  return (
    <div className="private-policy">
      <div className="title">Self Storage Tenant<br/>Private Insurance Policy Opt-out</div>
        <div className="instructions">
          <p>
            Your self-storage facility requires proof of insurance to opt out of its Facility Protection Plan or Tenant Insurance Program. Please complete the following form to prevent automatic enrollment in the minimum coverage plan.
          </p>
        </div>

      <div className="instructions">
        <p>Form Instructions:</p>
        <ol>
          <li>Enter your contact information including your full name as listed on the lease and your email address.</li>
          <li>Enter the facility address information where you rent including the street address, city name, and state.</li>
          <li>Enter your insurance information including policy number and expiration date. Note: The expiration date may not be more than three (3) years in the future.
            <ol>
              <li>Select how you'd like to identify your rental information by choosing unit/parking space number or move-in date.</li>
              <li>If you rent more than one unit or parking space, select "I know my unit/parking space number" and enter all numbers separated by commas, i.e., 310, 311, 312.</li>
              <li>Upload a copy of the Declarations Page provided by your insurance carrier. Accepted formats include PDF, JPG, DOC, DOCX, PNG, and GIF.</li>
            </ol>
          </li>
          <li>Click Submit.</li>
        </ol>
      </div>

      <div className="box">
        <Form onFormAttempt={onFormAttempt} submitLabel="Submit" customPopupContent={<SuccessPrivatePolicyPopupContent />} >
          <div className="form row section-spacing">
            <SectionHeader title="Tenant Information" number={1} />
            <div className="col-lg-6">
              <FormElement name="tenantName" label="Tenant Name" textFieldStylingOveride={muiTextFieldForPP} />
            </div>
            <div className="col-lg-6">
              <FormElement name="email" label="Email" textFieldStylingOveride={muiTextFieldForPP} />
            </div>
          </div>

          <SectionDivider />

          <div className="form row section-spacing" >
            <SectionHeader title="Facility Information" number={2} />
            <div className="col-lg-6">
              <FormElement name="address" label="Street Address" textFieldStylingOveride={muiTextFieldForPP} />
              <FormElement name="state" label="State" type="select" options={Object.keys(states)} labels={states} textFieldStylingOveride={muiTextFieldForPP} />
            </div>
            <div className="col-lg-6">
              <FormElement name="city" label="City Name" textFieldStylingOveride={muiTextFieldForPP} />
            </div>
          </div>

          <SectionDivider />

          <div className="form row section-spacing">
            <SectionHeader title="Insurance Information" number={3} />
            <div className="col-lg-6">
              <FormElement name="policyNumber" label="Policy Number" textFieldStylingOveride={muiTextFieldForPP} />
              <FormControl className="question">
                <FormLabel>How can we look up your rental?</FormLabel>
                <RadioGroup
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  <FormControlLabel value="unitName" control={<Radio />} label="I know my unit/parking space number" />
                  <FormControlLabel value="moveInDate" control={<Radio />} label="I know my move-in date" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="col-lg-6">
              <FormElement name="expiration" type="date" label="Expiration Date" />
              {type == "moveInDate" &&
                <FormElement name="moveInDate" type="date" label="Move-in Date" />
              }
              {type == "unitName" &&
                <FormElement
                  name="unitName"
                  label="Unit/space number(s)"
                  helperText="Separate multiple units with commas."
                  helperTextStyles={{ color: 'white' }}
                  textFieldStylingOveride={muiTextFieldForPP}
                />
              }
            </div>
          </div>

          <div className="form row">
            <div className="declarations">
              <FormElement name="attachment" type="file" label="Declarations Page" />
            </div>
          </div>
        </Form>
      </div>
      <div>
        <Typography 
          sx={{
            fontSize: '12px'
          }}
        >
        "Important Disclosure: By submitting proof of coverage through www.privatepolicy.com, you give permission for SafeLease Insurance Services LLC or its agents to verify that (1) the name on the policy or other proof of coverage matches the tenant name associated with the self-storage unit you are renting at the applicable facility, (2) the policy number provided matches the policy number on your declaration page, and (3) the policy has not expired, as evidenced by the policy term and expiration date. Acceptance or rejection of the proof of coverage submitted will be determined exclusively by SafeLease’s review of the foregoing three factors."
        </Typography>
      </div>
      <div className="privacy-policy">
        <a href="https://info.safelease.com/safelease-privacy-policy-2" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </div>
      <div className="powered-by">
        Powered by
        <a href="https://www.safelease.com/brochure" target="_blank" rel="noopener noreferrer"><Image src="/images/logos/horizontal/safelease_logohorizontal_rgb_blueonwhite.svg" /></a>
      </div>
    </div>
  );
}

const SuccessPrivatePolicyPopupContent = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "15px", alignItems: "center" }}>
      <Typography>Thank you, we have received your insurance information.</Typography>
      <Typography>Please allow 24 hours for your information to be processed.</Typography>
      <Typography>After this time, you may contact your facility to confirm policy details are accurately recorded on your account.</Typography>
    </div>
  );
};

const SectionHeader = ({ title, number }) => {
  return (
    <Stack alignItems="center" direction="row" spacing={1.5} sx={{ height: "30px", marginBottom: "35px" }}>
      <Typography sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "25px",
        height: "25px",
        borderRadius: "50%", background: "#1976d2"
      }}>{number}</Typography>
      <Typography variant="h5" >{title}</Typography>
    </Stack>
  )
}

const SectionDivider = styled(Divider)({
  color: "white",
  borderBottomWidth: 2,
  marginBottom: "40px"
});

export {
  PrivatePolicy,
}
