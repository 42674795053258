import SpikeMapComponent from './spike-map-component';
import { getClaimsMapMetrics, getLlcsByClaims, getRelationshipsByClaims } from '../../queries'
import TitleHeader from '../../shared/title-header';
import { useState, useEffect } from "react";
import { useQuery } from '@apollo/client';
import { ClaimStatusOptions, ClaimCauseOptions, StatusTriage } from '../../utilities/field-enums';
import createStore from 'zustand/vanilla';
import { useStore } from 'zustand'
import { ClaimsSumFilters } from './spike-map-component'
import SortingTable, { TableTitles } from '../../shared/sorting-table'
import TableWrapper from '../../shared/table-wrapper';
import { relationshipsByClaimsColumnDefs } from '../../utilities/column-defs'
import llcsByClaimsSubTableInfo from './llcs-by-claims-sub-table-info'
import { SafeleaseSearchBar } from '../../shared/safelease-search.bar';

export const claimsVizStore = createStore((set) => ({ 
  claimsVizMaxValue: null,
  setClaimsVizMaxValue: (value) => set({ claimsVizMaxValue: value }),
  sumFilter: ClaimsSumFilters.Dollars,
  setSumFilter: (value) => set({ sumFilter: value }),
  relationshipId: null,
  setRelationshipId: (value, name) => {
    set({ relationshipId: value })
    set({ relationshipName: name })
    set({ llcId: null })
    set({ llcName: null })
  },
  llcId: null,
  setLlcId: (value, name) => {
    set({ llcId: value })
    set({ llcName: name })
  },
  statuses: ClaimStatusOptions.filter((d, i) => 
      d.value === StatusTriage.Paid).map(d => d.value),
  setStatuses: (value) => set({ statuses: value }),
}))

export const useClaimsVizStore = (selector) => useStore(claimsVizStore, selector)

function ClaimsVisualizations() {
  const {
      claimsVizMaxValue,
      setClaimsVizMaxValue,
      setRelationshipId,
      sumFilter
  } = useClaimsVizStore((state) => {
    return {
      claimsVizMaxValue: state.claimsVizMaxValue,
      setClaimsVizMaxValue: state.setClaimsVizMaxValue,
      setRelationshipId: state.setRelationshipId,
      sumFilter: state.sumFilter
    }
  })

  const relationshipsByClaims = useQuery(getRelationshipsByClaims)
  const llcsByClaims = useQuery(getLlcsByClaims, {
    variables: {
      relationshipId: 271
    }})
  const [searchValue, setSearchValue] = useState<string>('');

  const claimsMetrics = useQuery(getClaimsMapMetrics, {
    variables: {
      causes: ClaimCauseOptions.map(d => d.value),
    },
    onCompleted: (data) => {
      if(data.getClaimsMapMetrics.length) {
        const max = Math.max(...data.getClaimsMapMetrics.map(d => (
          sumFilter === ClaimsSumFilters.Dollars ? d.totalPaid : d.countPaid
        )))
        if (claimsVizMaxValue === null || max > claimsVizMaxValue) {
          setClaimsVizMaxValue(max)
        }
      }
    }
  });

  useEffect(() => {
    document.body.classList.add('dashboard');
    return () => { document.body.classList.remove('dashboard')}
  }, []);

  const onRelationshipClick = (row) => 
    (event) => {
      setRelationshipId(row.id, row.name)
    }
  

  return (
    <div>
      <TitleHeader title="Claims Map" />
      <div className="tw-flex tw-flex-row">
        <SpikeMapComponent claimsMetrics={claimsMetrics}
            relationshipsByClaims={relationshipsByClaims}
            llcsByClaims={llcsByClaims}>
          <div>
            <SafeleaseSearchBar 
                defaultValue={'Search for a relationship, or LLC.'}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                onSubmitCallback={() => {
                  relationshipsByClaims.refetch({
                    search: searchValue, 
                  })
                }}/>
            <div className='tw-max-h-[850px] tw-overflow-y-scroll'>
              <TableWrapper 
                  title={TableTitles.RelationshipsByClaims}
                  columns={relationshipsByClaimsColumnDefs}>
                <SortingTable title={TableTitles.RelationshipsByClaims}
                    columns={relationshipsByClaimsColumnDefs}
                    queryResultData={ relationshipsByClaims?.data?.getRelationshipsByClaims} 
                    onRowClick={onRelationshipClick}
                    subTableInfo={llcsByClaimsSubTableInfo(llcsByClaims)}/>
              </TableWrapper>
            </div>
          </div>
        </SpikeMapComponent>
      </div>
    </div>
  );
}

export default ClaimsVisualizations;
