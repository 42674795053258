import { createContext, useContext, useState } from 'react';
import { mixpanelEventHandler, mixpanelIdentifyHandler } from './utilities/reactMixpanelHandler';
import apolloClient from "./utilities/apolloClient";

export type LoggedInUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  accessToken: string;
};

const AuthContext = createContext({
  user: null,
  login: async (user: any) => { },
  logout: (callback: any) => { },
});

const useTenantAuth = (): {
  user: LoggedInUser;
  login: (user: LoggedInUser) => Promise<void>;
  logout: (callback: any) => void;
} => {
  return useContext(AuthContext);
};

function TenantAuthProvider(props: any) {
  const [user, setUser] = useState<LoggedInUser | null>(null);

  if (window.localStorage) {
    const currentUser = window.localStorage.getItem('tenantUser');
    if (!user && currentUser) {
      setUser(JSON.parse(currentUser));
    }
  }

  const login = async (user: LoggedInUser) => {
    localStorage.setItem('tenantUser', JSON.stringify(user))
    await apolloClient.clearStore();
    await mixpanelIdentifyHandler();
    mixpanelEventHandler('Tenant login', { id: user.id, email: user.email, name: `${user.firstName} ${user.lastName}` });
    setUser(user);
  };

  const logout = (callback: any) => {
    localStorage.removeItem('tenantUser');
    apolloClient.clearStore();
    setUser(null);
    if (callback) callback();
  };

  return (
    <AuthContext.Provider
      value={{
        user: user,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { TenantAuthProvider, useTenantAuth };