export enum ClaimStatus {
  NotStarted = 'Not Started', //these are claims that have not submitted the protection addendum yet so they are not displayed to users as "incomplete/resume where they left off yet"
  Incomplete = 'Incomplete', //this is a claim that has been started but still not fully completed and submitted for review yet.
  InProgress = 'In Progress', //this is a claim that has been fully completed and submitted by the tenant and is in the review process where additional information may still be required.
}

export type ClaimAttachmentUploadRequestObject = {
  attachment_filename: string,
  attachment_desired_filename: string,
  attachment_mime_type: string,
  databaseColumnName: string,
  creationStep: number,
  claimId: string,
  claimStatus?: ClaimStatus,
}

export enum ClaimSteps{
  safeLeasePlanQuestion = 'safeLeasePlanQuestion',
  protectionPlanAddendum = 'protectionPlanAddendumUpload',
  billingLedger = 'billingLedgerUpload',
  questions = 'Questions',
}

export enum ClaimTypes {
  buildingCollapseFailure = 'Building Collapse/Failure',
  burglary = 'Burglary',
  fire = 'Fire',
  mold = 'Mold',
  rodent = 'Rodent',
  vandalism = 'Vandalism',
  vehicleDamage = 'Vehicle Damage',
  waterDamage = 'Water Damage',
  windstorm = 'Windstorm',
  other = 'Other'
}