import { PortfolioView } from "./pages";
import { ReputationPreActivationPage } from "./pages/PreActivation";
import { useReputation } from "./useReputation";
import { useEffect, useState } from "react";
import ReputationDataService from "./../services/reputation.service";
import { useAuth } from "./../auth.js";
import { useQuery } from "@apollo/client";
import { getRelationship } from "../queries";
import { ScrollToTop } from "./utils/scrollToTop";
import AccessControlled from "../components/AccessControlled";
import RouterHelper from "../utilities/router-helper";
import { Redirect } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Backdrop, CircularProgress } from "@mui/material";

// Wrapping the portfolio view for name's sake, future context wrappers, etc.
export function ReputationManagement({ routerHelper }: { routerHelper: RouterHelper }) {
  const { portfolio, selectedRelationshipId, setOnboardingStep, loadingRelationship, setLoadingRelationship, refresh } = useReputation();
  const auth = useAuth();

  const [syncError, setSyncError] = useState<boolean>(false);

  const relationshipId = auth.user.relationshipId;

  const relationshipQuery = useQuery(getRelationship, {
    variables: { id: selectedRelationshipId ?? relationshipId },
  });

  useEffect(() => {
    async function sync() {
      // await ReputationDataService.sync(selectedRelationshipId ?? relationshipId, relationshipQuery.data.getRelationship.name);
    }

    if (relationshipQuery.data) {
      routerHelper.setRelationshipId(selectedRelationshipId);
      setLoadingRelationship(true);

      sync()
        .then(() => {
          // If the sync is successful, set the onboarding step to completed
          // which will navigate into the portfolio view
          setOnboardingStep("COMPLETED");
          setSyncError(false);
          if (selectedRelationshipId) {
            refresh();
          }
        })
        .catch(() => {
          // If the sync fails, set the onboarding step to pre-activation
          // TODO:REPUTATION: This isn't exactly a 1:1 behavior with what's happening
          // There could be multiple reasons that a sync fails.. but for MVP this is good enough
          setOnboardingStep("PRE_ACTIVATION");
          enqueueSnackbar("This relationship has not been set up for reputation yet.", { variant: "error" });
          setSyncError(true);
        })
        .finally(() => {
          setLoadingRelationship(false);
        });
    }
  }, [relationshipQuery.data]);

  // If the user hasn't gone through setup, show the marketing modal
  const hasCompletedSetup = !!portfolio;

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, display: "" }} open={loadingRelationship}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {(!hasCompletedSetup || syncError) && <ReputationPreActivationPage routerHelper={routerHelper} />}
      {hasCompletedSetup && !syncError && <PortfolioView routerHelper={routerHelper} />}
    </>
  );
}
