import ServiceAbstract from './abstract';
import http from './http-common';
import { InvoiceLineItem, Llc, RecurringInvoiceLineItem } from '../utilities/generated/gql-types';

class BillingDataService extends ServiceAbstract {
  addLlc(llc: Llc, locationId) {
    return http.post('/onboarding/add_llc', { llc: llc, locationId: locationId });
  }
  updateLlcByField(newValue: { [index: string]: any }, llc: Llc) {
    this.validateReason();
    return http.post('/onboarding/update_llc_by_field', { newValue, llc }, this.config);
  }
  createLinkToken(fundingSourceId: number) {
    return http.post("/plaid/create_link_token", { fundingSourceId });
  }
  processPublicToken(publicToken: string, metadata: object, relationshipId: number) {
    return http.post("/plaid/process_public_token", { publicToken, metadata, relationshipId });
  }
  verifyMicrodeposit(metadata: object, fundingSourceId: number) {
    return http.post("/plaid/verify_microdeposit", { metadata, fundingSourceId });
  }
  deleteFundingSource(fundingSourceId: number) {
    return http.post("/billing/delete_funding_source", { fundingSourceId });
  }
  refreshFundingSource(fundingSourceId: number) {
    return http.post("/billing/refresh_funding_source", { fundingSourceId });
  }
  saveBillingSettings(billingEntityId: number, fundingSourceId: number) {
    return http.post("/billing/save_billing_settings", { billingEntityId, fundingSourceId });
  }
  runDwollaBilling(invoiceIds: string[]) {
    return http.post('/billing/run_dwolla_billing', { invoiceIds });
  }
  saveInvoiceLineItem(invoiceLineItem: InvoiceLineItem) {
    return http.post('/billing/save_invoice_line_item', invoiceLineItem);
  }
  deleteInvoiceLineItem(invoiceLineItemId: number) {
    return http.post('/billing/delete_invoice_line_item', { invoiceLineItemId });
  }
  saveRecurringInvoiceLineItem(recurringInvoiceLineItem: RecurringInvoiceLineItem) {
    return http.post('/billing/save_recurring_line_item', recurringInvoiceLineItem);
  }
  deleteRecurringInvoiceLineItem(recurringInvoiceLineItemId: number) {
    return http.post('/billing/delete_recurring_line_item', { recurringInvoiceLineItemId });
  }
  invoicePdfUrl(invoiceId: number) {
    return http.post('/billing/invoice_pdf_url', { invoiceId });
  }
  confirmInvoice(invoiceId: number, confirmed: boolean) {
    return http.post('/billing/confirm_invoice', { invoiceId, confirmed });
  }
  createInvoice(billingEntityId: number, servicePeriod: Date) {
    return http.post('/billing/create_invoice', { billingEntityId, servicePeriod });
  }
  updateBillingMethod(billingEntityId: number, billingMethod: string) {
    return http.post('/billing/update_billing_method', { billingEntityId, billingMethod });
  }
  signBillingAgreement(firstName: string, lastName: string) {
    return http.post('/billing/sign_billing_agreement', { firstName, lastName });
  }
  markInvoiceAsProcessed(invoiceId: number, reason: string) {
    return http.post('/billing/mark_invoice_as_processed', { invoiceId, reason });
  }
  delete(invoiceId: number) {
    return http.post('/billing/delete', { invoiceId });
  }
  reset(invoiceId: number, reason: string) {
    return http.post('/billing/reset', { invoiceId, reason });
  }
  bordereauReport(servicePeriod: Date) {
    return http.post("/billing/bordereau_report", {servicePeriod});
  }
  bordereauReportV2(servicePeriod: Date) {
    return http.post("/billing/bordereau_report_v2", {servicePeriod});
  }
  ytdSafeLeaseRevenueReport(servicePeriod: Date) {
    return http.post("/billing/ytd_safelease_revenue_report", {servicePeriod});
  }
  autoConfirmFailures() {
    return http.get('/billing/auto_confirm_failures');
  }
  runAutoConfirm(invoiceIdList: number[]) {
    return http.post('/billing/run_auto_confirm', { invoiceIdList });
  }
}

export default new BillingDataService();
