import { Alert, Checkbox, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseChip } from "@safelease/components";
import { PrivateReview } from "@safelease/service-utilities";
import { Theme } from "@emotion/react";
import { useState } from "react";
import { useAuth } from "../../auth";
import { reactQueryClient } from "../../utilities/reactQueryClent";
import { enqueueSnackbar } from "notistack";
import { useReputation } from "../useReputation";

interface FeedbackResponseProps {
  feedback: PrivateReview;
}

export function FeedbackResponseFooter({ feedback }: FeedbackResponseProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {feedback.message.campaign && (
        <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }} variant="body1">
          {feedback.contact.name} - {feedback.message?.campaign?.name} Campaign
        </Typography>
      )}
      {!feedback.message.campaign && <Alert severity="error">Could not find an associated campaign</Alert>}
      <FeedbackResponseActionArea feedback={feedback} />
    </Stack>
  );
}

export function FeedbackResponseActionArea({ feedback }: FeedbackResponseProps) {
  const [loading, isLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(feedback.responseSnippet));
  const auth = useAuth();
  const { SafeLeaseAPI } = useReputation();

  const email = feedback.contact?.email;
  const clipboardSupported = navigator.clipboard && navigator.clipboard.writeText;

  const handleEmailAddressCopyClick = () => {
    // Copy email address to clipboard
    navigator.clipboard.writeText(feedback.contact.email);
    enqueueSnackbar(`${feedback.contact.email} copied to clipboard`, { variant: "success" });
  };

  const handleToggle = async () => {
    isLoading(true);
    setIsChecked(!isChecked);
    try {
      // toggle the responseSnippet and responseDate fields between null and a value saying who marked it
      const newPrivateReviewParams = {
        responseSnippet: feedback.responseSnippet ? null : `Marked as Responded by ${auth.user.email}`,
        responseDate: feedback.responseSnippet ? null : new Date(),
      };

      // update data in db
      await SafeLeaseAPI.reputation.updatePrivateReview(feedback.id, { ...newPrivateReviewParams });

      // refresh the sources of the feedback data
      await reactQueryClient.invalidateQueries({
        queryKey: ["portfolioDashboard"], // this is the query that fetches the feedback
      });
    } catch (error) {
      enqueueSnackbar("Error marking as responded. Please try again.", { variant: "error" });
      // Revert the state back to the actual value from the feedback in case of an error
      setIsChecked(Boolean(feedback.responseSnippet));
    } finally {
      isLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress size={20} />;
  }

  // If there is no contact or email, show a message saying so
  if (!email) {
    return <Typography variant="body2">No email available from private review</Typography>;
  }

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-right" sx={{ my: 1 }}>
      <Stack direction="row" alignItems="center">
        <Checkbox onChange={handleToggle} checked={isChecked} />
        <Typography variant="body2">Responded</Typography>
      </Stack>

      {!isChecked && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Divider orientation="vertical" flexItem />
          {
            // Only show the copy email button if the browser supports clipboard
            clipboardSupported ? (
              <SafeLeaseButton color="blue" variant="contained" onClick={handleEmailAddressCopyClick}>
                Copy Email to Respond
              </SafeLeaseButton>
            ) : (
              <Typography variant="body2">Respond to {email}</Typography>
            )
          }
        </Stack>
      )}
    </Stack>
  );
}
