import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NextLink,
  Operation,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'universal-cookie';

const reload = function() {
  console.log("Build mismatch, trying to refresh");

  // Only do a build-driven reload once per hour.  This prevents the nightmare scenario of a reload loop that makes the app unusable.
  const cookies = new Cookies();
  if(cookies.get('refreshed')) return;

  var expires = new Date();
  expires.setHours(expires.getHours() + 1);
  cookies.set('refreshed', true, {expires: expires});
  
  window.location.reload();
};

const errorLink: ApolloLink = onError((error: any) => {
  if (error?.graphQLErrors) {
    for(var error of error.graphQLErrors) {
      if(error.build != (process.env.REACT_APP_BUILD || '')) reload(); // If there is a mismatched build in an error message, reload.
      if(error.message === 'Unauthorized'){
        localStorage.removeItem('tenantUser');
        window.location.href = '/signin';
      }
    }
  }
});

const authMiddleware: ApolloLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const tenantUser = localStorage.getItem('tenantUser');

  if (tenantUser) {
    // add the authorization to the headers
    const tenantUserObj = JSON.parse(tenantUser);
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: 'Bearer ' + tenantUserObj.accessToken,
      },
    }));
  }

  return forward(operation);
});

const httpLink = new HttpLink({ uri: '/graphql' });

export default new ApolloClient({
  ssrMode: false,
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
});