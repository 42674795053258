class Formatters {

  // Formats 1000 to "$10.00"
  static penniesToDollars (pennies?: number | null, options: {digits: number} = {digits: null}): string {
    if (pennies === null || pennies === undefined) {
      return '';
    }

    var digits = 2;
    if(options.digits !== null) {
      digits = options.digits;
    }
      
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
    
    return formatter.format(pennies / 100);
  }

  static penniesToDollarsTableRenderer(pennies, tableInfo, options) {
    if (pennies === null || pennies === undefined) {
      return '';
    }

    var digits = 2;
    if(options.digits !== null) {
      digits = options.digits;
    }
      
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
    
    return formatter.format(pennies / 100);
  }

  // Reformats "2022-06-15T16:33:57.389Z to "2022-06-15"
  static jsDateToGraphQlDate(jsDate: Date | null): string {
    return jsDate?.toISOString().split('T')[0];
  }

  // Formats "cameCaseString" to "Camel Case String"
  static camelCaseToUpperCase(camelCaseString: string): string {
    return camelCaseString?.split(/(?=[A-Z])/).map(function(p) {
      return p.charAt(0).toUpperCase() + p.slice(1);
    }).join(' ');
  }

  // Formats a non-local datetime as a local date
  static localize(dateTime: string): Date | string {
    return dateTime ? new Date(dateTime).toLocaleDateString() : '';
  }
  
  static jsDateToLocalTime(dateTime: string): string{
    return dateTime
      ? `${new Date(dateTime).toDateString()} ${new Date(dateTime).toLocaleTimeString()}`
      : '';
  }
  
  // Formats "2022-06-15T16:33:57.389Z to "2022-06-15" as String
  static jsDateToString(date: string): string {
    if(!date) return null;

    return new Date(date).toISOString().split('T')[0];
  }

  //Keep this for now even though it is unused, because it will be needed for external facing 
  //document manager for non-editable service dates
  //Formats 04-20-1969 as 04/1969
  static monthAndYearOnly(date: string): string {
    if (!date) return null
    return date.split('-').slice(0, -1).reverse().join('/')
  }

  // formats a date object or string to a mm/dd/yyyy format
  static jsDateToPrettyString(date: Date | string): string {
    const newDate = new Date(date);
    return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`
  }

  // Wrapper for toLocaleString() that can be used by column defintion renderers
  // Fomats 12045 as '12,045'
  static formatLocaleString(number?: number): string | undefined {
    return number?.toLocaleString();
  } 

  // Formats 100 as '100%'
  static formatPercentage(number?: number): string {
    return number || number === 0 ? number + '%' : '';
  }
  
  // Formats 0.1525 as '15.25%'
  static formatPercentageShort(number?: number, digits?: number): string {
    if (isNaN(number)) return "-";

    if (number === 0) return '0%' // get around formatter adding an extra decimal

    return (number * 100).toFixed(digits ?? 2) + '%';
  }

  // Format 825 as '8.25%'
  static formatIntToDecimalPercentage(number?: number): string {
    return number || number === 0 ? (number / 100).toFixed(2) + '%' : '';
  }

  // Formats 0.15 as '15%'
  static formatFractionAsPercentage(fraction?: number): string {
    return fraction || fraction === 0 ? (fraction * 100).toFixed(2) + '%' : '';
  }

  static formatDollarSign(number?: number): string {
    return number ? 
        '$' + number.toLocaleString(
            undefined, 
            {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 
        '';
  }

  // Unformats "$500.69" or "-$500.69" to 50069 or -50069
  static unformatDollarSignToPennies(formatted: string): number {
    let isNegative = false
    if (formatted[0] === '-') {
      isNegative = true
      formatted = formatted.slice(1)
    }
    const unformatted = Math.round(parseFloat(formatted.slice(1)) * 100);
    
    if (isNaN(unformatted)) return null;
    return isNegative ? unformatted * -1 : unformatted
  }

  // Unformats "$30.00" as 30.00 OR 0.3 to 30
  static unformatPercentToFloat(percent: string | number): number {
    if (typeof percent === 'string' && percent[percent.length - 1] === '%') {
      const unformatted = parseFloat(percent.slice(0, percent.length - 1))
      if (isNaN(unformatted)) return null;
      return unformatted
    } else if (typeof percent === 'string' && parseFloat(percent) >= 1) {
      return parseFloat(percent) * 100
    } else {
      return (percent as number) * 100
    }
  }

  static formatMinutesToPrettyString(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const hoursString = hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''} and ` : '';
    const minutesString = `${minutes % 60} minute${minutes % 60 !== 1 ? 's' : ''}`;
    
    return `${hoursString}${minutesString}`;
  }

  static formatWithCommas(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export { Formatters }
