import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Autocomplete,
  TextField,
  Box,
  List,
  ListItem,
  IconButton,
  Button,
  Popover,
  Stack,
} from "@mui/material";
import { navyButton } from "../../styles/mui-overrides";
import { QueryResult, useLazyQuery, useQuery } from "@apollo/client";
import { getBlockedUnits, getLocations, getUnits } from "../../queries";
import client from "../../utilities/apolloClient";
import BlockedUnitDataService from "../../services/blocked-unit.service";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import DeleteIcon from "@mui/icons-material/Delete";
import RouterHelper from "../../utilities/router-helper";
import { useAuth } from "../../auth";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { Formatters } from "../../utilities/formatters";

interface BlockedUnitsAccordionProps {
  routerHelper: RouterHelper;
}

export default function BlockedUnitsAccordion({
  routerHelper,
}: BlockedUnitsAccordionProps) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);

  const [expanded, setExpanded] = useState<string | false>(false);

  // New state for Location Autocomplete dropdown options
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // New state for Unit Autocomplete dropdown options
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);

  // Get all locations for relationshipId
  const locationsQuery: QueryResult = useQuery(getLocations, {
    variables: {
      relationshipId:
        routerHelper?.getRelationshipId() || auth.user.relationshipId,
    },
  });

  // Get all units for locationId
  const [fetchUnits, { data: unitsData }] = useLazyQuery(getUnits, {
    fetchPolicy: "network-only", // Always get fresh data
  });

  // Get all blocked units for locationId
  const [fetchBlockedUnits, { data: blockedUnits }] = useLazyQuery(
    getBlockedUnits,
    {
      fetchPolicy: "network-only", // Always get fresh data
    }
  );

  // Reset all state when relationshipId changes
  useEffect(() => {
    setSelectedLocation(null);
    setSelectedUnits([]);
  }, [routerHelper?.getRelationshipId() || auth.user.relationshipId]);

  // Effect to set initial location and location options.
  useEffect(() => {
    const locations = locationsQuery.data?.getLocations;
    if (locations && locations.length > 0) {
      setLocationOptions(locations);
      // Only set the selected location if it hasn't been set before.
      if (!selectedLocation) {
        setSelectedLocation(locations[0]);
      }
    }
  }, [locationsQuery.data, selectedLocation]);

  // Effect to fetch units and blocked units when selectedLocation changes.
  // This effect consolidates the fetching of units and blocked units to ensure
  // that units are fetched before attempting to fetch blocked units.
  useEffect(() => {
    if (selectedLocation) {
      fetchUnits({ variables: { locationId: selectedLocation.id } }).then(
        () => {
          // After units are fetched, then fetch blocked units.
          fetchBlockedUnits({ variables: { locationId: selectedLocation.id } });
        }
      );

      // Reset selected units when location changes
      setSelectedUnits([]);
    }
  }, [selectedLocation, fetchUnits, fetchBlockedUnits]);

  // Effect to update unitOptions when unitsData changes.
  useEffect(() => {
    if (unitsData?.getUnits) {
      setUnitOptions(unitsData.getUnits);
    }
  }, [unitsData]);

  // Effect to update selectedUnits when blockedUnits and unitsData change.
  // This is the final step where you set the selected units based on the
  // blocked units and map the blocked units to the unit options.
  useEffect(() => {
    if (blockedUnits?.getBlockedUnits && unitsData?.getUnits) {
      const blockedUnitsNames = new Set(
        blockedUnits.getBlockedUnits.map((bu) => bu.unit)
      );
      const blockedUnitsArray = unitsData.getUnits.filter((unit) =>
        blockedUnitsNames.has(unit.name)
      );
      setSelectedUnits(blockedUnitsArray);
      console.log("Selected Units", blockedUnitsArray);
    }
  }, [blockedUnits, unitsData]);

  // Handles expanding and collapsing the accordion
  const handleExpandSettings =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // Handles removing a unit from the selected units
  const handleRemoveUnit = (indexToDelete) => {
    const newSelectedUnits = selectedUnits.filter(
      (_, index) => index !== indexToDelete
    );
    setSelectedUnits(newSelectedUnits);
  };

  // Filters the autocomplete options based on the input value and exclude already selected units
  const filterUnitOptions = (options, { inputValue }) => {
    const filteredOptions = options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) &&
        !selectedUnits.find((selectedUnit) => selectedUnit.name === option.name)
    );
    return filteredOptions;
  };

  // Handles updating the blocked units list in the DB
  const updateBlockedUnits = async (blockedUnits, locationId) => {
    try {
      // Map blocked units to list of unit names to send to API.
      const blockedUnitNames = blockedUnits.map((unit) => unit.name);

      setIsSaving(true);
      await BlockedUnitDataService.bulkUpdate(locationId, blockedUnitNames);
      // Set short delay to see saving... message so it doesn't flicker
      await new Promise((resolve) => setTimeout(resolve, 100));
      // Show snackbar on success
      enqueueSnackbar("Blocked Units Saved", { variant: "success" });

      // Refetch queries to update the selectedUnits state
      await client.refetchQueries({ include: [getBlockedUnits] });
    } catch (error) {
      console.error("Error refetching queries:", error);
      enqueueSnackbar("Error Saving Blocked Units", { variant: "error" });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Accordion
      sx={{ pl: 2, mb: 3, mt: 2 }}
      expanded={expanded === "blockedunitssetting"}
      onChange={handleExpandSettings("blockedunitssetting")}
    >
      <AccordionSummary
        sx={{ ml: 0, pl: 0, display: "flex", alignItems: "center" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <SettingsIcon sx={{ mr: 1 }} />
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          Blocked Unit Settings
        </Typography>
        <Typography
          sx={{ color: "text.secondary", marginLeft: "auto", marginRight: 3 }}
        >
          Add/Update units you want to block from Auto Protect
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ ml: 0, pl: 0 }}>
        {/* Autocomplete for Locations */}
        <Autocomplete
          value={selectedLocation}
          onChange={(_, value) => setSelectedLocation(value)}
          autoHighlight
          disablePortal
          id="locations-combo-box"
          options={locationOptions}
          getOptionLabel={(locationOption) => locationOption.fullAddress || ""}
          renderInput={(params) => <TextField {...params} label="Location" />}
          sx={{ width: 600, mb: 2 }} // Adjusted the margin for consistency
        />
        {/* Autocomplete for Units */}
        <Autocomplete
          value={selectedUnits}
          filterOptions={filterUnitOptions}
          autoHighlight
          multiple
          disablePortal
          id="units-combo-box"
          options={unitOptions}
          getOptionLabel={(unitOption) => unitOption.name || ""}
          onChange={(_, value) => setSelectedUnits(value)}
          sx={{ width: 600 }}
          renderInput={(params) => <TextField {...params} label="Units" />}
        />
      </AccordionDetails>
      <Box sx={{ marginBottom: "1.5rem" }}>
        <Typography sx={{ ml: 0, pl: 0 }} variant="h6" component="div">
          {selectedUnits.length > 0
            ? "Selected Blocked Units"
            : "No Units Blocked"}
        </Typography>
        <Typography
          sx={{ ml: 0, pl: 0, fontSize: "0.8rem" }}
          color="textSecondary"
          component="p"
        >
          Remember to press the "Save" button to keep your changes before
          switching locations.
        </Typography>

        <List
          sx={{
            marginLeft: 0,
            paddingLeft: 0,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "left",
          }}
        >
          {selectedUnits.map((selectedUnit, index) => (
            <BlockedUnitListItem
              key={selectedUnit.name}
              unit={selectedUnit}
              onRemove={() => handleRemoveUnit(index)}
            />
          ))}
        </List>

        {!isSaving ? (
          <Button
            sx={{ ...navyButton, marginLeft: 0, width: 125 }}
            variant="contained"
            onClick={() => {
              updateBlockedUnits(selectedUnits, selectedLocation.id);
            }}
          >
            Save
          </Button>
        ) : (
          <Button
            sx={{ ...navyButton, marginLeft: 0, width: 125 }}
            variant="contained"
            disabled
          >
            Saving...
          </Button>
        )}
      </Box>
    </Accordion>
  );
}

function BlockedUnitListItem({ unit, onRemove }) {
  const unitCardStyle = {
    width: "fit-content",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: "1rem",
    paddingRight: "48px", // ensure there is enough space for the delete icon
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    margin: "0.5rem",
    marginLeft: 0,
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ListItem
      sx={unitCardStyle}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <Box
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
      >
        <WarehouseIcon />
        <Typography variant="body1">
          {unit ? `Unit: ${unit.name}` : "None Selected"}
        </Typography>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack direction="column" spacing={1} sx={{ p: 2 }}>
          <Typography>
            <strong>Unit Name:</strong> {unit.name}
          </Typography>
          <Typography>
            <strong>Insurable:</strong>{" "}
            {_.capitalize(unit.insurable.toString())}
          </Typography>
          <Typography>
            <strong>Rate:</strong> {Formatters.penniesToDollars(unit.rate)}
          </Typography>
          <Typography>
            <strong>Size:</strong> {unit.size}
          </Typography>
          <Typography>
            <strong>Type:</strong> {unit.type}
          </Typography>
          <Typography>
            <strong>Last Updated At:</strong> {unit.updatedAt}
          </Typography>
        </Stack>
      </Popover>
    </ListItem>
  );
}
