import { useHistory } from "react-router-dom";
import { useTenantAuth } from "../../../tenantAuth";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Image } from "../../../image";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { useSnackbar } from "notistack";
import TenantUserDataService from "../../../services/tenant-user.service";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const validationSchema = z
  .object({
    email: z.string().nonempty("Email is required.").email(),
    password: z
      .string()
      .nonempty("Password is required.")
      .min(8, "Password must be at least 8 characters long."), //TODO: Add more password validation
    firstName: z.string().nonempty("First name is required."),
    lastName: z.string().nonempty("Last name is required."),
    phoneNumber: z
      .string()
      .nonempty("Phone number is required.")
      .refine(
        (data) => isPossiblePhoneNumber(data, "US"),
        "Invalid phone number."
      ),
    address: z.string().nonempty("Address is required."),
    city: z.string().nonempty("City is required."),
    state: z.string().nonempty("State is required."),
    zipcode: z.string().nonempty("Zipcode is required."),
    passwordConfirmation: z.string().nonempty("Confirm password is required."),
    textNotifications: z.boolean(),
    emailNotifications: z.boolean(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords do not match",
    path: ["passwordConfirmation"], // This specifies which field the error is associated with
  });

type CreateTenantUserProps = {};

function CreateTenantUser({}: CreateTenantUserProps) {
  const history = useHistory();
  const tenantAuth = useTenantAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, formState } =
    useForm<CreateTenantUserFormValues>({
      defaultValues: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        textNotifications: true,
        emailNotifications: true,
      },
      resolver: zodResolver(validationSchema),
    });

  const onSignupButtonClicked: SubmitHandler<
    CreateTenantUserFormValues
  > = async (data) => {
    setLoading(true);
    try {
      const signUpResponse = await TenantUserDataService.signup(data);
      if (signUpResponse.data.errors) {
        // this is how the response looks like when there is an error with the express-validator has an error. The expected error here would be an existing user with the same email. the rest of the errors are handled by the zod validation
        const errorDetails: {
          [key: string]: { value: string; msg: string };
        } = signUpResponse.data.errors;
        for (const [_, val] of Object.entries(errorDetails)) {
          enqueueSnackbar(val.msg, {
            variant: "error",
          });
        }
      } else {
        await tenantAuth.login(signUpResponse.data);
        history.replace("/");
        return;
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong. Please try again later.", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSignupButtonClicked)}>
      <Grid container justifyContent="center" padding="30px">
        <Grid container spacing={2} justifyContent="center" maxWidth="900px">
          <Grid container item xs={12} justifyContent="center">
            <Image src="/images/safelease_logo.svg" alt="SafeLease logo" />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            marginBottom="15px"
          >
            <Typography variant="h4">Account Creation</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.firstName?.message}
                  error={!!formState.errors.firstName?.message}
                  fullWidth
                  label="First name"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.lastName?.message}
                  error={!!formState.errors.lastName?.message}
                  fullWidth
                  label="Last name"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.phoneNumber?.message}
                  error={!!formState.errors.phoneNumber?.message}
                  fullWidth
                  label="Phone number"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Mailing address</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.address?.message}
                  error={!!formState.errors.address?.message}
                  fullWidth
                  label="Address"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.city?.message}
                  error={!!formState.errors.city?.message}
                  fullWidth
                  label="City"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.state?.message}
                  error={!!formState.errors.state?.message}
                  fullWidth
                  label="State"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} marginBottom="55px">
            <Controller
              name="zipcode"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.zipcode?.message}
                  error={!!formState.errors.zipcode?.message}
                  fullWidth
                  label="Zipcode"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.email?.message}
                  error={!!formState.errors.email?.message}
                  fullWidth
                  label="Email"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.password?.message}
                  error={!!formState.errors.password?.message}
                  fullWidth
                  label="Password"
                  type="password"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="passwordConfirmation"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={formState.errors.passwordConfirmation?.message}
                  error={!!formState.errors.passwordConfirmation?.message}
                  fullWidth
                  label="Confirm password"
                  type="password"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Stack>
              <FormControlLabel
                label="Receive text notifications"
                control={
                  <Controller
                    name="textNotifications"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
              <FormControlLabel
                label="Receive email notifications"
                control={
                  <Controller
                    name="emailNotifications"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
              />
            </Stack>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="body2">
              Already have an account? <a href="/signin">Sign in</a>
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <LoadingButton
              variant="contained"
              disableElevation
              sx={{
                width: "220px",
                alignSelf: "center",
                textTransform: "none",
              }}
              type="submit"
              loading={loading}
            >
              Signup
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export { CreateTenantUser };
